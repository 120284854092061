import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'campaignInviteId'
})
export class CampaignInviteIdPipe implements PipeTransform {

  transform(link: string, args?: any): any {
    if(!link) return '';
    // console.log(link);
    return link.split('/')[link.split('/').length -1];
  }

}
