<form [formGroup]="form" autocomplete="off">
  <label
    >Would you like to purchase a card, which will collate all messages from the
    contributors?</label
  >
  <div class="row">
    <div class="col-lg-3 col-md-6">
      <div class="form-group">
        <input
          type="text"
          (change)="getQuote()"
          maxlength="250"
          autocomplete="name"
          class="form-control"
          formControlName="name"
          placeholder="Recipient’s full name"
        />
      </div>
    </div>
    <div class="col-lg-3 col-md-6">
      <div class="form-group">
        <input
          type="text"
          (change)="getQuote()"
          maxlength="250"
          autocomplete="address-level1"
          class="form-control"
          formControlName="address_line1"
          placeholder="Address line 1"
        />
      </div>
    </div>

    <div class="col-lg-3 col-md-6">
      <div class="form-group">
        <input
          type="text"
          (change)="getQuote()"
          maxlength="250"
          autocomplete="address-level2"
          class="form-control"
          formControlName="address_line2"
          placeholder="Address line 2"
        />
      </div>
    </div>

    <div class="col-lg-3 col-md-6">
      <div class="form-group">
        <input
          type="text"
          (change)="getQuote()"
          maxlength="10"
          class="form-control"
          formControlName="postalOrZipCode"
          placeholder="Postal code"
        />
      </div>
    </div>

    <div class="d-sm-none col-12 d-lg-block"></div>

    <div class="col-lg-3 col-md-6">
      <div class="form-group">
        <country-select
          [disabled]="campaign?.isAmountProcessed"
          [selected]="form.get('countryCode').value"
          validation="true"
          (selectedChange)="
            form.get('countryCode').setValue($event?.alpha2Code); getQuote()
          "
          placeholder="Country"
        >
        </country-select>
      </div>
    </div>

    <div class="col-lg-3 col-md-6">
      <div class="form-group">
        <input
          type="text"
          (change)="getQuote()"
          maxlength="150"
          class="form-control"
          formControlName="townOrCity"
          placeholder="City"
        />
      </div>
    </div>

    <!-- <div class="col-lg-3 col-md-6">
      <div class="form-group">
        <input
          type="text"
          (change)="getQuote()"
          maxlength="150"
          class="form-control"
          formControlName="stateOrCounty"
          placeholder="State"
        />
      </div>
    </div> -->
  </div>

  <div class="d-flex justify-content-between mb-3" *ngIf="priceSummary">
    <span class="total-price">
      Total Card Price :
      {{ priceSummary?.cardCharges | currency: priceSummary?.countryCode }} +
      {{ priceSummary?.shippingCharges | currency: priceSummary?.countryCode }}
      = {{ priceSummary?.totalCharges | currency: priceSummary?.countryCode }}
    </span>
    <div class="text-mute">
      Total Card Price : Card Price + Shipping Charges
    </div>
  </div>
</form>
