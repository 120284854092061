export const environment = {
  production: true,
  env: 'prod',
  apiUrls: {
    api: 'https://qa.collectagift.com',
  },
  stripePK:
    'pk_live_51HpL7FEmO2b7uwYzsTcTfoNdiVZGuAyQ0mOm23IuJZW3v2moWc1ZwQeA7AUJKxOnEEHDufw5kJNFlhAA7WodarjD00yVda6GvH',
  stripePayoutLink:
    'https://connect.stripe.com/oauth/authorize?response_type=code&client_id=ca_KOXeiV2keir8uUdBmn9xzNjaYu9lGHSk&scope=read_write',
  firebaseConfig: {
    apiKey: 'AIzaSyDpIXUPNhMdkqIMqiHPmYCIKJLDkuKZlpk',
    authDomain: 'collectagift-3b895.firebaseapp.com',
    databaseURL: 'https://collectagift-3b895-default-rtdb.firebaseio.com',
    projectId: 'collectagift-3b895',
    storageBucket: 'collectagift-3b895.appspot.com',
    messagingSenderId: '329689445810',
    appId: '1:329689445810:web:160a8ae7ea6cec93bca0ab',
    measurementId: 'G-PGY8DDR4CQ',
  },
};
