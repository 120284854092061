<div>
  <h5 class="text-center">Gift Poll</h5>
  <div
    class="d-flex justify-content-between"
    *ngFor="let item of data?.giftSuggestions"
  >
    <div class="nameText" *ngIf="item.voteCount >= 0">
      {{ item.url }}
    </div>
    <div class="percent" *ngIf="item.voteCount >= 0">
      {{ item.percentage }}%
    </div>
  </div>

  <!-- <div class="text-center nameText" *ngIf="!data?.giftSuggestions?.length">
    No gift poll found for this campaign!
  </div> -->
</div>
