<main>
  <nav class="nav-container">
    <div class="nav-content">
      <div
        class="layout-container"
        (click)="selectTemplateId(template.id)"
        *ngFor="let template of templates"
        [class.selected-template]="template.id === selectedTemplateId"
        [ngStyle]="getContainerStyle(template)"
      >
        <table
          [style.borderSpacing]="template.config.gapSize + 'px'"
          style="
            width: '100%';
            height: '100%';
            border-collapse: 'separate';
            table-layout: 'fixed';
          "
        >
          <tr *ngFor="let row of template.config.layout">
            <td
              *ngFor="let item of row"
              [attr.colspan]="item.colspan || 1"
              [style.width]="100 / row.length + '%'"
              [style.height]="item.height"
            >
              <div
                style="
                  justify-content: 'center';
                  align-items: 'center';
                  width: '100%';
                  height: '100%';
                  border-radius: '2px';
                "
                [style.display]="item.type === 'text' ? 'black' : 'flex'"
                [style.background-color]="
                  item.type === 'image' ? 'white' : null
                "
              >
                <img
                  *ngIf="item.type === 'image'"
                  [src]="'assets/images/upload-placeholder.png'"
                  [alt]="item.alt"
                  style="
                    max-width: '100%';
                    max-height: '100%';
                    object-fit: 'contain';
                  "
                />
                <p
                  *ngIf="item.type === 'text'"
                  style="
                    margin: '0';
                    padding: '5px';
                    font-size: '14px';
                    text-align: 'center';
                    width: '100%';
                    font-family: Source Sans Pro, sans-serif;
                    color: black;
                  "
                >
                  Message here...
                </p>
              </div>
            </td>
          </tr>
        </table>
      </div>
    </div>
    <div class="nav-footer">
      <button
        type="button"
        class="btn btn-red w-100"
        (click)="selectTemplate()"
      >
        Select Template
      </button>
    </div>
  </nav>
  <section>
    <div class="section-content" #container>
      <div
        class="main-container"
        *ngIf="selectedTemp"
        [ngStyle]="getContainerStyle(selectedTemp)"
      >
        <table
          [style.borderSpacing]="selectedTemp.config.gapSize + 'px'"
          style="
            width: '100%';
            height: '100%';
            border-collapse: 'separate';
            table-layout: 'fixed';
          "
        >
          <tr *ngFor="let row of selectedTemp.config.layout">
            <td
              *ngFor="let item of row"
              [style.width]="100 / row.length + '%'"
              [style.height]="item.height"
              [attr.colspan]="item.colspan || 1"
            >
              <layout-img-item
                *ngIf="item.type === 'image'"
                [item]="item"
              ></layout-img-item>
              <layout-content-item
                *ngIf="item.type === 'text'"
                [item]="item"
              ></layout-content-item>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </section>
  <nav>
    <div class="nav-content">
      <div>
        <h4>Layout Styles</h4>
        <div class="mb-3">
          <div class="d-flex justify-content-between">
            <label for="layout-gap">Layout Gap</label>
            <label>{{ gapInput.value }}</label>
          </div>
          <input
            id="layout-gap"
            type="range"
            class="w-100 layout-input-gap"
            min="0"
            max="50"
            [(ngModel)]="selectedTemp.config.gapSize"
            [ngModelOptions]="{ standalone: true }"
            #gapInput
          />
        </div>

        <div class="mb-3">
          <label for="background-color">Background color</label> <br />
          <input
            id="background-color"
            type="color"
            class="w-100"
            matInput
            [(ngModel)]="selectedTemp.config.backgroundColor"
          />
        </div>
      </div>

      <div *ngIf="isContentStyleVisible">
        <ng-container
          *ngTemplateOutlet="contentItem?.dialogTemp"
        ></ng-container>
      </div>
    </div>
    <footer>
      <button
        type="button"
        class="btn btn-red"
        (click)="generateImage(container)"
        [disabled]="!selectedTemp"
      >
        Generate Image & Upload
      </button>
    </footer>
  </nav>
</main>
