import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormArray, FormGroup, FormControl, Validators } from '@angular/forms';
import { InviteEmailService } from 'src/app/services/invite-email.service';
import { AppService } from 'src/app/services/app.service';
import { Campaign } from 'src/app/models/campaign.model';
import { Router } from '@angular/router';
import { CampaignService } from 'src/app/services/campaign.service';
import {
  trigger,
  state,
  style,
  animate,
  transition,
} from '@angular/animations';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { ValidationService } from 'src/app/modules/shareModule/controls-msg/validation.service';
import { CreateCatalogueProductAndProceedComponent } from '../create-catalogue-product-and-proceed/create-catalogue-product-and-proceed.component';

@Component({
  selector: 'app-invite-people',
  templateUrl: './invite-people.component.html',
  styleUrls: ['./invite-people.component.scss'],
  animations: [
    trigger('error', [
      state(
        'show',
        style({
          height: 'auto',
          overflow: 'hidden',
        })
      ),
      state(
        'hide',
        style({
          height: '0px',
          overflow: 'hidden',
        })
      ),
      transition('* <=> *', [
        animate(
          25,
          style({
            height: '10px',
          })
        ),
        animate(50),
      ]),
    ]),
  ],
})
export class InvitePeopleComponent implements OnInit {
  @Input() form: FormGroup = new FormGroup({});
  @Output() next: EventEmitter<number> = new EventEmitter();
  @Output() campaignChange: EventEmitter<Campaign> = new EventEmitter();
  @Input() campaignId: number;
  @Input() campaign: Campaign = new Campaign();
  isLoading: boolean = false;
  email: FormControl = new FormControl('', [
    Validators.required,
    ValidationService.emailValidator,
  ]);
  checking: boolean = false;
  success: boolean;
  inviteData: any;

  constructor(
    private _inviteEmailService: InviteEmailService,
    private _appService: AppService,
    private _router: Router,
    private _campaignService: CampaignService,
    public _configurationService: ConfigurationService
  ) {
    this._configurationService.fetch();
  }

  public get campaignContent() {
    return this._configurationService?.campaignContent || {};
  }

  public get invitePeopleToContribute() {
    return this._configurationService?.invitePeopleToContribute || {};
  }

  ngOnInit() {}

  onNext(temp: any) {
    if (this.campaign.isAmountProcessed) {
      this._appService.snackBarOpenTemplate(temp);
      // this.next.emit(2);
      return;
    }
    this.form.get('uniqueCode').setValue('uniqueCode' + this.form.value['id']);
    this.update({ ...this.form.value });
  }

  addInvitation() {
    this.invitedEmails.push(
      new FormGroup({
        id: new FormControl(''),
        email: new FormControl(this.email.value),
        campaignId: new FormControl(this.campaignId),
        loading: new FormControl(true),
      })
    );
    this.email.reset();
    this.create(this.singleInvitation(this.invitedEmails.length - 1));
  }

  get invitedEmails(): FormArray {
    return this.form.get('invitedEmails') as FormArray;
  }

  singleInvitation(index): FormGroup {
    return this.invitedEmails.controls[index] as FormGroup;
  }

  checkUniqueCode() {
    this.checking = true;
    this._campaignService.campaigns
      .create({ uniqueCode: this.form.value['uniqueCode'] }, 'check/code')
      .then((d) => {
        this.checking = false;
        this.update({ ...this.form.value });
      })
      .catch((d) => {
        this.checking = false;
        this.form
          .get('uniqueCode')
          .setErrors({ invalid: 'Code already exists' });
      });
  }

  update(d: Campaign) {
    this.checking = true;
    this._campaignService.campaigns
      .update(this.campaign.id, d)
      .then((d) => {
        this.campaignChange.emit(d);
        this.success = true;
        this.checking = false;
        this.form.get('uniqueCode').markAsPristine();
        this.next.emit(2);
      })
      .catch((err) => {
        this.checking = false;
        this._appService.snackBarOpen(err);
      });
  }

  create(form: FormGroup) {
    this._inviteEmailService.invitedEmails
      .create(form.value)
      .then((d) => {
        form.get('id').setValue(d.id);
        form.get('loading').setValue(false);
      })
      .catch((err) => {
        form.get('loading').setValue(false);
        this._appService.snackBarOpen(err);
      });
  }

  copyUniqueCode() {
    let input: HTMLInputElement = document.getElementById(
      'create-code'
    ) as HTMLInputElement;
    input.select();
    document.execCommand('copy');
    input.blur();
    this._appService.snackBarOpen('Unique Code Copied');
  }

  get campaignLink(): string {
    const pathname = new URL(this.campaign.inviteLink).pathname;
    return `${location.origin}${pathname}`;
  }

  copyLink() {
    this._appService.copyLink(this.campaignLink);
  }

  delete(i) {
    let form: FormGroup = this.singleInvitation(i);
    form.get('loading').setValue(true);
    this._inviteEmailService.invitedEmails
      .remove(form.get('id').value)
      .then((d) => {
        this.invitedEmails.removeAt(i);
      })
      .catch((err) => {
        form.get('loading').setValue(false);
        this._appService.snackBarOpen(err);
      });
  }

  thisLink(text: string) {
    const text1 = text?.toLowerCase();
    return text1?.replace(new RegExp('Click here', 'gi'), (match) => {
      return `<a class="blckunderline" href="${this.campaignLink}" target="_blank">Click here</a>`;
    });
  }

  saveAndContinue(viewOnly: boolean, dialogData?: any) {
    this._appService.matDialog.open(CreateCatalogueProductAndProceedComponent, {
      maxHeight: '100vh',
      maxWidth: '100vw',
      width: '90vw',
      height: '90vh',
      data: {
        data: dialogData?.giftAmount,
        productId: dialogData,
        // remainingAmount: this.remainingAmount,
        isSingleToProceed: true,
        campaign: {
          ...this.campaign,
          ...this.form.value,
          // cardAmount: this.campaignCardPr,
          // remainingAmount: this.remainingAmount,
        },
        viewOnly,
        multiple: false,
      },
    });
  }

  navigateToContribute(id: number) {
    this._router.navigate(['/pages/contribution/' + id]);
  }
  
}
