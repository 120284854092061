import { Injectable } from '@angular/core';
import { IApi } from '../http-handler/common/contracts/api';
import { HttpClient } from '@angular/common/http';
import { GenericApi } from '../http-handler/common/generic-api';

@Injectable({
  providedIn: 'root'
})
export class CharitiesService {

  charities

constructor(
  http: HttpClient
) { 

  this.charities = new GenericApi<Charity>('charities', http);


}

}

export class Charity{
  id?:number;
  name?:string;
  status?:string
}
