<div class="button-container">
  <button class="close-button" (click)="dialogRef.close()">X</button>
</div>
<p>
  Would you like to redeem the full £{{
    (this.data?.campaign?.collectedAmount - this?.data.data?.this.campaignCardPr).toFixed(2)
  }}
  on one voucher or would you like to split it across multiple vouchers? (You
  can split it between up to 4 vouchers)
</p>
<div class="button-box">
  <div>
    <button mat-button ButtonUi (click)="setMultiple()" color="c1">
      Just One Retailer
    </button>
  </div>
  <div>
    <button mat-button ButtonUi (click)="setMultiple()" color="c1">
      Multiple
    </button>
  </div>
</div>

<form *ngIf="!isMultiple" style="border-collapse: collapse">
  <table>
    <tr>
      <th>Voucher</th>
      <th>Retailer</th>
      <th>Amount</th>
    </tr>
    <tr *ngFor="let selected of singleSelectedProducts; index as i">
      <td>{{ i + 1 }}</td>
      <td class="total-amount" (click)="chooseByRetailer(false, true, i)">
        {{
          selected?.item?.name ? selected?.item?.name : "Choose Gift Card here"
        }}
      </td>
      <td>
        <input
          (input)="onInputChange($event, i)"
          type="number"
          class="form-control col-7"
          placeholder="Enter amount"
          [(ngModel)]="selected.amount"
          [ngModelOptions]="{ standalone: true }"
          disabled
        />
      </td>
      <td></td>
    </tr>
  </table>
</form>
<form *ngIf="isMultiple" style="border-collapse: collapse">
  <table>
    <tr>
      <th>Voucher</th>
      <th>Retailer</th>
      <th>Amount</th>
    </tr>
    <tr *ngFor="let selected of selectedProducts; index as i">
      <td>{{ i + 1 }}</td>
      <td class="total-amount" (click)="chooseByRetailer(false, true, i)">
        {{
          selected?.item?.name ? selected?.item?.name : "Choose Gift Card here"
        }}
      </td>
      <td>
        <input
          (input)="onInputChange($event, i)"
          type="number"
          class="form-control col-7"
          placeholder="Enter amount"
          [(ngModel)]="selected.amount"
          [ngModelOptions]="{ standalone: true }"
        />
      </td>
      <td></td>
    </tr>

    <tr>
      <td></td>
      <td colspan="2" class="total-amount">
        Amount redeemed &pound;{{ totalCardAmount.toFixed(2) }} of &pound;{{
          charityAmount().toFixed(2)
        }} , You have &pound;{{ remainingAmountLeft }} left to redeem.
      </td>
    </tr>
  </table>
</form>

<div class="redeem-button">
  <button
    [disabled]="max_value || !isSelectedProducts"
    (click)="done()"
    [loading]="isLoading"
    mat-button
    ButtonUi
    color="c1"
  >
    Confirm Selection
  </button>
</div>
