import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { HomeComponent } from './modules/home/component/home.component';
import { PagesComponent } from './pages/pages.component';
import { CreateGiftComponent } from './modules/create-gift/create-gift.component';
import { DetailComponent } from './modules/detail/detail.component';
import { AuthGuard } from './guards/auth.guard';
import { LoginGuard } from './guards/login.guard';
import { AboutUsComponent } from './modules/home/about/about-us/about-us.component';
import { TermsConditionsComponent } from './modules/home/Terms-Conditions/Terms-Conditions.component';
import { PrivacyPolicyComponent } from './modules/home/Privacy-policy/Privacy-policy.component';
import { ConnectStripeComponent } from './modules/home/connect-stripe/connect-stripe.component';
import { FeesComponent } from './modules/home/fees/fees.component';
import { CampaignDigitalCardComponent } from './modules/shareModule/components/campaign-digital-card/campaign-digital-card.component';

const ChildRoutes: Routes = [
  { path: '', redirectTo: 'detail', pathMatch: 'full' },
  {
    path: 'detail',
    component: DetailComponent,
    loadChildren: () =>
      import('./modules/detail/detail.module').then((m) => m.DetailModule),
    canActivate: [AuthGuard],
  },
  { path: 'create-gift', component: CreateGiftComponent },
  {
    path: 'contribution',
    loadChildren: () =>
      import(
        './modules/contribute-to-a-collection/contribute-to-a-collection.module'
      ).then((m) => m.ContributeToACollectionModule),
    canActivate: [AuthGuard],
  },
  { path: '**', redirectTo: 'detail', pathMatch: 'full' },
];

const appRoutes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: 'home', component: HomeComponent },
  { path: 'faqs', component: FeesComponent },
  { path: 'link/stripe', component: ConnectStripeComponent },
  {
    path: 'digital-e-card/:campaignId',
    component: CampaignDigitalCardComponent,
  },
  { path: 'terms-conditions', component: TermsConditionsComponent },
  { path: 'privacy-policy', component: PrivacyPolicyComponent },
  { path: 'about-us', component: AboutUsComponent },
  {
    path: 'contribution',
    loadChildren: () =>
      import(
        './modules/contribute-to-a-collection/contribute-to-a-collection.module'
      ).then((m) => m.ContributeToACollectionModule),
    canActivate: [LoginGuard],
  },
  // { path:'contribution',loadChildren:() => import('./modules/contribute-to-a-collection/contribute-to-a-collection.module').then(m => m.ContributeToACollectionModule)},
  {
    path: 'auth',
    loadChildren: () =>
      import('./modules/auth/auth.module').then((d) => d.AuthModule),
    canActivate: [LoginGuard],
  },
  { path: 'pages', component: PagesComponent, children: ChildRoutes },
  // { path: 'pages', component: PagesComponent, children: ChildRoutes, },
  { path: '**', redirectTo: 'home', pathMatch: 'full' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes, {
      relativeLinkResolution: 'legacy',
      preloadingStrategy: PreloadAllModules,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
