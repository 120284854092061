import { Injectable } from '@angular/core';
import { CountryOptions } from '../models/country.model';

@Injectable({
  providedIn: 'root'
})
export class CardService {

constructor() { }

displayCurrancy = (item:CountryOptions) => {
  if (!item) return "";
  if (item.currencies && item.currencies.length) {
    return `${item.currencies[0].symbol || ''} ${item.currencies[0].code || ''} ${item.currencies[0].name || ''} ${item.name}`
  } else {
    return item.name;
  }
}

searchCurrancy = (c:CountryOptions,v:string) => {
    v = v.toLowerCase();
    return (c.currencies.length && (c.currencies.filter(c => (c.name && c.name.toLowerCase().includes(v)) || (c.code && c.code.includes(v.toUpperCase()))).length))
}

}
