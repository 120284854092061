<!-- navbar -->
<div
  class="container-fluid pl-0 nav-section m-0 home-img padding-right-0"
  [style.background-image]="'url(' + homeHeaderImage + ')'"
>
  <div style="background-color: #ef4f60">
    <div
      width="100%"
      direction="right"
      bgcolor="yellow"
      class="animation"
      [innerHTML]="currentMessage"
    ></div>
  </div>
  <nav class="navbar navbar-expand-lg ml-auto">
    <div class="d-flex" *ngIf="!_appService.user">
      <div class="Register-home cursor-pointer" (click)="navigateToSignup()">
        Register
      </div>
      <div class="log-home cursor-pointer" routerLink="/auth/sign-in">
        Log In
      </div>
    </div>

    <div class="d-flex" *ngIf="_appService.user">
      <div
        class="dashboard-home cursor-pointer"
        (click)="navigate('/pages/detail')"
      >
        My Dashboard
      </div>
    </div>
    <!-- <button class="navbar-toggler ml-auto" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span>
        <img src="assets/images/menu.png" class="img-fluid" alt="">
      </span>
    </button> -->

    <!-- <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav ml-auto" >
        <li class="nav-item active">
          <a class="nav-link" routerLink="/home">Home</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/about-us">About us</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" >Gift Partners</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" >Charity Champions</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/home" [fragment]="'contact'" tabindex="-1" aria-disabled="true">Contact us</a>
        </li>
        <li class="nav-item" *ngIf="!user?.id" >
          <button class="orange-btn outline light" (click)="navigate('/pages/detail')"  >Log In</button>
        </li>
        <li class="nav-item" *ngIf="user?.id" >
          <button mat-icon-button class="bg-mute mx-3 profile" (click)="navigate('/pages/detail')" >
          <img class="rounded-circle img-fluid" [src]="user?.imgUrl || 'assets/images/user icon.png'" alt="">
          </button>
        </li>
      </ul>
    </div> -->

    <div class="nav-container">
      <div
        class="button_container {{ isOpened ? 'active' : '' }}"
        id="toggle"
        (click)="isOpened = !isOpened"
      >
        <span class="top"></span>
        <span class="middle"></span>
        <span class="bottom"></span>
      </div>
      <div class="overlay {{ isOpened ? 'open' : '' }}" id="overlay">
        <nav class="overlay-menu">
          <ul>
            <li class="nav-item active">
              <a
                class="nav-link"
                (click)="isOpened = !isOpened"
                routerLink="/home"
                >Home</a
              >
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                (click)="isOpened = !isOpened"
                routerLink="/about-us"
                >About us</a
              >
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                (click)="isOpened = !isOpened"
                routerLink="/home"
                [fragment]="'contact'"
                tabindex="-1"
                aria-disabled="true"
                >Contact us</a
              >
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                (click)="isOpened = !isOpened"
                routerLink="/home"
                [fragment]="'gift-parnter'"
                tabindex="-1"
                aria-disabled="true"
                >Our Gift Partners</a
              >
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                (click)="isOpened = !isOpened"
                routerLink="/home"
                [fragment]="'charity'"
                tabindex="-1"
                aria-disabled="true"
                >Charity Champions</a
              >
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                (click)="isOpened = !isOpened"
                routerLink="/faqs"
                >FAQs</a
              >
            </li>
            <li class="nav-item" *ngIf="!user?.id">
              <button
                class="orange-btn outline light"
                (click)="navigate('/pages/detail'); isOpened = !isOpened"
              >
                Log In
              </button>
            </li>
            <li class="nav-item d-flex align-items-center" *ngIf="user?.id">
              <a
                class="nav-link cursor-pointer"
                (click)="navigate('/pages/detail'); isOpened = !isOpened"
              >
                <!-- My Account (Access to my CollectaGift Pots) -->
                My Dashboard
              </a>
              <button
                mat-icon-button
                class="bg-mute ml-3 profile"
                (click)="navigate('/pages/detail'); isOpened = !isOpened"
              >
                <img
                  class="rounded-circle img-fluid"
                  [src]="user?.imgUrl || 'assets/images/user icon.png'"
                  alt=""
                />
              </button>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </nav>

  <!-- <img
    src="assets/images/header-left-shape.png"
    alt=""
    class="img-fluid banner-shape-img"
  />
  <img
    src="assets/images/banner-gift-img.png"
    alt=""
    class="img-fluid banner-gift-img"
  /> -->

  <!-- <div class="home-popup"  >
    <p>
      <b>At CollectaGift</b> <br>
      <small>
        we love clubbing together with friends, family and colleagues to buy a group gift for a special someone. Whether
        it's a Leaving Gift for a colleague, a Thank You Gift for a teacher, or any group gift, we love coming together,
        celebrating and giving. However, what we don't love as much is the hassle that can come with it, so at
        CollectaGift, we're passionate about making this process easier."
      </small>
    </p>
  </div> -->

  <div class="header-heading ml-auto text-black">
    <div class="d-flex scroll-down">
      <div class="scroll-hom">SCROLL</div>
      <div class="down-hom">DOWN</div>
      <div class="line-down"></div>
      <div>
        <i class="arrow down"></i>
      </div>
    </div>
    <img
      height="150px"
      data-aos="zoom-out"
      src="assets/logos/CollectaGift-Final.png"
      alt=""
    />
    <!-- <h1>C<span class="header-f-small">ollect</span> A <span class="skyblue"> Gift</span></h1> -->
    <p data-aos="fade-left">Group Gifting, Sorted.</p>
    <div class="btn-row">
      <button
        class="orange-btn theme-btn"
        (click)="navigate('/pages/create-gift')"
      >
        START AN ONLINE COLLECTION
      </button>
      <button
        class="orange-btn-reverse theme-btn"
        (click)="startWithContribute('/pages/contribution')"
      >
        CONTRIBUTE TO A COLLECTION
      </button>
    </div>
    <div class="group-home">Group Gift Collections & Digital Group Cards</div>
  </div>
</div>
<div
  class="browse-hom"
  *ngIf="this.tremendousProducts.list.length && this.mediator.length"
>
  <a class="skyblue" (click)="browseAvailableGift()">
    Browse our Gift Catalogue</a
  >
</div>

<!-- carousel browse gift catalogue -->
<div class="mt-3">
  <!-- *ngIf="this.tremendousProducts.list.length && this.mediator.length" -->
  <div class="row">
    <div class="d-flex col-1 center cursor-pointer" (click)="leftClick()">
      <!-- <mat-icon> arrow_back_ios </mat-icon> -->
    </div>

    <div class="col-md-12 mx-auto d-flex images">
      <!-- *ngFor="let item of mediator" -->
      <!-- item?.images[0]?.src -->
      <img
        class="img-thumbnail"
        src="https://gcow.sfo2.digitaloceanspaces.com/rewards/images/giftcards/39929_67508297.lg.png"
      />
      <img
        class="img-thumbnail"
        src="https://gcow.sfo2.digitaloceanspaces.com/rewards/images/giftcards/11563_38b6c4f9.webp"
      />
      <img
        class="img-thumbnail"
        src="https://gcow.sfo2.digitaloceanspaces.com/rewards/images/giftcards/110522_1ae43c36.webp"
      />
      <img
        class="img-thumbnail"
        src="https://gcow.sfo2.digitaloceanspaces.com/rewards/images/giftcards/5744_71dc8c6d.webp"
      />
      <img
        class="img-thumbnail"
        src="https://gcow.sfo2.digitaloceanspaces.com/rewards/images/giftcards/65692_5a2e6620.webp"
      />
      <img
        class="img-thumbnail"
        src="https://gcow.sfo2.digitaloceanspaces.com/rewards/images/giftcards/66670_a45f9c98.webp"
      />
      <img
        class="img-thumbnail"
        src="https://gcow.sfo2.digitaloceanspaces.com/rewards/images/giftcards/40404_47f44e05.webp"
      />
      <img
        class="img-thumbnail"
        src="https://gcow.sfo2.digitaloceanspaces.com/rewards/images/giftcards/6945_c9a38d5c.webp"
      />
      <img
        class="img-thumbnail"
        src="https://gcow.sfo2.digitaloceanspaces.com/rewards/images/giftcards/7089_c16a7182.webp"
      />
      <!-- <img class="img-thumbnail" src="../../../../assets/images/Slide10.PNG" /> -->
    </div>
    <div class="d-flex center col-1 cursor-pointer" (click)="rightClick()">
      <!-- <mat-icon> arrow_forward_ios </mat-icon> -->
    </div>
  </div>
</div>

<!-- categoris setion  -->
<!-- <section class="p-relative categories-section">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 heading-theme text-center text-white">
        <h2 class="m-0">Browse by categories</h2>
        <p>Your online solution for Group Gifting</p>
        <img src="assets/images/Vector Smart Object copy 4.png" class="img-fluid round" alt="">
      </div>

      <div class="col-lg-12 slider-box">

        <ngx-slick-carousel #slickModal="slick-carousel" [config]="slideConfig" class="cat-slider">
          <div ngxSlickItem *ngFor="let i of categories;" >
            <div class="categorie-item">
              <div class="img-box {{(i.color | category:'class')}}">
                <img [src]="i.imgUrl" alt="">
              </div>
              <div class="content">
                {{i.name}}
              </div>
            </div>
          </div>
        </ngx-slick-carousel>

      </div>
    </div>
  </div>

  <img src="assets/images/slider-vector.png" class="img-fluid leftVector" alt="">
  <img src="assets/images/slider-right-vector.png" class="img-fluid rightVector" alt="">
</section> -->

<!-- orginising -->
<!-- <section class="p-relative orginising-setion">
  <div class="container">
    <div class="row">
      <div class="col-lg-8 col-sm-12 mx-auto heading-theme text-center">
        <h2 class="text-black mb-2">Organising</h2>
        <p>At CollectaGift, we love clubbing together with friends, family and colleagues to buy a group gift for a special someone.  Whether it’s a Leaving Gift for a colleague, a Thank You Gift for a teacher, or any group gift, we love coming together, celebrating and giving.  However, what we don’t love as much is the hassle that can come with it, so at Collectagift, we’re passionate about making this process easier.  </p>
      </div>
      <div class="col-lg-7 mx-auto col-sm-8 text-center">
        <img src="assets/images/orginising.png" class="img-fluid" alt="">
      </div>

    </div>
  </div>
  <img src="assets/images/banner-rectangle.png" class="img-fluid RightVector" alt="">
  <img src="assets/images/orginising-left.png" class="img-fluid leftVector" alt="">

</section> -->

<!-- why us -->
<section class="p-relative why-us mt-3">
  <img class="whyImgG" src="assets/images/whyGrph.png" />
  <div class="container">
    <div class="row">
      <div
        class="col-lg-6 col-sm-12 mx-auto heading-theme text-center text-white"
      >
        <h3 class="m-0" data-aos="fade-up">Why CollectaGift?</h3>
        <!-- <p>A group of collection for your colleague who is retiring or your friend whose birthday it is?</p> -->
      </div>
    </div>

    <div class="row box-row">
      <div
        class="col-lg-6 col-sm-6"
        *ngFor="let item of homeContent.whyCollectaGift; let i = index"
      >
        <div class="box">
          <div class="left-img" [ngSwitch]="i">
            <img
              data-aos="fade-down"
              *ngSwitchCase="0"
              src="assets/images/cash.png"
              alt=""
            />
            <img
              data-aos="fade-down"
              *ngSwitchCase="1"
              src="assets/images/home/whyCollect/ic_wfh.png"
              alt=""
            />
            <img
              data-aos="fade-down"
              *ngSwitchCase="2"
              src="assets/images/home/whyCollect/ic_greeting_card.png"
              alt=""
            />
            <img
              data-aos="fade-down"
              *ngSwitchCase="3"
              src="../../../../assets/images/home/whyCollect/ic_chasing.png"
              alt=""
            />
            <img
              data-aos="fade-down"
              *ngSwitchCase="4"
              src="assets/images/home/whyCollect/ic_collection_pot.png"
              alt=""
            />
            <img
              data-aos="fade-down"
              *ngSwitchCase="5"
              width="50px"
              height="50px"
              src="assets/images/ic_stuck_gift@2x.png"
              alt=""
            />
          </div>
          <div class="content">
            <h5 class="font-Caviar" data-aos="fade-up">{{ item.heading }}</h5>
            <p data-aos="fade-up">
              {{ item?.description }}
            </p>
          </div>
        </div>
      </div>

      <!-- <div class="col-lg-6 col-sm-6">
        <div class="box">
          <div class="left-img">
          </div>
          <div class="content">
            <h5 class="font-Caviar">
              Working from home/Based in a different location?
            </h5>
            <p>Contribute from anywhere into an online CollectaGift Pot.</p>
          </div>
        </div>
      </div>

      <div class="col-lg-6 col-sm-6">
        <div class="box">
          <div class="left-img">
          </div>
          <div class="content">
            <h5 class="font-Caviar">
              Trying to organise a group greeting card remotely?
            </h5>
            <p>
              Digital Group Greeting Card with choice to print-at-home or
              email-to-recipient.
            </p>
          </div>
        </div>
      </div>

      <div class="col-lg-6 col-sm-6">
        <div class="box">
          <div class="left-img">
          </div>
          <div class="content">
            <h5 class="font-Caviar">Tired of chasing people for money?</h5>
            <p>No more IOUs.</p>
          </div>
        </div>
      </div>

      <div class="col-lg-6 col-sm-6">
        <div class="box">
          <div class="left-img">
          </div>
          <div class="content">
            <h5 class="font-Caviar">Leftover cash in the CollectaGift Pot?</h5>
            <p>Optional Charity Donations.</p>
          </div>
        </div>
      </div>

      <div class="col-lg-6 col-sm-6">
        <div class="box">
          <div class="left-img">

          </div>
          <div class="content">
            <h5 class="font-Caviar">Stuck for Gift ideas?</h5>
            <p>Request/Submit Gift ideas and create polls to votes on ideas.</p>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</section>

<!-- how it work -->
<section class="p-relative how-it-work p-0 mb-0">
  <img
    data-aos="fade-up"
    data-aos-anchor-placement="top-center"
    src="assets/images/slider-right-vector.png"
    class="img-fluid rightVector"
    alt=""
  />
  <div class="container p-relative">
    <img
      data-aos="fade-down"
      data-aos-anchor-placement="top-center"
      src="assets/images/banner-rectangle.png"
      class="img-fluid rectangle"
      alt="Rectangle"
    />

    <div class="row">
      <div class="col-lg-6 col-sm-12 mx-auto text-center">
        <div class="heading-theme">
          <h3 data-aos="fade-up" class="text-black">How it works?</h3>
          <p data-aos="fade-down">
            {{
              homeContent?.howItWorks?.description ||
                "CollectAGift works in 3 simple steps"
            }}
          </p>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="circle-container">
        <div class="circle-outer" data-aos="fade-left">
          <div class="circle blue">
            <div>
              <!-- <img src="assets/images/Collection.png" class="img-fluid" alt=""> -->
              <h3 class="m-0">1</h3>
            </div>
          </div>
          <!-- arrows -->
          <div class="arrowDiv">
            <img
              src="assets/images/down-arrow.png"
              class="img-fluid arrowDown"
              alt=""
            />
            <div class="small-circle">
              <div class="blue one"></div>
            </div>
            <div class="circle-content">
              {{
                homeContent?.howItWorks?.heading1 ||
                  "Setup online CollectaGift Pot"
              }}
              <!-- CREATE A COLLECTION -->
            </div>
          </div>
        </div>

        <div class="circle-outer" data-aos="fade-up">
          <div class="circle purple">
            <div>
              <!-- <img src="assets/images/mail.png" class="img-fluid" alt=""> -->
              <h3 class="m-0">2</h3>
            </div>
          </div>
          <!-- arrows -->
          <div class="arrowDiv">
            <img
              src="assets/images/down-arrow.png"
              class="img-fluid arrowDown"
              alt=""
            />
            <div class="small-circle">
              <div class="purple two"></div>
            </div>
            <div class="circle-content">
              <!-- INVITE FRIENDS <br> VIA EMAIL -->
              {{
                homeContent?.howItWorks?.heading2 ||
                  "Share link and invite people to contribute"
              }}
            </div>
          </div>
        </div>

        <!-- <div class="circle-outer">
          <div class="circle magenta">
            <div>
              <img src="assets/images/payment.png" class="img-fluid" alt="">
            </div>
          </div>

          <div class="arrowDiv">
            <img src="assets/images/down-arrow.png" class="img-fluid arrowDown" alt="">
            <div class="small-circle">
              <div class="magenta"></div>
            </div>
            <div class="circle-content">
              CONTRIBUTE ONLINE <br> VIA PAYMENT METHOD
            </div>
          </div>
        </div>

        <div class="circle-outer">
          <div class="circle darkOrange">
            <div>
              <img src="assets/images/payout.png" class="img-fluid" alt="">
            </div>
          </div>
          <div class="arrowDiv">
            <img src="assets/images/down-arrow.png" class="img-fluid arrowDown" alt="">
            <div class="small-circle">
              <div class="darkOrange"></div>
            </div>
            <div class="circle-content">
              GET QUICK PAYOUTS <br> IN ACCOUNT
            </div>
          </div>
        </div> -->

        <div class="circle-outer" data-aos="fade-right">
          <div class="circle lightOrange">
            <div>
              <!-- <img src="assets/images/gift.png" class="img-fluid" alt=""> -->
              <h3 class="m-0">3</h3>
            </div>
          </div>
          <!-- arrows -->
          <div class="arrowDiv">
            <img
              src="assets/images/down-arrow.png"
              class="img-fluid arrowDown"
              alt=""
            />
            <div class="small-circle">
              <div class="lightOrange three"></div>
            </div>
            <div class="circle-content">
              <!-- GIFT <br> RECOMMENDATIONS -->
              {{
                homeContent?.howItWorks?.heading3 ||
                  "Redeem funds to purchase gift and card"
              }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<!--Gift Partners-->

<section class="p-relative d-none giftsec heading-theme pt-5 mb-0" id="gift-parnter">
  <div class="container">
    <div class="heading-theme text-center mt-5">
      <h3 class="black" data-aos="fade-up-right">Our Gift Partners</h3>
      <p data-aos="fade-up-left">
       
      </p>
      <p data-aos="fade-up-left">
        You can use the funds collected to purchase any gift you like from our
        Gift Catalogue
        <a class="skyblue" (click)="browseAvailableGift()"> (browse here) </a>
        of over 100 high street brands. Alternatively you can redeem the funds
        via a virtual Prepaid debit card to purchase a gift from anywhere on the
        web where a Prepaid debit card is accepted. We are also excited to partner with
        some carefully curated independent gift partners to offer you some
        exclusive discounts. As a small independent company ourselves, we choose
        our independent gift partners carefully as we also like to support other
        small independent businesses too. Keep an eye out for available discount
        codes when you come to redeem your collection proceeds.
       
      </p>
    </div>
    <div class="tiff mt-4">
       
      <div class="row">
        <div
          data-aos="fade-down"
          data-aos-duration="3000"
          class="col-md-6 col-12 d-flex align-items-center justify-content-center"
        >
          <a href="https://owendrewcandles.com/" target="_blank">
            <img
              class="owendrew"
              src="{{ homeContent?.ourGiftPartners?.giftPartnerImage3 }}"
              onerror="this.src = 'assets/images/tiffny-logo.png'"
            />
          </a>
        </div>
        <div class="col-md-6 col-12 d-flex align-items-center">
          <div class="heading-theme">
            <h3 class="black" data-aos="fade-down" data-aos-duration="3000">
              {{
                homeContent?.giftPartners?.length
                  ? homeContent?.giftPartners[2]?.heading || "--"
                  : ""
              }}
            </h3>
            <p data-aos="fade-up" data-aos-duration="3000">
              {{
                homeContent?.giftPartners?.length
                  ? homeContent?.giftPartners[2]?.description || "--"
                  : ""
              }}
            </p>
          </div>
        </div>
      </div>
   
    </div>
    <div class="text-center mt-4">
 
      <p class="black" data-aos="zoom-in-down">
        <b
          >If you are a gift provider and would like to partner with us, please
          email
          <a style="color: #4498c7" href="mailTo:hello@collectagift.com"
            >hello&#64;collectagift.com</a
          ></b
        >
      </p>
    </div>
  </div>
</section>

<!--Champion Section-->
<section class="p-relative championSec mt-0 mb-0" id="charity">
  <div class="container">
    <div class="row">
      <div class="col-lg-6">
        <div
          id="demo"
          class="carousel slide"
          data-ride="carousel"
          data-interval="false"
        >
          <!-- Indicators -->
          <ul class="carousel-indicators">
            <li data-target="#demo" data-slide-to="0" class="active">
              <img src="" />
            </li>
            <!-- <li data-target="#demo" data-slide-to="1">
              <img src="" />
            </li> -->
          </ul>

          <!-- The slideshow -->
          <div class="carousel-inner">
            <div class="carousel-item active">
              <div class="youtubeVdo mt-5" data-aos="zoom-out-right">
                <video controls #videoPlayer>
                  <source
                    src="https://shmos.s3.us-east-2.amazonaws.com/media/Journey+to+a+New+Smile+(UK).mp4"
                    type="video/mp4"
                  />
                </video>
              </div>
            </div>
            <!-- <div class="carousel-item">
              <div class="youtubeVdo mt-5" data-aos="zoom-out-left">
                <video controls #videoPlayer>
                  <source
                    src="https://shmos.s3.us-east-2.amazonaws.com/media/CLEFT+in+Bangladesh_+Part+II.mp4"
                    type="video/mp4"
                  />
                </video>
              </div>
            </div> -->
          </div>
          <!-- <div class="text-center pt-1 black" data-aos="zoom-in">
            Copyright Tidal Films 2022
          </div> -->
        </div>
      </div>
      <div class="col-lg-6 collectCharity">
        <div class="heading-theme">
          <h3 class="black" data-aos="flip-down">
            CollectaGift Charity Champions
          </h3>
          <p
            data-aos="fade-down"
            class="mb-0"
            *ngFor="
              let item of homeContent?.collectaGiftCharityChampions
                | slice : 0 : 1
            "
          >
            {{ item.description }}
          </p>
          <a href="https://www.smiletrain.org.uk" target="_blank"
            ><img
              class="lgo my-4"
              src="../../../../assets/images/SmileTrain.png"
          /></a>
          <p
            data-aos="fade-left"
            class="black"
            *ngFor="
              let item of homeContent?.collectaGiftCharityChampions
                | slice : 1 : 2
            "
          >
            {{ item.description }}
            <a
              style="color: #4498c7"
              href="https://www.smiletrain.org.uk/"
              target="_blank"
              >https://www.smiletrain.org.uk</a
            >
          </p>
        </div>
      </div>
    </div>
    <!-- <div class="heading-theme text-center">
      <h3 class="black">CollectaGift Charity Champions</h3>
      <p class="mb-0">Become a CollectaGift Charity Champion and support our charity partner</p>
      <img class="my-4" src="assets/images/champion_logo.png" />
      <p class="black">
        Bought the gift and have some spare change left in the Collectagift Pot and wondering what to do with it? Be a
        CollectaGift Charity Champion and donate any spare change to our nominated charity partner. Here, at
        CollectaGift, we’re delighted to partner with CLEFT – Bridging the Gap. Any leftover change you kindly donate
        will go straight to providing long-term, sustainable ways to improve cleft care both in the UK and overseas. You
        can find out a little more about the charity by visiting their website <a
          style="color: #4498C7;">www.cleft.org.uk.</a>
      </p>
    </div> -->
  </div>
</section>

<!-- contct -->
<section class="p-relative contact-section p-0 mb-0 mt-0" id="contact">
  <div class="container-fluid m-0">
    <div class="row">
      <div class="col-lg-8 col-md-8 col-sm-12 contact-blue text-white">
        <div class="heading-theme">
          <h3 class="m-0" data-aos="zoom-in">Contact Us</h3>
          <p data-aos="fade-up">
            Contact us using the form below or email:
            <a class="text-white" href="mailTo:hello@collectagift.com"
              >hello&#64;collectagift.com</a
            >
          </p>
          <img
            data-aos="fade-up"
            src="assets/images/round-object.png"
            class="img-fluid round"
            alt=""
          />
        </div>

        <form
          class="row pt-4 m-0"
          [formGroup]="contactUsForm"
          autocomplete="off"
        >
          <div class="col-lg-6 col-12 pl-0">
            <div class="form-group" data-aos="fade-right">
              <label for="name"></label>
              <input
                id="name"
                type="text"
                class="form-control"
                formControlName="name"
                placeholder="Name"
              />
            </div>
          </div>

          <div class="col-lg-6 col-12 pl-0">
            <div class="form-group" data-aos="fade-right">
              <label for="name"></label>
              <input
                id="name"
                type="email"
                class="form-control"
                formControlName="email"
                placeholder="Email"
              />
            </div>
          </div>

          <div class="col-lg-12 col-12 pt-4 pl-0">
            <div class="form-group" data-aos="fade-right">
              <label for="name"></label>
              <input
                id="name"
                type="text"
                class="form-control"
                formControlName="message"
                placeholder="Message"
              />
            </div>
          </div>

          <div class="col-12 button-row">
            <button
              class="theme-btn submit-btn"
              (click)="contactUs()"
              [disabled]="contactUsForm.invalid"
            >
              Submit
            </button>
            <h1 data-aos="fade-left" class="contact-stemp">Contact</h1>
          </div>
        </form>
      </div>

      <!-- right  -->
      <div class="col-lg-4 col-md-4 col-sm-12 footer-right">
        <div class="footer-link">
          <ul class="footer-nav">
            <a class="nav-link" routerLink="/">Home</a>
            <a class="nav-link" routerLink="/terms-conditions"
              >Terms & Conditions</a
            >
            <a class="nav-link" routerLink="/privacy-policy">Privacy Policy</a>
            <a class="nav-link" routerLink="/">Contact us</a>
          </ul>

          <!-- address -->
          <div class="address-container">
            <div class="address">
              <!-- <h2 class="h2 m-0" >Contact Information</h2> -->
              <!-- <address> -->
              <!-- <p>#523 rosemary Street <br>
                  Strasbourg, 67000 USA</p>
                <hr> -->
              <!-- <p>Call us: +123 456 5463</p> -->
              <!-- <a href="mailto:shareagift@gmail.com">
                  <p>shareagift@gmail.com</p>
                </a> -->
              <!-- </address> -->

              <!-- <div class="social-icon">
                <h2 class="text-black">Follow us</h2>
                <a routerLink="/">
                  <img src="assets/images/facebook.png" alt="">
                </a>
                <a routerLink="/">
                  <img src="assets/images/instagram.png" alt="">
                </a>
                <a routerLink="/">
                  <img src="assets/images/twitter.png" alt="">
                </a>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
