<div class="continer-fluid p-0">

  <mat-horizontal-stepper #stepper >

<!-- guest user -->

    <!-- <mat-step [stepControl]="form.get('email')" [label]="'email'" *ngIf="!appService?.user" >
      <div class="heading-themes">
        <h3>
          {{'Contribute as a guest user' | uppercase}}
          <button class="float-right" mat-icon-button (click)="dialogRef.close()" >
            <mat-icon>clear</mat-icon>
          </button>
        </h3>
      </div>
      <form [formGroup]="form" >
        <div class="form-group">
          <label for="email">Email</label>
          <input required id="email" type="text" placeholder="Email" class="form-control" formControlName="email" >
        </div>
        <div class="text-right">
          <button ButtonUi type="submit"  size="lg" color="c1" matStepperNext [loading]="isLoading" [disabled]="isLoading || form.get('email').invalid" (click)="form.get(['campaign','link'])?.value ? submit() : null" >
            Next
          </button>
        </div>
      </form>
      <div class="or" *ngIf="!appService?.user?.id" >
        <hr class="col" >
        <h5 class="text-mute mx-3" >Or</h5>
        <hr class="col" >
      </div>
      <div class="text-center" *ngIf="!appService?.user?.id" >
        <a  routerLink="auth/sign-in" [queryParamsHandling]="'preserve'" [queryParams]="{'redirectTo':'pages/contribution'}" (click)="dialogRef.close()" >Sign In</a> &nbsp;&nbsp;
        <a routerLink="auth/sign-up" [queryParamsHandling]="'preserve'" [queryParams]="{'redirectTo':'pages/contribution'}" (click)="dialogRef.close()" >Sign Up</a>
      </div>
    </mat-step> -->

    <!-- geust user -->
  
    <mat-step [stepControl]="form.get('campaign')" *ngIf="!link" >
      <div class="heading-themes">
        <h3>
          {{'Enter Link' | uppercase}}
          <button class="float-right" mat-icon-button (click)="dialogRef.close()" >
            <mat-icon>clear</mat-icon>
          </button>
        </h3>
      </div>
    
    
    
      <form [formGroup]="form.get('campaign')" >
    
        <!-- <div class="form-group">
          <label for="Unique-code">Unique Code</label>
          <input id="Unique-code" type="text" placeholder="Enter Unique Code" class="form-control" formControlName="uniqueCode" >
        </div>

        <div class="or my-2">
          <hr class="col" >
          <h5 class="text-mute mx-3" >Or</h5>
          <hr class="col" >
        </div> -->

        <div class="form-group">
          <label for="Link">Link </label>
          <input id="Link" type="email" placeholder="Enter Link" class="form-control" formControlName="link" >
        </div>
    
        <div class="text-right">
          <button ButtonUi type="submit" (click)="submit()" size="lg" color="c1" [disabled]="isLoading || form.invalid || (!form.get(['campaign','uniqueCode'])?.value && !form.get(['campaign','link'])?.value)" >
            Contribute Now
          </button>
        </div>
        
      </form>
    </mat-step>
  
  </mat-horizontal-stepper>



</div>

