<div class="outer">

  <header>
    <h3>{{!data?.notCrop ? 'Crop Image' :'View Image'}}</h3>
  </header>

  <image-cropper *ngIf="!data?.notCrop"
    [imageChangedEvent]="selectedFile"
    [maintainAspectRatio]="true"
    [aspectRatio]="data.ratio || 1 / 1"
    format="png"
    (imageCropped)="imageCropped($event)"
    (imageLoaded)="imageLoaded()"
    (cropperReady)="cropperReady()"
    (loadImageFailed)="loadImageFailed()"
></image-cropper>

<lib-ngx-image-zoom *ngIf="data?.notCrop" class="mb-3"
    [thumbImage]="_file | base64 | async"
    [fullImage]="_file | base64 | async"
    [zoomMode]="'hover'"
    [enableScrollZoom]="true"
></lib-ngx-image-zoom>



<footer class="text-right" >
  <button class="mr-3" matRipple ButtonUi color="c2" (click)="dialogRef.close()" >
    Cancel
  </button>
  <button matRipple ButtonUi color="c1"  (click)="!data?.notCrop ? done() : select()" >
    Done
  </button>
</footer>

</div>