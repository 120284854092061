<div class="container-fluid">
  <h3 class="headtitle">INVITE PEOPLE TO CONTRIBUTE</h3>
  <form [formGroup]="form" autocapitalize="off">
    <mat-card class="mat-card p-4">
      <div class="text-center">
        <img
          class="my-4 rounded"
          width="200px"
          src="assets/images/Depositphotos_81565988_l-2015.jpg"
          alt=""
        />
        <h4 class="mb-3 color-pinky-coral">
          {{ campaignContent?.invitePeopleToContribute?.heading1 || "--" }}
          <!-- Now it's time to invite people to contribute. Here's your unique link. -->
          <!-- Wahey, you’ve created a CollectaGift Pot for {{campaign?.receipientName || 'Recipients'}}. Now it’s time <br> to share it, here’s your link -->
        </h4>
      </div>
      <div class="row">
        <div class="col-12 text-center">
          <button 
            (click)="navigateToContribute(campaignId)"
            class="font-12 theme-btn yellow"
          >
            Click here to view ({{
              form.get("receipientName")?.value | titlecase
            }}'s) CollectaGift Pot Contribution Page
          </button>
          <button
            mat-icon-button
            type="button"
            title="Press me to copy link and share with friends to contribute"
            (click)="copyLink()"
          >
            <img src="assets/images/clipboard.png" alt="" />
          </button>
        </div>
        <div class="col-12"></div>

        <div class="col-12 mb-3">
          <!-- {{ campaignContent?.invitePeopleToContribute?.description1 || "--" }} -->
          Please copy and paste this link. (<span
            [innerHTML]="thisLink('Click here')"
          ></span
          >) and share it with those you would like to invite to contribute.
          This link will also be emailed to you (Check Your Junk) and available
          on your dashboard for you to access at any time and invite people to
          contribute. Contributors will have until
          {{ campaign?.collectionDeadline | date : "dd MMMM yyyy" }} to
          contribute to this CollectaGift Pot, when the link will expire. Once
          it expires, you'll be able to redeem the funds from the CollectaGift
          Pot to buy the gift and optional card via your Dashboard. In case you
          would like to make any changes to the CollectaGift Pot (eg: change the
          expiry date), you can click back to step 1 and make the necessary
          edits anytime before the CollectaGift Pot expires! You can also
          manually close the CollectaGift Pot early or reopen a closed pot via
          your Dashboard.

          <!-- The contributors will have until
          {{ campaign?.collectionDeadline | date: "dd MMMM yyyy" }} to
          contribute to this CollectaGift Pot, when the link will expire. Once
          it expires, you'll be able to redeem the funds from the CollectaGift
          Pot to buy the gift and optional card. -->
        </div>

        <!-- new paragraph -->
        <div class="col-12 mb-3">
          <!-- {{ campaignContent?.invitePeopleToContribute?.description1 || "--" }} -->

          <!-- Now it's time to start thinking about what you may like to buy for
          {{ form.get("receipientName")?.value | titlecase }}. In case you're
          stuck for ideas, we're delighted to offer you the following exclusive
          CollectaGift discounts with our CollectaGift partners: -->

          Now it's time to start thinking about what you may want to buy for the
          {{ form.get("receipientName")?.value | titlecase }}. Whilst you can
          choose from over a hundred high-street brands in our
          <a
            class="skyblue link"
            (click)="
              $event.stopPropagation();
              $event.preventDefault();
              saveAndContinue(true)
            "
          >
            gift catalogue </a
          >, we're also proud to offer some exclusive discounts with some
          independent retailers* (more coming soon...).

          <!-- The contributors will have until
          {{ campaign?.collectionDeadline | date: "dd MMMM yyyy" }} to
          contribute to this CollectaGift Pot, when the link will expire. Once
          it expires, you'll be able to redeem the funds from the CollectaGift
          Pot to buy the gift and optional card. -->
        </div>
        <div class="col-12 mb-3">
          <p>
            <!-- Now it's time to start thinking about what you may like to buy for
            {{ campaign?.receipientName || "Recipients" }}. In case you're stuck
            for ideas, we're delighted to offer you the following exclusive
            CollectaGift discounts with our CollectaGift partners: -->
          </p>

          <div class="row">
            <span class="">
              <!-- <b>
                {{
                  campaignContent?.invitePeopleToContribute?.heading2 || "--"
                }}
              </b> -->
            </span>
            <div class="col-lg-6 col-md-6 col-12 text-center">
              <div>
                <a href="https://owendrewcandles.com" target="_blank">
                  <img
                    [src]="invitePeopleToContribute[0]"
                    height="71px"
                    alt=""
                    class="cg2Img rounded mb-3"
                  />
                </a>
              </div>
            </div>
            <div class="col-12">
              <!-- <b>
                {{
                  campaignContent?.invitePeopleToContribute?.heading2 || "--"
                }}
              </b> -->
              *Please note that you currently cannot use the discount code
              within our catalogue however you can choose to payout the
              redemption proceeds to a Prepaid Debit Card and then use the
              Prepaid Debit Card to buy directly from them and benefit from our
              exclusive discount code.
            </div>
            <!-- <div class="col-lg-6 col-md-6 col-12 text-center">
              <div>
                <a href="https://www.tiffanylandale.co.uk" target="_blank">
                  <img
                    [src]="invitePeopleToContribute[1]"
                    height="71px"
                    alt=""
                    class="cg2Img rounded mb-3"
                  />
                </a>
              </div>
              <span class=""
                ><b>
                  {{
                    campaignContent?.invitePeopleToContribute?.heading3 || "--"
                  }}
                </b>
              </span>
            </div> -->

            <div
              class="col-lg-6 col-md-6 col-12 text-center mt-4"
              *ngIf="campaignContent?.invitePeopleToContribute?.heading4"
            >
              <div>
                <img
                  [src]="invitePeopleToContribute[2]"
                  onerror="this.src='assets/images/tiffny-logo.png'"
                  height="71px"
                  alt=""
                  class="cg2Img rounded mb-3"
                />
              </div>
              <span class=""
                ><b>
                  {{
                    campaignContent?.invitePeopleToContribute?.heading4 || "--"
                  }}
                </b>
              </span>
            </div>

            <div
              class="col-lg-6 col-md-6 col-12 text-center mt-4"
              *ngIf="campaignContent?.invitePeopleToContribute?.heading5"
            >
              <div>
                <img
                  [src]="invitePeopleToContribute[3]"
                  onerror="this.src='assets/images/tiffny-logo.png'"
                  height="71px"
                  alt=""
                  class="cg2Img rounded mb-3"
                />
              </div>
              <span class=""
                ><b>
                  {{
                    campaignContent?.invitePeopleToContribute?.heading5 || "--"
                  }}
                </b>
              </span>
            </div>
          </div>
        </div>
        <!-- <div class="col-lg-6 pb-4">
          <div class="form-group" >
            <label for="create-code">
              You can also create a Unique Code for your Campaigns and share it
            </label> <br>
            <div class="d-flex" >
            <input id="create-code" [readOnly]="campaign['uniqueCode']" type="text" formControlName="uniqueCode" class="form-control mr-lg-2" placeholder="Enter Unique Code Here..." >
            <div>
              <button type="submit" *ngIf="!campaign['uniqueCode']" ButtonUi color="c1" [disabled]="form.get('uniqueCode').invalid || checking" (click)="checkUniqueCode()" >Generate</button>
              <div matRipple class="rounded p-2" *ngIf="campaign['uniqueCode']" (click)="copyUniqueCode()" >
                <img src="assets/images/clipboard.png" alt="">
              </div>
            </div>
            <div></div>
            </div>
            <div class="text-danger" *ngIf="form.get('uniqueCode')?.touched && form.get('uniqueCode').invalid" >
              <div class="d-flex align-content-center" [@error]="form.get('uniqueCode')?.errors?.invalid ? 'show' : 'hide'" >
                <mat-icon>error</mat-icon>
                <div class="ml-2 my-auto" >Code already exists</div>
              </div>
            </div>
            <div class="text-success" >
              <div class="d-flex align-content-center" [@error]="success ? 'show' : 'hide'" >
                <mat-icon>check</mat-icon>
                <div class="ml-2 my-auto" >Unique Code successfully Generated</div>
              </div>
            </div>
          </div>


        </div> -->
        <!-- <div class="col-6 form-group button">
          <label for="">Share by email</label>
          <input type="text" class="form-control" [formControl]="email" placeholder="Email Address"  >
          <button type="submit" ButtonUi color="c1" [disabled]="email.invalid" (click)="addInvitation()" >Add</button>
        </div>
        <div class="col-12 mb-3" >
          <div *ngFor="let invitation of invitedEmails.controls;let i = index;" class="d-flex align-items-center" >
            <button type="button" [disabled]="invitation.value?.loading" mat-icon-button (click)="delete(i)" >
              <img src="assets/images/delete.png" alt="">
            </button>
            <span class="skyblue" >{{invitation.value?.email || 'N/a'}}</span>
            <mat-error *ngIf="!invitation.value?.loading && !invitation.value?.id"  >
              <a (click)="create(invitation)"  >Retry</a>
            </mat-error>
            <mat-spinner *ngIf="invitation.value?.loading"  class="ml-3 orange" [diameter]="20" [strokeWidth]="1.5" ></mat-spinner>
          </div>
        </div> -->
      </div>
    </mat-card>
    <div class="actions">
      <button
        type="button"
        ButtonUi
        color="c1"
        size="lg"
        (click)="onNext(tosterTemp)"
        [disabled]="checking"
      >
        NEXT
      </button>
    </div>
  </form>
</div>

<ng-template #tosterTemp>
  <span class="text-red font-weight-bold">
    This CollectaGift Pot has already been redeemed.
  </span>
</ng-template>
