<!-- navbar -->
<!-- <div class="container-fluid pl-0 nav-section">
  <nav class="navbar navbar-expand-lg ml-auto mt-5">
    <div class="nav-container">
      <div class="button_container  {{isOpened?'active':''}}" id="toggle" (click)="isOpened = !isOpened">
        <span class="top"></span>
        <span class="middle"></span>
        <span class="bottom"></span>
      </div>
      <div class="overlay {{isOpened?'open':''}}" id="overlay">
        <nav class="overlay-menu">
          <ul>
            <li class="nav-item active">
              <a class="nav-link" (click)="isOpened = !isOpened" routerLink="/home">Home</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" (click)="isOpened = !isOpened" routerLink="/about-us">About us</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" (click)="isOpened = !isOpened" routerLink="/about-us" [fragment]="'contact'"
                tabindex="-1" aria-disabled="true">Contact us</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" (click)="isOpened = !isOpened" routerLink="/fees">Fees</a>
            </li>
            <li class="nav-item" *ngIf="!user?.id">
              <button class="orange-btn outline light" (click)="navigate('/pages/detail');isOpened = !isOpened">Log
                In</button>
            </li>
            <li class="nav-item" *ngIf="user?.id">
              <button mat-icon-button class="bg-mute mx-3 profile"
                (click)="navigate('/pages/detail');isOpened = !isOpened">
                <img class="rounded-circle img-fluid" [src]="user?.imgUrl || 'assets/images/user icon.png'" alt="">
              </button>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </nav>
</div> -->

<div class="container-fluid pl-0 nav-section">
  <nav class="navbar navbar-expand-lg ml-auto">
    <div class="nav-container">
      <div
        class="button_container  {{ isOpened ? 'active' : '' }}"
        id="toggle"
        (click)="isOpened = !isOpened"
      >
        <span class="top"></span>
        <span class="middle"></span>
        <span class="bottom"></span>
      </div>
      <div class="overlay {{ isOpened ? 'open' : '' }}" id="overlay">
        <nav class="overlay-menu">
          <ul>
            <li class="nav-item active">
              <a
                class="nav-link"
                (click)="isOpened = !isOpened"
                routerLink="/home"
                >Home</a
              >
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                (click)="isOpened = !isOpened"
                routerLink="/about-us"
                >About us</a
              >
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                (click)="isOpened = !isOpened"
                routerLink="/about-us"
                [fragment]="'contact'"
                tabindex="-1"
                aria-disabled="true"
                >Contact us</a
              >
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                (click)="isOpened = !isOpened"
                routerLink="/faqs"
                >FAQs</a
              >
            </li>
            <li class="nav-item" *ngIf="!user?.id">
              <button
                class="orange-btn outline light"
                (click)="navigate('/pages/detail'); isOpened = !isOpened"
              >
                Log In
              </button>
            </li>
            <li class="nav-item" *ngIf="user?.id">
              <button
                mat-icon-button
                class="bg-mute mx-3 profile"
                (click)="navigate('/pages/detail'); isOpened = !isOpened"
              >
                <img
                  class="rounded-circle img-fluid"
                  [src]="user?.imgUrl || 'assets/images/user icon.png'"
                  alt=""
                />
              </button>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </nav>
  <div class="top-m">
    <div class="container header-heading text-black">
      <div>
        <img width="200px" src="assets/logos/CollectaGift-Final.png" alt="" />
        <p class="pl-4">Group Gifting, Sorted</p>
      </div>
    </div>
  </div>
</div>

<section class="p-relative welcm-sec mb-0 mt-0">
  <img class="abt_grphcs1" src="assets/images/about_grphcs1.png" />
  <img class="abt_grphcs2" src="assets/images/about_grphcs1.png" />
  <img class="abt_grphcs3" src="assets/images/about_grphcs2.svg" />
  <div class="container">
    <div class="row">
      <div class="col-lg-4">
        <div class="faq-head">
          <div class="pb-4">
            <a
              (click)="showData('Starting')"
              [ngClass]="Starting ? 'active' : ''"
              class="black font-16 cursor"
              >Creating an Online CollectaGift Pot</a
            >
          </div>
          <div class="pb-4">
            <a
              (click)="showData('Redeeming')"
              [ngClass]="Redeeming ? 'active' : ''"
              class="black font-16 cursor"
              >Redeeming an Online CollectaGift Pot</a
            >
          </div>
          <div class="pb-4">
            <a
              (click)="showData('Contribute')"
              [ngClass]="Contribute ? 'active' : ''"
              class="black font-16 cursor"
              >Contributing to an OnlineCollectaGift Pot</a
            >
          </div>
          <div class="pb-4">
            <a
              (click)="showData('CharityDonations')"
              [ngClass]="CharityDonations ? 'active' : ''"
              class="black font-16 cursor"
              >CollectaGift CharityChampions & CharityDonations
            </a>
          </div>
          <div class="pb-4">
            <a
              (click)="showData('Managing')"
              [ngClass]="Managing ? 'active' : ''"
              class="black font-16 cursor"
              >Managing an OnlineCollectaGift Pot
            </a>
          </div>
          <div class="pb-4">
            <a
              (click)="showData('GreetingCard')"
              [ngClass]="GreetingCard ? 'active' : ''"
              class="black font-16 cursor"
              >Greeting Card</a
            >
          </div>
          <div class="pb-4">
            <a
              (click)="showData('Fees')"
              [ngClass]="Fees ? 'active' : ''"
              class="black font-16 cursor"
              >Fees</a
            >
          </div>
        </div>
      </div>
      <div class="col-lg-8">
        <div class="faq-data">
          <div *ngIf="Starting">
            <div id="accordion">
              <div
                class="card"
                *ngFor="
                  let item of faqContent?.CreatingAnOnlineCollectaGiftPot;
                  let i = index
                "
              >
                <div
                  class="card-header"
                  [class.collapsed]="i !== 0"
                  id="headingOne{{ i }}"
                  data-toggle="collapse"
                  [attr.data-target]="'#collapseOne' + i"
                  [attr.aria-controls]="'collapseOne' + i"
                >
                  <button class="btn btn-link">
                    {{ item.que }}
                  </button>
                  <div>
                    <img class="add" src="assets/images/add.svg" />
                    <img class="cross" src="assets/images/cross.svg" />
                  </div>
                </div>

                <div
                  id="collapseOne{{ i }}"
                  class="collapse"
                  [class.show]="i == 0"
                  [attr.aria-labelledby]="'headingOne' + i"
                  data-parent="#accordion"
                >
                  <div class="card-body">
                    {{ item.ans }}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div *ngIf="Redeeming">
            <div id="accordion">
              <div
                class="card"
                *ngFor="
                  let item of faqContent?.RedeemingAnOnlineCollectaGiftPot;
                  let i = index
                "
              >
                <div
                  class="card-header"
                  [class.collapsed]="i !== 0"
                  id="headingOne{{ i }}"
                  data-toggle="collapse"
                  [attr.data-target]="'#collapseOne' + i"
                  [attr.aria-controls]="'collapseOne' + i"
                >
                  <button class="btn btn-link">
                    {{ item.que }}
                  </button>
                  <div>
                    <img class="add" src="assets/images/add.svg" />
                    <img class="cross" src="assets/images/cross.svg" />
                  </div>
                </div>

                <div
                  id="collapseOne{{ i }}"
                  class="collapse"
                  [class.show]="i == 0"
                  [attr.aria-labelledby]="'headingOne' + i"
                  data-parent="#accordion"
                >
                  <div class="card-body">
                    {{ item.ans }}
                  </div>
                </div>
              </div>
              <!-- <div class="card">
                <div class="card-header" id="headingOne" data-toggle="collapse" data-target="#collapseOne"
                  aria-expanded="true" aria-controls="collapseOne">
                  <button class="btn btn-link">
                    Why do I need to complete my KYC details?
                  </button>
                  <div>
                    <img class="add" src="assets/images/add.svg" />
                    <img class="cross" src="assets/images/cross.svg" />
                  </div>
                </div>

                <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordion">
                  <div class="card-body">
                    KYC details are required by Stripe, our third-party payment provider, in order to payout the funds
                    to your nominated bank account. This is to ensure all transactions are secure and to prevent fraud.
                    This requirement to provide your KYC details is only a one-time requirement and is only required the
                    very first time you payout funds to your bank account. Once provided once, you’ll never be required
                    to provide them again.
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-header collapsed" id="headingTwo" data-toggle="collapse" data-target="#collapseTwo"
                  aria-expanded="false" aria-controls="collapseTwo">
                  <button class="btn btn-link">
                    What can I spend the CollectaGift proceeds on?
                  </button>
                  <div>
                    <img class="add" src="assets/images/add.svg" />
                    <img class="cross" src="assets/images/cross.svg" />
                  </div>
                </div>
                <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
                  <div class="card-body">
                    Your CollectaGift proceeds should be used to purchase a gift for the intended recipient, but you are
                    free to purchase whatever gift you like. Keep an eye out though for exclusive discounts from our
                    partner gift providers.
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-header collapsed" id="headingThree" data-toggle="collapse" data-target="#collapseThree"
                  aria-expanded="false" aria-controls="collapseThree">
                  <button class="btn btn-link">
                    How long does it take for the CollectaGift pot proceeds to be paid out to my bank account?
                  </button>
                  <div>
                    <img class="add" src="assets/images/add.svg" />
                    <img class="cross" src="assets/images/cross.svg" />
                  </div>
                </div>
                <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordion">
                  <div class="card-body">
                    It currently takes 7 days to receive the proceeds to a UK bank account
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-header collapsed" id="headingFour" data-toggle="collapse" data-target="#collapseFour"
                  aria-expanded="false" aria-controls="collapseFour">
                  <button class="btn btn-link">
                    How much does it cost to redeem a CollectaGift pot?
                  </button>
                  <div>
                    <img class="add" src="assets/images/add.svg" />
                    <img class="cross" src="assets/images/cross.svg" />
                  </div>
                </div>
                <div id="collapseFour" class="collapse" aria-labelledby="headingFour" data-parent="#accordion">
                  <div class="card-body">
                    CollectaGift doesn’t charge anything to payout the CollectaGift Pot proceeds to your nominated bank
                    account. However Stripe fees are payable (£2.10 + 0.25%) and these are deducted from the
                    CollectaGift proceeds and go straight to Stripe. Although we’ve got you covered if the CollectaGift
                    Pot proceeds are less than £100 and you purchase the group card, we’ll pay your Stripe transaction
                    fees!
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-header collapsed" id="headingFive" data-toggle="collapse" data-target="#collapseFive"
                  aria-expanded="false" aria-controls="collapseFive">
                  <button class="btn btn-link">
                    How much does it cost to redeem a CollectaGift pot?
                  </button>
                  <div>
                    <img class="add" src="assets/images/add.svg" />
                    <img class="cross" src="assets/images/cross.svg" />
                  </div>
                </div>
                <div id="collapseFive" class="collapse" aria-labelledby="headingFive" data-parent="#accordion">
                  <div class="card-body">
                    CollectaGift doesn’t charge anything to payout the CollectaGift Pot proceeds to your nominated bank
                    account. However Stripe fees are payable (£2.10 + 0.25%) and these are deducted from the
                    CollectaGift proceeds and go straight to Stripe. Although we’ve got you covered if the CollectaGift
                    Pot proceeds are less than £100 and you purchase the group card, we’ll pay your Stripe transaction
                    fees!
                  </div>
                </div>
              </div> -->
            </div>
          </div>

          <div *ngIf="Contribute">
            <div id="accordion">
              <div
                class="card"
                *ngFor="
                  let item of faqContent?.ContributingToAnOnlineCollectaGiftPot;
                  let i = index
                "
              >
                <div
                  class="card-header"
                  [class.collapsed]="i !== 0"
                  id="headingOne{{ i }}"
                  data-toggle="collapse"
                  [attr.data-target]="'#collapseOne' + i"
                  [attr.aria-controls]="'collapseOne' + i"
                >
                  <button class="btn btn-link">
                    {{ item.que }}
                  </button>
                  <div>
                    <img class="add" src="assets/images/add.svg" />
                    <img class="cross" src="assets/images/cross.svg" />
                  </div>
                </div>

                <div
                  id="collapseOne{{ i }}"
                  class="collapse"
                  [class.show]="i == 0"
                  [attr.aria-labelledby]="'headingOne' + i"
                  data-parent="#accordion"
                >
                  <div class="card-body">
                    {{ item.ans }}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div *ngIf="CharityDonations">
            <div id="accordion">
              <div
                class="card"
                *ngFor="
                  let item of faqContent[
                    'CollectaGiftCharityChampions&CharityDonations'
                  ];
                  let i = index
                "
              >
                <div
                  class="card-header"
                  [class.collapsed]="i !== 0"
                  id="headingOne{{ i }}"
                  data-toggle="collapse"
                  [attr.data-target]="'#collapseOne' + i"
                  [attr.aria-controls]="'collapseOne' + i"
                >
                  <button class="btn btn-link">
                    {{ item.que }}
                  </button>
                  <div>
                    <img class="add" src="assets/images/add.svg" />
                    <img class="cross" src="assets/images/cross.svg" />
                  </div>
                </div>

                <div
                  id="collapseOne{{ i }}"
                  class="collapse"
                  [class.show]="i == 0"
                  [attr.aria-labelledby]="'headingOne' + i"
                  data-parent="#accordion"
                >
                  <div class="card-body">
                    {{ item.ans }}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div *ngIf="Managing">
            <div id="accordion">
              <div
                class="card"
                *ngFor="
                  let item of faqContent?.ManagingAnOnlineCollectaGiftPot;
                  let i = index
                "
              >
                <div
                  class="card-header"
                  [class.collapsed]="i !== 0"
                  id="headingOne{{ i }}"
                  data-toggle="collapse"
                  [attr.data-target]="'#collapseOne' + i"
                  [attr.aria-controls]="'collapseOne' + i"
                >
                  <button class="btn btn-link">
                    {{ item.que }}
                  </button>
                  <div>
                    <img class="add" src="assets/images/add.svg" />
                    <img class="cross" src="assets/images/cross.svg" />
                  </div>
                </div>

                <div
                  id="collapseOne{{ i }}"
                  class="collapse"
                  [class.show]="i == 0"
                  [attr.aria-labelledby]="'headingOne' + i"
                  data-parent="#accordion"
                >
                  <div class="card-body">
                    {{ item.ans }}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div *ngIf="GreetingCard">
            <div id="accordion">
              <div
                class="card"
                *ngFor="let item of faqContent?.GreetingCard; let i = index"
              >
                <div
                  class="card-header"
                  [class.collapsed]="i !== 0"
                  id="headingOne{{ i }}"
                  data-toggle="collapse"
                  [attr.data-target]="'#collapseOne' + i"
                  [attr.aria-controls]="'collapseOne' + i"
                >
                  <button class="btn btn-link">
                    {{ item.que }}
                  </button>
                  <div>
                    <img class="add" src="assets/images/add.svg" />
                    <img class="cross" src="assets/images/cross.svg" />
                  </div>
                </div>

                <div
                  id="collapseOne{{ i }}"
                  class="collapse"
                  [class.show]="i == 0"
                  [attr.aria-labelledby]="'headingOne' + i"
                  data-parent="#accordion"
                >
                  <div class="card-body">
                    {{ item.ans }}
                  </div>
                </div>
              </div>
              <!-- <div class="card">
                <div class="card-header" id="headingOne" data-toggle="collapse" data-target="#collapseOne"
                  aria-expanded="true" aria-controls="collapseOne">
                  <button class="btn btn-link">
                    Is there a minimum/maximum contribution amount?
                  </button>
                  <div>
                    <img class="add" src="assets/images/add.svg" />
                    <img class="cross" src="assets/images/cross.svg" />
                  </div>
                </div>

                <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordion">
                  <div class="card-body">
                    You’re free to contribute as much as you want to a CollectaGift Pot, however the minimum
                    contribution is £1.
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-header collapsed" id="headingTwo" data-toggle="collapse" data-target="#collapseTwo"
                  aria-expanded="false" aria-controls="collapseTwo">
                  <button class="btn btn-link">
                    Can I contribute from outside the UK?
                  </button>
                  <div>
                    <img class="add" src="assets/images/add.svg" />
                    <img class="cross" src="assets/images/cross.svg" />
                  </div>
                </div>
                <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
                  <div class="card-body">
                    Yes, you can contribute from anywhere in the world!
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-header collapsed" id="headingThree" data-toggle="collapse" data-target="#collapseThree"
                  aria-expanded="false" aria-controls="collapseThree">
                  <button class="btn btn-link">
                    How do I know my contribution is secure?
                  </button>
                  <div>
                    <img class="add" src="assets/images/add.svg" />
                    <img class="cross" src="assets/images/cross.svg" />
                  </div>
                </div>
                <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordion">
                  <div class="card-body">
                    We use Stripe, a third-party payment provider, to process all payments.
                  </div>
                </div>
              </div> -->
            </div>
          </div>

          <div *ngIf="Fees">
            <div class="pl-5">
              <h4>Fees</h4>
              <div class="">
                <p
                  class="mb-1"
                  *ngFor="let item of faqContent?.fees | slice : 0 : 2"
                >
                  {{ item?.description }}
                </p>
                <div
                  *ngFor="
                    let item of faqContent?.fees
                      | slice : 2 : faqContent?.fees?.length
                  "
                >
                  <h4 class="hd2 pt-3">
                    {{ item.heading }}
                  </h4>
                  <p class="mb-1">
                    {{ item.description }}
                  </p>
                </div>
                <!-- <h4 class="hd2 pt-3">
                  Cost to make a contribution into a CollectaGift Pot
                </h4>
                <p class="mb-1">
                  This varies according to the currency of the contribution but
                  is clearly stated when making your contribution.
                </p>
                <h4 class="hd2 pt-3">
                  For GBP the fee to deposit funds is 20p + 1.8% of contribution
                  amount
                </h4>
                <p class="mb-1">
                  A £5.00 contribution incurs a 29p (20p + 9p) payment
                  processing fee upon depositing funds. You have the option to
                  add the payment processing fee onto your contribution so that
                  100% of your contribution amount goes into the CollectaGift
                  Pot. If you do not select this option, the payment processing
                  fee will be deducted from your individual contribution amount.
                </p>
                <h4 class="hd2 pt-3">
                  Cost to redeem the CollectaGift Pot amount
                </h4>
                <p class="mb-1">
                  This varies according to the currency of the contribution but
                  is clearly stated when making your contribution. For GBP the
                  fee to withdraw funds is £2.10 + 0.25% of the total
                  CollectaGift Pot value. e.g. A £100 Pot incurs a £2.35 (£2.10
                  + 25p) payment fee upon withdrawal of funds. This is deducted
                  from the total CollectaGift Pot value.
                </p>
                <p class="mb-1">
                  CollectaGift will pay this fee on your behalf if a Group
                  Greeting Card is purchased (see below) and the total
                  CollectaGift Pot value is £100 or less. This is to ensure
                  that, where possible, 100% of the CollectaGift Pot goes to the
                  intended recipient.
                </p>
                <h4 class="hd2 pt-3">Cost to purchase a Group Greeting Card</h4>
                <p class="mb-1">
                  The cost to purchase a digital, print-at-home greeting card is
                  £3.00.
                </p>
                <p class="mb-1">
                  If your total CollectaGift Pot value is £100 or less, we’ll
                  use the sale proceeds from your Group Greeting Card to pay the
                  Stripe withdrawal fee. This way, your deserving gift recipient
                  receives as much of their CollectaGift Pot as possible.
                </p>
                <p class="mb-1">
                  For CollectaGift Pots totalling more than £100, the cost of
                  the digital card is deducted from the total CollectaGift Pot
                  value.
                </p> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<!-- contct -->
<section class="p-relative contact-section p-0 mb-0 mt-0" id="contact">
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-8 col-md-8 col-sm-12 contact-blue text-white">
        <div class="heading-theme">
          <h3 class="m-0">Contact Us</h3>
          <p>
            Contact us using the form below or email:
            <a class="text-white" href="mailTo:hello@collectagift.com"
              >hello&#64;collectagift.com</a
            >
          </p>
          <img
            src="assets/images/round-object.png"
            class="img-fluid round"
            alt=""
          />
        </div>

        <form
          class="row pt-4 m-0"
          [formGroup]="contactUsForm"
          autocomplete="off"
        >
          <div class="col-lg-6 col-12 pl-0">
            <div class="form-group">
              <label for="name"></label>
              <input
                id="name"
                type="text"
                class="form-control"
                formControlName="name"
                placeholder="Name"
              />
            </div>
          </div>

          <div class="col-lg-6 col-12 pl-0">
            <div class="form-group">
              <label for="name"></label>
              <input
                id="name"
                type="email"
                class="form-control"
                formControlName="email"
                placeholder="Email"
              />
            </div>
          </div>

          <div class="col-lg-12 col-12 pt-4 pl-0">
            <div class="form-group">
              <label for="name"></label>
              <input
                id="name"
                type="text"
                class="form-control"
                formControlName="message"
                placeholder="Message"
              />
            </div>
          </div>

          <div class="col-12 button-row">
            <button
              class="theme-btn submit-btn"
              (click)="contactUs()"
              [disabled]="contactUsForm.invalid"
            >
              Submit
            </button>
            <h1 class="contact-stemp">Contact</h1>
          </div>
        </form>
      </div>

      <!-- right  -->
      <div class="col-lg-4 col-md-4 col-sm-12 footer-right">
        <div class="footer-link">
          <ul class="footer-nav">
            <a class="nav-link" routerLink="/">Home</a>
            <a class="nav-link" routerLink="/">Campaigns</a>
            <a class="nav-link" routerLink="/">Contribution</a>
            <a class="nav-link" routerLink="/">Contact us</a>
          </ul>

          <!-- address -->
          <div class="address-container">
            <div class="address">
              <!-- <h2 class="h2 m-0" >Contact Information</h2> -->
              <!-- <address> -->
              <!-- <p>#523 rosemary Street <br>
                  Strasbourg, 67000 USA</p>
                <hr> -->
              <!-- <p>Call us: +123 456 5463</p> -->
              <!-- <a href="mailto:shareagift@gmail.com">
                  <p>shareagift@gmail.com</p>
                </a> -->
              <!-- </address> -->

              <div class="social-icon">
                <h2 class="text-black">Follow us</h2>
                <a routerLink="/">
                  <img src="assets/images/facebook.png" alt="" />
                </a>
                <a routerLink="/">
                  <img src="assets/images/instagram.png" alt="" />
                </a>
                <a routerLink="/">
                  <img src="assets/images/twitter.png" alt="" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<!-- <div class="row">
  <div class="col-12">
    <div class="pl-5">
      <h4>Fees</h4>
      <div class="">
        <p>
          At CollectaGift, we want the money you collect to go towards your recipient’s Gift. This is why we do not charge a service fee for creating a CollectaGift Pot</p>
        <p class="mb-1">However, transaction fees are payable to Stripe, our third party payment provider, for making contributions into a CollectaGift pot and redeeming any fund from the CollectaGift pot and these will need to be paid for.</p>
       <h4 class="hd2 pt-3">Cost to make a contribution into a CollectaGift Pot</h4>
       <p class="mb-1">This varies according to the currency of the contribution but is clearly stated when making your contribution.</p>
       <h4 class="hd2 pt-3">For GBP the fee to deposit funds is 20p + 1.8% of contribution amount </h4>
       <p class="mb-1">A £5.00 contribution incurs a 29p (20p + 9p) payment processing fee upon depositing funds. 
        You have the option to add the payment processing fee onto your contribution so that 100% of your contribution amount goes into the CollectaGift Pot. If you do not select this option, the payment processing fee will be deducted from your individual contribution amount. </p>
        <h4 class="hd2 pt-3">Cost to redeem the CollectaGift Pot amount</h4>
        <p class="mb-1">This varies according to the currency of the contribution but is clearly stated when making your contribution.
          For GBP the fee to withdraw funds is £2.10 + 0.25% of the total CollectaGift Pot value. 
          e.g. A £100 Pot incurs a £2.35 (£2.10 + 25p) payment fee upon withdrawal of funds. 
          This is deducted from the total CollectaGift Pot value. </p>
          <p class="mb-1">CollectaGift will pay this fee on your behalf if a Group Greeting Card is purchased (see below) and the total CollectaGift Pot value is £100 or less. This is to ensure that, where possible, 100% of the CollectaGift Pot goes to the intended recipient.</p>
          <h4 class="hd2 pt-3">Cost to purchase a Group Greeting Card</h4>
          <p class="mb-1">The cost to purchase a digital, print-at-home greeting card is £3.00.</p>
          <p class="mb-1">If your total CollectaGift Pot value is £100 or less, we’ll use the sale proceeds from your Group Greeting Card to pay the Stripe withdrawal fee. This way, your deserving gift recipient receives as much of their CollectaGift Pot as possible.</p>
          <p class="mb-1">For CollectaGift Pots totalling more than £100, the cost of the digital card is deducted from the total CollectaGift Pot value.</p>
      </div>
    </div>
  </div>
</div> -->
