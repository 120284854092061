import { Component, OnInit } from '@angular/core';
import { Contribution } from 'src/app/models/contribution';
import { ContributionService } from 'src/app/services/contributions.service';
import { Page } from 'src/app/http-handler/common/contracts/page';
import { AppService } from 'src/app/services/app.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-contributions',
  templateUrl: './contributions.component.html',
  styleUrls: ['./contributions.component.scss']
})
export class ContributionsComponent implements OnInit {

  contribution: Page<Contribution>;

  constructor(
    private _appService: AppService,
    private _contributionService: ContributionService,
    private _router: Router
  ) {

    this.contribution = new Page({
      api: _contributionService.contributions,
      filters: [{
        field: 'createdAt',
        value: null
      },{
        field: 'paymentStatus',
        value: ''
      },{
        field: 'userId',
        value: _appService.user.id
      }]
    });

    this.fetch(true);

   }

  ngOnInit() {
  }
  
  

  fetch(loading?: boolean) {
    if (loading) this._appService.loadingShow();
    this.contribution.fetch().then(d => {
      this._appService.loadingHide();
    }).catch(err => {
      this._appService.loadingHide();
      this._appService.snackBarOpen(err);
    })
  }

}
