import { Component, OnInit, ViewChild } from '@angular/core';
import { Page } from 'src/app/http-handler/common/contracts/page';
import { Campaign } from 'src/app/models/campaign.model';
import { AppService } from 'src/app/services/app.service';
import { CampaignService } from 'src/app/services/campaign.service';
import { Router } from '@angular/router';
import { PayoutComponent } from '../../shareModule/dialogs/payout/payout.component';
import { BankAcountComponent } from './bank-acount/bank-acount.component';

@Component({
  selector: 'app-pay-out',
  templateUrl: './pay-out.component.html',
  styleUrls: ['./pay-out.component.scss']
})
export class PayOutComponent implements OnInit {

  campaigns: Page<Campaign>;
  @ViewChild('bankAccounts') bankAccounts:BankAcountComponent;

  constructor(
    private _appService: AppService,
    private _campaignsService: CampaignService,
    private _router: Router
  ) {

    this.campaigns = new Page({
      api: _campaignsService.campaigns,
      filters: [{
        field: 'filter',
        value: 'payout',
      }]
    });

    this.fetch(true);

  }

  ngOnInit() {
  }

  fetch(loading?: boolean) {
    this.campaigns.fetch().then(d => {

    }).catch(err => {
      this._appService.snackBarOpen(err);
    })
  }

  payout(index: number) {
    let dialog = this._appService.matDialog.open(PayoutComponent, { data: this.campaigns.items[index] });
    dialog.afterClosed().subscribe(d => {
      if (d) {
        this.campaigns.items[index] = { ...this.campaigns.items[index], ...d };
      }
      if(this.bankAccounts?.fetch){
        this.bankAccounts.fetch();
      }
    });
  }

}
