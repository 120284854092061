import { Injectable } from '@angular/core';
import { IApi } from '../http-handler/common/contracts/api';
import { HttpClient } from '@angular/common/http';
import { GenericApi } from '../http-handler/common/generic-api';
import { Contribution } from '../models/contribution';

@Injectable({
  providedIn: 'root'
})
export class ContributionService {

  contributions:IApi<Contribution>;

constructor(
  http:HttpClient
) {

  this.contributions = new GenericApi<Contribution>('contributions',http);

 }

}
