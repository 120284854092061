<div class="dialog-container p-0">
  <header class="border-bottom flex-header-browser p-3 d-flex align-items-center justify-content-between position-relative">
    <div class="flex-header ">
      <h5 class="m-0">
        {{
          data?.viewOnly
            ? "Browse available Gift Providers (you can select your provider in the coming steps)"
            : data?.productId?.isPayout
            ? "Buy Prepaid MasterCard"
            : "Please choose your Gift Provider here"
        }}
      </h5>
      <span
        *ngIf="!data?.viewOnly && !data?.productId?.isPayout"
        class="text-header"
        >Please note this catalogue will only show available merchants given
        your payout amount. For example, if your payout amount is £10, it will
        not show merchants that have a minimum payout amount higher than
        £10.</span
      >
    </div>
    <div class="sub-flex-header-browser align-center w-50 gap-20  mr-5">
      <div *ngIf="!isPayout" class="pl-3">
        <mat-form-field>
          <mat-select
            #status="matSelect"
            [(ngModel)]="category"
            (selectionChange)="fetchTremendousProducts()"
            placeholder="Filter By Category"
          >
            <mat-option value="All">Show All</mat-option>
            <mat-option value="Fashion, Jewellery and Other Retail"
              >Fashion, Jewellery and Other Retail</mat-option
            >
            <mat-option value="Travel">Travel</mat-option>
            <mat-option value="Groceries">Groceries</mat-option>
            <mat-option value="Restaurants">Restaurants</mat-option>
            <mat-option value="Department Stores">Department Stores</mat-option>
            <mat-option value="Technology">Technology</mat-option>
            <mat-option value="Football">Football</mat-option>
            <mat-option value="Homewear">Homewear</mat-option>
            <mat-option value="DIY">DIY</mat-option>
            <mat-option value="Food & Drink">Food & Drink</mat-option>
            <mat-option value="Experiences">Experiences</mat-option>
            <mat-option value="Spa & Wellness">Spa & Wellness</mat-option>
            <mat-option value="Babies & Children">Babies & Children</mat-option>
            <mat-option value="Entertainment">Entertainment</mat-option>
            <mat-option value="Gift Cards">Flex Voucher</mat-option>
          </mat-select>
          <mat-icon matSuffix>keyboard_arrow_down</mat-icon>
        </mat-form-field>
      </div>
        <input
        *ngIf="!isPayout"
        class="form-control"
        type="search"
        [(ngModel)]="searchVal"
        (input)="onSearchInput()"
        />
        <!-- <span class="text-red font-12">Search for vouchers with the specific name.</span> -->
    </div>
    <button class="flex-button-close" mat-icon-button (click)="dialogRef.close()">
      <mat-icon>clear</mat-icon>
    </button>
  </header>

  <div class="content">
    <div class="row w-100">
      <!-- [class.d-none]="checkIsInMaxMin(item)" -->
      <div class="col-md-3" *ngFor="let item of fullTremendousList">
        <mat-card
          class="mb-3"
          (click)="catalogueCard(item, data?.viewOnly)"
          [class.selected]="item.product_id === selectedProduct?.product_id"
        >
          <img mat-card-image [src]="item?.image_url" />
          <mat-card-content>
            <p class="text-center h5">
              {{ item?.name }}
            </p>
          </mat-card-content>
        </mat-card>
      </div>
      <div class="align-center justify-center w-100">
        <!-- Print Card -->
        <mat-spinner
          class="d-inline-block ml-2"
          *ngIf="tremendousProducts?.isLoading"
          mode="indeterminate"
          [strokeWidth]="2"
          [diameter]="22"
        ></mat-spinner>
        <mat-spinner
          class="d-inline-block ml-2"
          *ngIf="isFullProductsListLoading"
          mode="indeterminate"
          [strokeWidth]="2"
          [diameter]="22"
        ></mat-spinner>
      </div>
    </div>

    <div
      class="w-100 col-12 text-center"
      *ngIf="
        !tremendousProducts?.list?.length && !tremendousProducts?.isLoading
      "
    >
      No data is available
    </div>
    <div
      class="w-100 col-12 text-center"
      *ngIf="
        !fullTremendousList?.length
      "
    >
      No data is available
    </div>
  </div>

  <footer class="text-right border-top p-3" *ngIf="!data.viewOnly">
    <button
      *ngIf="!data?.multiple"
      matRipple
      size="lg"
      color="c1"
      [loading]="isLoading || tremendousProducts?.isLoading"
      [disabled]="!selectedProduct?.id || isLoading"
      (click)="done()"
      ButtonUi
    >
      Confirm Selection
    </button>
    <!-- <button
      *ngIf="data?.multiple"
      size="lg"
      color="c1"
      (click)="back()"
      ButtonUi
    >
      Select More
    </button> -->
  </footer>
</div>
