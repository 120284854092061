import { Component, OnInit, Input, OnChanges, ViewChild } from '@angular/core';
import { Campaign } from 'src/app/models/campaign.model';
import { CampaignService } from 'src/app/services/campaign.service';
import { AppService } from 'src/app/services/app.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MargeImagesService } from 'src/app/services/margeImages.service';
import { environment } from 'src/environments/environment';
import { CardFirebaseService } from 'src/app/services/card-firebase.service';
import { Contribution, FireContribution } from 'src/app/models/contribution';
import { CWishesComponent } from 'src/app/modules/detail/c-wishes/c-wishes.component';
import { ViewGiftPollComponent } from 'src/app/modules/detail/view-gift-poll/view-gift-poll.component';
import { ViewGiftSuggestionComponent } from 'src/app/modules/detail/view-gift-suggestion/view-gift-suggestion.component';
import { Router } from '@angular/router';

@Component({
  selector: 'camaign-card',
  templateUrl: './single-camaign.component.html',
  styleUrls: ['./single-camaign.component.scss'],
})
export class SingleCamaignComponent implements OnInit, OnChanges {
  @Input() campaign: Campaign = new Campaign();
  @Input() actionOptions: boolean = false;
  @Input() showStatus: boolean = false;
  @Input() contributeBtn: boolean = false;
  @ViewChild('suggestionFromContributors', { static: true })
  suggestionFromContributors;
  dialogRef: MatDialogRef<any>;

  constructor(
    private _campaignService: CampaignService,
    public _appService: AppService,
    private _margeImagesService: MargeImagesService,
    private _cardFirebase: CardFirebaseService,
    private dialog: MatDialog,
    private router:Router
  ) {}

  ngOnInit() {
    if (this.campaign) {
      if (this.campaign.contributorGiftIdeas) {
        this.campaign.contributorGiftIdeas.sort((a, b) => a.userId - b.userId);
      }
      if (!this.campaign.collectedAmount) this.campaign.collectedAmount = 0;
    }
  }

  openWishes(contributions: Contribution[]) {
    let type = this.campaign?.templateType=='freeCard'?'downloadWishes': 'wishes'
    this.dialog.open(CWishesComponent, {
      data: { contributions: contributions, type:type  },
      width: '600px',
    });
  }

  openEncodes(contributions: Contribution[]) {
    this.dialog.open(CWishesComponent, {
      data: { contributions: contributions, type: 'encode' },
      width: '600px',
    });
  }

  openPoll(item: Campaign) {
    this.dialog.open(ViewGiftPollComponent, {
      data: item,
      width: '400px',
    });
  }

  openSuggestion(item: Campaign) {
    this.dialog.open(ViewGiftSuggestionComponent, {
      data: item,
      width: '400px',
    });
  }

  isPrinting = false;
  async printCard() {
    this.isPrinting = true;
    try {
      const [selectedTemplateUrl, finalTemplate] = await Promise.all([
        this._margeImagesService.getImage(
          `${environment.apiUrls.api}/api/campaigns/change/base?imgUrl=${this.campaign.selectedTemplateUrl}`
        ),
        this._margeImagesService.getImage(
          `${environment.apiUrls.api}/api/campaigns/change/base?imgUrl=${this.campaign.finalTemplate}`
        ),
      ]);
      if (!selectedTemplateUrl || !finalTemplate) {
        throw new Error('Server error');
      }
      const w = window.open('', '_blank', 'width=800,height=600');
      w.document.write(`
      <!DOCTYPE html>
      <html lang="en">
        <head>
          <title>Download Card</title>
          <style>
            * {
              page-break-after: always !important;
            }
            @page {
              margin: 0px;
              padding: 0px;
            }
          </style>
        </head>
        <body>
          <img
            id="coverImg"
            src="${selectedTemplateUrl}"
            width="100%"
            alt=""
          />
          <div class="coverBack">
            <img
              style="visibility: hidden;"
              src="${selectedTemplateUrl}"
              width="100%"
            />
          </div>
          <img id="wishCard" src="${finalTemplate}" width="100%" alt="" />
          <div class="coverBack">
            <img
            style="visibility: hidden;"
              src="${finalTemplate}"
              width="100%"
              alt=""
            />
          </div>
        </body>
      </html>
      `);
      setTimeout(() => {
        w.print();
        w.close();
      }, 1000);
    } catch (error) {
    } finally {
      this.isPrinting = false;
    }
  }

  ngOnChanges() {}

  fetch() {
    this._campaignService.campaigns
      .get(this.campaign.id)
      .then((d) => {
        this.campaign = d;
        if (this.campaign.contributorGiftIdeas) {
          this.campaign.contributorGiftIdeas.sort(
            (a, b) => a.userId - b.userId
          );
        }
        if (!this.campaign.collectedAmount) this.campaign.collectedAmount = 0;
      })
      .catch((err) => {
        this._appService.snackBarOpen(err);
      });
  }

  onCardClick() {
    // if (this._appService.guestEmail) {
    //   localStorage.setItem('campaign', JSON.stringify(this.campaign));
    // }
  }

  get campaignId() {
    const arry = this.campaign.inviteLink.split('/');
    return arry[arry.length - 1];
  }

  copyCardLink() {
    this._appService.copyLink(
      `${location.host}/digital-e-card/${this.campaignId}`
    );
  }

  get isExpried(): boolean {
    let expireOn: Date = new Date(this.campaign.collectionDeadline);
    if ((expireOn.getTime() - new Date().getTime()) / (1000 * 3600 * 24) < 0) {
      return true;
    } else {
      return false;
    }
  }

  updateStatus() {
    const c = this.campaign;
    this._campaignService.campaigns
      .update(c.id, { status: c.status })
      .then((d) => {
        var status;
        switch (c.status) {
          case 'active':
            status = 'Open - accepting contributions';
            break;
          case 'closed':
            status = 'Paid Out';
            break;
          case 'dateAchieved':
            status = 'Closed - Pending Payout';
            break;
        }
        this._appService.snackBarOpen(`Status changed to ${status}`);
      })
      .catch((err) => {
        this.fetch();
        this._appService.snackBarOpen(err);
      });
  }

  showPollResult() {
    this.dialogRef = this._appService.matDialog.open(
      this.suggestionFromContributors,
      {
        maxHeight: '90%',
        maxWidth: '90%',
        panelClass: 'suggestionFromContributors',
        width: '700px',
        height: '400px',
      }
    );
  }

  navigateToContribuiton(id:string){
    this.router.navigate(['pages/contribution/' + id])
  }

}
