import { Component, OnInit } from "@angular/core";
import { UserService } from "src/app/services/user.service";
import { AppService } from "src/app/services/app.service";
import { User } from "src/app/models/user.model";
import { MatDialog } from "@angular/material/dialog";
import { ImageCropperComponent } from "../../shareModule/components/image-cropper/image-cropper.component";
import { FileUploaderComponent } from "../../shareModule/components/file-uploader/file-uploader.component";
import { ChangePasswordDialogComponent } from "../../shareModule/dialogs/change-password-dialog/change-password-dialog.component";
import { AddKycProofsComponent } from "./add-kyc-proofs/add-kyc-proofs.component";
import { ActivatedRoute } from "@angular/router";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ValidationService } from "../../shareModule/controls-msg/validation.service";

@Component({
  selector: "app-personal-information",
  templateUrl: "./personal-information.component.html",
  styleUrls: ["./personal-information.component.scss"],
})
export class PersonalInformationComponent implements OnInit {
  edit: boolean = false;
  isLoading: boolean = false;
  isDeleting: boolean = false;
  kyc = {
    isLoading: false,
    items: [],
  };

  editProfileForm: FormGroup = new FormGroup({
    firstName: new FormControl(this._appService.user.firstName, [
      Validators.required,
      ValidationService.spaceValidator,
    ]),
    lastName: new FormControl(this._appService.user.lastName, [
      Validators.required,
      ValidationService.spaceValidator,
    ]),
  });

  constructor(
    private _userService: UserService,
    private _appService: AppService,
    private _dialog: MatDialog,
    private _activatedRoute: ActivatedRoute
  ) {
    this.fetch();
    // this.addProofs()
  }

  ngOnInit() {}

  async deactivateAccount() {
    if (this.isDeleting) return;
    if (
      !confirm(
        `If you deactivate your account you won't be able to activate it agian. Are you sure you want to deactivate your account?`
      )
    ) {
      return;
    }
    const deactivateAccount = await this._userService.deactivateAccount();
    if (!deactivateAccount) {
      this.isDeleting = false;
    }
  }

  fetch() {
    this._userService.users
      .get(this.user.id)
      .then((d) => {
        localStorage.setItem("user", JSON.stringify({ ...this.user, ...d }));
        this.resetForm();
        // let qp = this._activatedRoute.snapshot.queryParams;

        // if (qp['kyc'] && qp['kyc'] === 'add' && !d.address) {
        //   this.addProofs();
        // }
      })
      .catch((err) => this._appService.snackBarOpen(err));
  }

  fetchKyc() {
    this.kyc.isLoading = true;
    this._userService
      .getKycProofs()
      .then((d: any) => {
        this.kyc.isLoading = false;
        this.kyc.items = d.items;
      })
      .catch((err) => {
        this.kyc.isLoading = false;
        this._appService.snackBarOpen(err);
      });
  }

  get user(): User {
    return this._appService.user;
  }

  changeImage(event) {
    let dialog = this._dialog.open(ImageCropperComponent, {
      data: { event },
      disableClose: true,
      minWidth: "500px",
      maxWidth: "100%",
      maxHeight: "100%",
    });

    dialog.afterClosed().subscribe((file: File) => {
      if (file) {
        this.uploadFile(file);
      }
    });
  }

  uploadFile(file: File) {
    let dialog = this._dialog.open(FileUploaderComponent, {
      data: [file],
      disableClose: true,
      minWidth: "400px",
      maxWidth: "100%",
      maxHeight: "100%",
    });

    dialog.afterClosed().subscribe((urls: string[]) => {
      if (urls.length && urls[0].length) {
        this.update({ imgUrl: urls[0] });
      }
    });
  }

  editProfile(temp) {
    let dialog = this._dialog.open(temp, {
      disableClose: true,
      panelClass: "edit-profile-dialog",
      maxHeight: "100%",
      maxWidth: "100%",
    });

    dialog.afterClosed().subscribe((d) => {
      if (d) {
        this.fetch();
      }
    });
  }

  addProofs() {
    this._dialog
      .open(AddKycProofsComponent, {
        disableClose: true,
        maxHeight: "100%",
        maxWidth: "100%",
        width: "70vw",
      })
      .afterClosed()
      .subscribe((d) => {
        if (d) {
          this.fetch();
          this.fetchKyc();
        }
      });
  }

  changePassword() {
    let dialog = this._dialog.open(ChangePasswordDialogComponent, {
      disableClose: true,
      maxHeight: "100%",
      maxWidth: "100%",
      width: "400px",
    });
  }

  updateEditProfile() {
    this.update(this.editProfileForm.value);
  }

  update(u: User) {
    this._appService.loadingShow();
    this._userService.users
      .update(this.user.id, u)
      .then((d) => {
        this.edit = false;
        this._appService.loadingHide();
        localStorage.setItem("user", JSON.stringify({ ...this.user, ...d }));
        this.resetForm();
      })
      .catch((err) => {
        this._appService.loadingHide();
        this._appService.snackBarOpen(err);
        this.fetch();
      });
  }

  resetForm() {
    this.editProfileForm.get("firstName").setValue(this.user.firstName);
    this.editProfileForm.get("lastName").setValue(this.user.lastName);
  }
}
