import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Category } from 'src/app/models/category.model';
import { CategoriesService } from 'src/app/services/categories.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { UserService } from 'src/app/services/user.service';
import { AppService } from 'src/app/services/app.service';
import { User } from 'src/app/models/user.model';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { StartWithContributeComponent } from '../../shareModule/dialogs/start-with-contribute/start-with-contribute.component';
import { CampaignService } from 'src/app/services/campaign.service';
import { ViewportScroller } from '@angular/common';
import { CharitiesService } from 'src/app/services/charities.service';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { ValidationService } from '../../shareModule/controls-msg/validation.service';
import { BrowseAvailableGiftProvidersComponent } from './browse-available-gift-providers/browse-available-gift-providers.component';
import { GuestUserDetailComponent } from '../../contribute-to-a-collection/components/contribute/guest-user-detail/guest-user-detail.component';
import { TremendousProduct } from 'src/app/models/tremendous.product.model';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit, AfterViewInit {
  messages: string[] = [];
  index = 0;
  currentMessage: string = '';
  categories: Category[] = [];
  tremendousProducts: { list: TremendousProduct[]; isLoading: boolean } = {
    list: [],
    isLoading: false,
  };
  stripe: any;
  scope: any;
  charityId: any;
  authorizationCode: any;
  stripeVerify = { charityId: '', authorizationCode: '' };
  id: any;
  guestUser: User;
  contributionId: any;

  contactUsForm: FormGroup = new FormGroup({
    name: new FormControl('', [Validators.required]),
    email: new FormControl('', [
      Validators.required,
      ValidationService.emailValidator,
    ]),
    message: new FormControl('', [Validators.required]),
    userId: new FormControl(''),
    contributorEmail: new FormControl('', [
      Validators.required,
      ValidationService.emailValidator,
    ]),
    contributorName: new FormControl(''),
  });

  public get homeContent() {
    return this._configurationService.homeContent;
  }

  public get homeHeaderImage() {
    return this._configurationService.homeHeaderImage;
  }

  constructor(
    private _categoriesService: CategoriesService,
    private _userService: UserService,
    public _appService: AppService,
    private _router: Router,
    private _dialog: MatDialog,
    private _activatedRoute: ActivatedRoute,
    private _campaignService: CampaignService,
    private _viewportScroller: ViewportScroller,
    private charitiesService: CharitiesService,
    private _configurationService: ConfigurationService
  ) {
    this.fetchTremendousProducts();
    _activatedRoute.queryParams.subscribe((d) => {
      // console.log(d.redirectTo);
      const contribution = d.redirectTo;
      this.contributionId = contribution?.split('/')[3];
      // console.log(this.contributionId);
      if (contribution?.includes('contribution')) {
        // this.fillGuestUserDetails();
        this._router.navigate([`/contribution/${this.contributionId}`]);
      }
    });

    _configurationService.fetch();

    this.stripeVerify.authorizationCode =
      this._activatedRoute.snapshot.queryParams['code'];

    this.stripeVerify.charityId = localStorage.getItem('charityId');
    if (this.stripeVerify.charityId && this.stripeVerify.authorizationCode) {
      this.charitiesService.charities
        .create(this.stripeVerify, 'verify')
        .then((d) => {
          this._appService.snackBarOpen('Account successfully linked');
        })
        .catch((err) => {
          this._appService.snackBarOpen(err);
        });
    }

    this._categoriesService.categories
      .search({ serverPaging: false } as any)
      .then((d) => {
        let colorCount = 0;
        d.items.forEach((c) => {
          if (colorCount > 5) {
            colorCount = 0;
          }
          colorCount++;
          c.color = colorCount;
        });
        this.categories = [...d.items];
      });
  }

  fillGuestUserDetails() {
    console.log('qwerty');

    this._dialog
      .open(GuestUserDetailComponent, {
        disableClose: true,
        minHeight: '200px',
        maxHeight: '100%',
        minWidth: '600px',
        maxWidth: '100%',
        panelClass: 'guestUserModal',
        data: { id: this.contributionId },
      })
      .afterClosed()
      .subscribe((d) => {
        console.log(d);
        if (d) {
          this._router.navigate([`/pages/contribution/${this.contributionId}`]);
          this.guestUser = d;
          this.contactUsForm.get('userId').setValue(d.id);
          this.contactUsForm.get('contributorEmail').setValue(d.email);
          this.contactUsForm
            .get('contributorName')
            .setValue(d.name || 'Guest User');
        }
      });
  }

  isOpened: boolean;
  ngOnInit() {
    this.messages = [
      'Group Gift Collections and Group Cards made easy!',
      'Birthday - New Baby - New Home - Wedding - Thank You - Get Well Soon - Retirement (and many more..)',
      'Choose from over a hundred gift providers in our online gift catalogue',
      'Design your own card or choose from our Online Card Shop',
      'Option to donate any Spare Change to Smile Train, our partner charity',
      'Free online group gift collections',
    ];

    if (this.messages.length > 0) {
      this.currentMessage = this.messages[this.index];
    }

    setInterval(() => {
      if (this.messages.length > 0) {
        this.index = (this.index + 1) % this.messages.length;
        this.currentMessage = this.messages[this.index];
      }
    }, 5000);

    // $('#toggle').click(function () {
    //   $(this).toggleClass('active');
    //   $('#overlay').toggleClass('open');
    // });
    // $('.overlay-menu a').click(function () {
    //   $('.button_container').toggleClass('active');
    //   $('#overlay').toggleClass('open');
    //   return false;
    // });

    let queryParams = this._activatedRoute.snapshot.queryParams;
    let link: string = queryParams['redirectTo'];
    if (link && link.match(/\/pages\/contribution\/[a-zA-Z0-9]+/g)) {
      let linkAry = link.split('/');
      let campaignId = linkAry[linkAry.length - 1];
      this._appService.loadingShow();
      this._campaignService
        .getByInviteLink(campaignId)
        .then((d) => {
          console.log(d);

          this._appService.loadingHide();
          let expireOn: Date = new Date(d.collectionDeadline);

          // (expireOn.getTime() - new Date().getTime()) / (1000 * 3600 * 24) < 0

          if (expireOn.getTime() - new Date().getTime() >= 0) {
            this._appService.snackBarOpen('Link is expired');
          } else {
            // this._appService.snackBarOpen(
            //   'To contribute, please login or register as a new user.'
            // );
            // this._router.navigate(['auth/sign-up'], {
            //   queryParamsHandling: 'preserve',
            // });
            // this.startWithContribute();
            this._router.navigate(['contribution', campaignId]);
          }
        })
        .catch((d) => {
          this._appService.loadingHide();
          this._appService.snackBarOpen('Invalid link');
        });
    }
  }

  ngAfterViewInit() {
    this._activatedRoute.fragment.subscribe((d) => {
      this._viewportScroller.scrollToAnchor(d);
    });
  }

  get user(): User {
    return JSON.parse(localStorage.getItem('user'));
  }

  contactUs() {
    this._userService.users
      .create({ ...this.contactUsForm.value }, 'contact/us')
      .then((d) => {
        // this._appService.snackBarOpen('err');
        this._appService.snackBarOpen(
          'Thank you for contacting CollectaGift. We will aim to come back to you within 24 hours.'
        );
        this.contactUsForm.reset();
      })
      .catch((err) => {
        this._appService.snackBarOpen(err);
      });
  }

  navigate(link) {
    this._router.navigate([link]);
  }

  startWithContribute(link?: string) {
    this._dialog.open(StartWithContributeComponent, {
      disableClose: true,
      minHeight: '200px',
      maxHeight: '100%',
      minWidth: '600px',
      maxWidth: '100%',
    });
  }

  slides = [
    { img: 'https://dummyimage.com/600x400/000/fff' },
    { img: 'https://dummyimage.com/600x400/000/fff' },
    { img: 'https://dummyimage.com/600x400/000/fff' },
    { img: 'https://dummyimage.com/600x400/000/fff' },
    { img: 'https://dummyimage.com/600x400/000/fff' },
    { img: 'https://dummyimage.com/600x400/000/fff' },
  ];

  slideConfig = {
    slidesToShow: 5,
    slidesToScroll: 1,
    nextArrow:
      "<div class='next-slide'><img src='assets/images/arrow-right.png'></div>",
    prevArrow:
      "<div class='prev-slide'><img src='assets/images/arrow-left.png'></div>",
    dots: false,
    infinite: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 590,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },

      // You can unslick at a given breakpoint now by adding:
      // settings: "unslick"
      // instead of a settings object
    ],
  };

  browseAvailableGift() {
    this._appService.matDialog.open(BrowseAvailableGiftProvidersComponent, {
      maxHeight: '100vh',
      maxWidth: '100vw',
      width: '90vw',
      height: '90vh',
    });
  }

  addSlide() {
    this.slides.push({ img: 'http://placehold.it/350x150/777777' });
  }

  removeSlide() {
    this.slides.length = this.slides.length - 1;
  }

  slickInit(e) {
    console.log('slick initialized');
  }

  breakpoint(e) {
    console.log('breakpoint');
  }

  afterChange(e) {
    console.log('afterChange');
  }

  beforeChange(e) {
    console.log('beforeChange');
  }

  startIndex = 0;
  lastIndex = 2;
  mediator = [];

  leftClick() {
    if (this.startIndex === 0) {
      this.startIndex = this.tremendousProducts.list.length - 1;
      this.lastIndex--;
      this.mediator.unshift(
        this.tremendousProducts.list[this.tremendousProducts.list.length - 1]
      );
      this.mediator.pop();
    } else if (this.lastIndex === 0) {
      this.lastIndex = this.tremendousProducts.list.length - 1;
      this.startIndex--;
      this.mediator.unshift(this.tremendousProducts.list[this.startIndex]);
      this.mediator.pop();
    } else {
      this.startIndex--;
      this.lastIndex--;
      this.mediator.unshift(this.tremendousProducts.list[this.startIndex]);
      this.mediator.pop();
    }
    console.log('start ', this.startIndex, 'last ', this.lastIndex);
    return;
  }

  rightClick() {
    if (this.lastIndex === this.tremendousProducts.list.length - 1) {
      this.lastIndex = 0;
      this.startIndex++;
      this.mediator.shift();
      this.mediator.push(this.tremendousProducts.list[0]);
    } else if (this.startIndex === this.tremendousProducts.list.length - 1) {
      this.startIndex = 0;
      this.lastIndex++;
      this.mediator.shift();
      this.mediator.push(this.tremendousProducts.list[this.lastIndex]);
    } else {
      this.startIndex++;
      this.lastIndex++;
      this.mediator.shift();
      this.mediator.push(this.tremendousProducts.list[this.lastIndex]);
    }
    console.log('start ', this.startIndex, 'last ', this.lastIndex);
    return;
  }

  async fetchTremendousProducts() {
    this.tremendousProducts.isLoading = true;
    this.tremendousProducts.list =
      await this._campaignService.getTremendousProduct();
    let array = [
      this.tremendousProducts.list[0],
      this.tremendousProducts.list[1],
      this.tremendousProducts.list[2],
      this.tremendousProducts.list[3],
      this.tremendousProducts.list[4],
    ];
    this.mediator = [...array];
    this.tremendousProducts.isLoading = false;
  }

  navigateToSignup() {
    this._router.navigate(['auth/sign-up']);
  }
}
