<div class="continer-fluid p-0">
  <mat-horizontal-stepper #stepper>
    <mat-step [stepControl]="formGroup" [label]="'email'">
      <div class="heading-themes">
        <h3>
          {{ "Contribute as a guest user" | uppercase }}
          <!-- <button class="float-right" mat-icon-button (click)="closeDialog()">
            <mat-icon>clear</mat-icon>
          </button> -->
        </h3>
      </div>
      <form [formGroup]="formGroup">
        <div class="form-group">
          <label for="name">Name</label>
          <input
            id="name"
            type="text"
            placeholder="Name"
            class="form-control"
            formControlName="name"
          />
        </div>

        <!-- <div class="form-group">
          <label for="email">Email</label>
          <input
            required
            id="email"
            type="email"
            placeholder="Email"
            class="form-control"
            formControlName="email"
          />
        </div> -->

        <div class="text-right">
          <button
            ButtonUi
            type="submit"
            size="lg"
            color="c1"
            matStepperNext
            [loading]="isLoading"
            (click)="submit()"
          >
            Done
          </button>
        </div>
      </form>

      <!-- <div class="text-center">
        <a
          routerLink="auth/sign-in"
          [queryParamsHandling]="'preserve'"
          [queryParams]="{ redirectTo: 'pages/contribution/' + this.data.id }"
          (click)="closeDialog()"
          >Sign In</a
        >
        &nbsp;&nbsp;
        <a
          routerLink="auth/sign-up"
          [queryParamsHandling]="'preserve'"
          [queryParams]="{ redirectTo: 'pages/contribution/' + this.data.id }"
          (click)="closeDialog()"
          >Sign Up</a
        >
      </div> -->
    </mat-step>
  </mat-horizontal-stepper>
</div>
