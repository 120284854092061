<div class="mdl py-4">
  <div class="border-bottom">
    <div class="d-flex justify-content-between pb-2 px-4">
      <div class="font-24">Send an Invite to Charity</div>
      <div>
        <mat-icon aria-hidden="false" aria-label="clear icon" mat-dialog-close>clear</mat-icon>
      </div>
    </div>
  </div>
  <div class="mt-3 px-4">
    <div class="font-14 mb-2">For sending an Invitation for charity. Please enter the required details below.</div>
    <div class="chariyt-form">
      <form [formGroup]="form">
        <div class="form-group">
          <label>Name</label>
          <input type="text" class="form-control" formControlName="name"/>
        </div>
        <div class="form-group">
          <label>Email</label>
          <input type="text" class="form-control" formControlName="email"/>
        </div>
        <div class="text-right">
          <button class="orange-btn send-btn" [disabled]="form.invalid || btnDisable" (click)="onSubmit()">Send Invite</button>
        </div>
      </form>
    </div>
  </div>
</div>
