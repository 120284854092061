<div
  #card
  class="item-card"
  style="background-color: white; height: 100%; width: 100%"
>
  <div class="placeholder" (click)="fileInput.click()" *ngIf="!base64">
    <img
      [src]="'assets/images/upload-placeholder.png'"
      style="max-width: '100%'; max-height: '100%'; object-fit: 'contain'"
      alt=""
    />
  </div>

  <div
    #itemContainer
    class="item-container"
    style="
      background-repeat: no-repeat;
      background-position: 0px 50%;
      background-size: 100%;
      height: 100%;
      width: 100%;
    "
    [style.backgroundImage]="'url(' + base64 + ')'"
    (mousedown)="onMouseDown($event)"
    (mousemove)="onMouseMove($event)"
    (mouseup)="onMouseUpOrLeave()"
    (mouseleave)="onMouseUpOrLeave()"
    (wheel)="onMouseWheel($event)"
    (contextmenu)="onContextMenu($event)"
    [style.display]="!base64 ? 'none' : 'block'"
  ></div>
</div>

<div class="actions" style="display: none" *ngIf="base64">
  <button mat-icon-button class="mr-3" (click)="fileInput.click()">
    <mat-icon>edit</mat-icon>
  </button>
  <button mat-icon-button (click)="onDelete()">
    <mat-icon>delete</mat-icon>
  </button>
</div>

<input
  type="file"
  hidden
  #fileInput
  (click)="fileInput.value = null"
  (change)="onFileInputChange($event)"
/>
