<div class="info-text">
  click on card to open and click on each page to turn page
</div>
<div class="book-box">
  <div
    class="flip-book"
    [style.width]="cardDimensions.width"
    [style.height]="cardDimensions.height"
    [class.opened]="isAnyPageFlipped()"
  >
    <div
      class="page height-392"
      *ngFor="let page of pages; let i = index"
      [class.flipped]="page.flipped"
      [style.zIndex]="page.flipped ? i + 1 : pages.length - i"
      (click)="flipPage(i)"
    >
      <div class="front height-392">
        <img
          *ngIf="page?.frontPage?.image"
          appImage
          [src]="page?.frontPage?.image"
        />
        <div class="content height-392" [class.show]="page?.frontPage?.opened">
          <p
            class="gift-card-message p-text"
            *ngFor="let item of page?.frontPage?.wishes"
            [ngStyle]="item"
          >
            <span
              class="gift-card-message"
              style="background-color: transparent"
              >{{ item.message }}</span
            >
          </p>
        </div>
      </div>
      <!-- <div class="back">
        <img
          *ngIf="page?.backPage?.image"
          appImage
          #image="appImage"
          [src]="page?.front?.image"
        />
      </div> -->
      <div class="content height-392" [class.show]="page?.backPage?.opened">
        <p
          class="gift-card-message p-text"
          style="transform: rotateY(180deg)"
          *ngFor="let item of page?.backPage?.wishes"
          [ngStyle]="item"
        >
          <span
            class="gift-card-message"
            style="background-color: transparent"
            >{{ item.message }}</span
          >
        </p>
      </div>
      <div class="last-page" *ngIf="i === pages.length - 1">
        <div><img class="last-logo" width="40" height="40" src="assets/logos/CollectaGift-Avatar.png" alt=""></div>
        <div class="text-align-end d-flex justify-content-end flex-column back-cont">
          <img
          class="img-height"
          style="transform: rotateY(180deg)"
          src="assets/logos/CollectaGift-Final.png"
          alt=""
          />
          <p class="font-12 m-0 lh-14 sorted-text" style="transform: rotateY(180deg)">Group Gifting, Sorted</p>
          <p class="m-0 collect-g-text" style="transform: rotateY(180deg)">www.collectagift.com</p>
        </div>
      </div>
    </div>
  </div>
</div>
