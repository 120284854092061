import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { AppComponent } from "./app.component";
import { AngularFireModule } from "@angular/fire";
import { AngularFireAuthModule } from "@angular/fire/auth";
import { environment } from "src/environments/environment.prod";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { HomeModule } from "./modules/home/home.module";
import { CreateGiftModule } from "./modules/create-gift/create-gift.module";
import { DetailModule } from "./modules/detail/detail.module";
import { AppRoutingModule } from "./app-routing.module";
import { ShareModule } from "./modules/shareModule/share.module";
import { PagesComponent } from "./pages/pages.component";
import { CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { NgxSpinnerModule } from "ngx-spinner";
import { AngularFireDatabaseModule } from "@angular/fire/database";
import { AngularFirestoreModule } from "@angular/fire/firestore";

@NgModule({
  declarations: [AppComponent, PagesComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireAuthModule,
    AngularFireDatabaseModule,
    AngularFirestoreModule,
    BrowserAnimationsModule,
    CreateGiftModule,
    HomeModule,
    DetailModule,
    ShareModule,
    NgxSpinnerModule,
  ],
  providers: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent],
})
export class AppModule {}
