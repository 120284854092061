<nav class="navbar">
  <ul class="nav">
    <div class="btn-row" *ngIf="_router.url.includes('pages/detail/campaigns')">
      <button class="orange-btn theme-btn" (click)="navigate('/pages/create-gift', '/auth/sign-up')">
        START AN ONLINE COLLECTION
      </button>
      <button class="orange-btn-reverse theme-btn ml-3" (click)="startWithContribute('/pages/contribution')">
        CONTRIBUTE TO A COLLECTION
      </button>
    </div>
    <li class="nav-item">
      <button class="mr-1" mat-icon-button routerLink="/pages/detail">
        <mat-icon appearance="outline">dashboard_outline</mat-icon>
      </button>
    </li>
    <li class="nav-item">
      <button class="mr-1" mat-icon-button routerLink="/home">
        <mat-icon>home_outline</mat-icon>
      </button>
    </li>
    <li class="nav-item">
      <div class="btn-group">
        <button mat-icon-button data-toggle="dropdown" class="profile">
          <img [src]="appService?.user?.imgUrl" height="100%" width="100%" class="rounded-circle img-fluid user-img"
            onerror="this.src = 'assets/images/team-man-placeholder.jpg'" alt="">
        </button>
        <button mat-icon-button class="profile-down" data-toggle="dropdown">
          <img src="assets/icons/Profile Down.png" alt="">
        </button>

        <div class="dropdown-menu fade-down">
          <header *ngIf="user?.id" class="p-3 d-flex align-items-center border-bottom">
            <div class="profile mr-3">
              <img [src]="appService?.user?.imgUrl" style="height: 60px !important;width: 60px !important;"
                class="rounded-circle user-img " onerror="this.src = 'assets/images/team-man-placeholder.jpg'" alt="">
              <img matRipple routerLink="/pages/detail/personal-information" class="edit"
                src="assets/icons/Green Edit.png" alt="">
            </div>
            <div class="data-content">
              <div class="title text-blue font-weight-bold">
                {{(user?.firstName | titlecase) || 'N/a'}} {{user?.lastName}}
              </div>
              <div class="title">
                Joined on {{user?.createdAt | date:'dd MMM yyyy'}}
              </div>
            </div>
          </header>
          <mat-list class="p-0">
            <!-- <mat-list-item *ngIf="user?.id" mat-ripple>Report a Issue</mat-list-item>
            <mat-list-item *ngIf="user?.id" mat-ripple>Our Blogs</mat-list-item> -->
            <mat-list-item *ngIf="user?.role === 'admin'" (click)="inviteCharity()" mat-ripple>Send an Invite to the
              Charity</mat-list-item>
            <mat-list-item mat-ripple *ngIf="user?.id" (click)="userService.logout()">Log Out</mat-list-item>
            <mat-list-item mat-ripple *ngIf="!user?.id" (click)="logIn()">Log In</mat-list-item>
          </mat-list>
        </div>

      </div>


    </li>
  </ul>
</nav>