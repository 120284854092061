<!-- navbar -->
<div class="container-fluid pl-0 nav-section">
  <nav class="navbar navbar-expand-lg ml-auto">
    <div class="nav-container">
      <div
        class="button_container  {{ isOpened ? 'active' : '' }}"
        id="toggle"
        (click)="isOpened = !isOpened"
      >
        <span class="top"></span>
        <span class="middle"></span>
        <span class="bottom"></span>
      </div>
      <div class="overlay {{ isOpened ? 'open' : '' }}" id="overlay">
        <nav class="overlay-menu">
          <ul>
            <li class="nav-item active">
              <a
                class="nav-link"
                (click)="isOpened = !isOpened"
                routerLink="/home"
                >Home</a
              >
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                (click)="isOpened = !isOpened"
                routerLink="/about-us"
                >About us</a
              >
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                (click)="isOpened = !isOpened"
                routerLink="/about-us"
                [fragment]="'contact'"
                tabindex="-1"
                aria-disabled="true"
                >Contact us</a
              >
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                (click)="isOpened = !isOpened"
                routerLink="/fees"
                >FAQs</a
              >
            </li>
            <li class="nav-item" *ngIf="!user?.id">
              <button
                class="orange-btn outline light"
                (click)="navigate('/pages/detail'); isOpened = !isOpened"
              >
                Log In
              </button>
            </li>
            <li class="nav-item" *ngIf="user?.id">
              <button
                mat-icon-button
                class="bg-mute mx-3 profile"
                (click)="navigate('/pages/detail'); isOpened = !isOpened"
              >
                <img
                  class="rounded-circle img-fluid"
                  [src]="user?.imgUrl || 'assets/images/user icon.png'"
                  alt=""
                />
              </button>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </nav>
  <!-- <img src="assets/images/header-left-shape.png" alt="" class="img-fluid banner-shape-img">
  <img src="assets/images/aboutUs-bnr2.svg" alt="" class="img-fluid banner-gift-img"> -->

  <!-- <div class="header-heading ml-auto text-black">
    <img height="150px" src="assets/logos/CollectaGift-Final.png" alt="">
    <p>Know More About Us</p>
  </div> -->

  <div class="top-m">
    <div class="row pb-5">
      <div class="col-lg-6">
        <div class="header-heading ml-auto text-black text-center">
          <img
            height="150px"
            src="assets/logos/CollectaGift-Final.png"
            alt=""
          />
        </div>
      </div>
      <div class="col-lg-6">
        <div class="">
          <h4 class="mb-2">Welcome to CollectaGift</h4>
          <h5 class="mb-0">Group Gifting, Sorted!</h5>
          <!-- <div
            class="hd2"
            *ngFor="
              let item of aboutUsContent.welcomeToCollectAGift | slice: 0:1
            "
          >
            {{ item.description }}
          </div> -->
          <div class="mt-2 pb-4">
            <!-- <p
              *ngFor="
                let item of aboutUsContent.welcomeToCollectAGift | slice: 1:2
              "
            >
              {{ item.description }}
            </p> -->
            <p>
              At CollectaGift, we love clubbing together with friends, family
              and colleagues to buy a group gift for a special someone. Whether
              it’s a group <b> Birthday Gift </b> for a friend , a
              <b> Leaving Gift </b> for a colleague, a
              <b> Thank You Gift </b> for a teacher, or any group gift, we love
              coming together, celebrating and giving. What we aren’t great fans
              of, is the hassle that can come with organising a group gift, so
              at CollectaGift, we’re passionate about making this process
              easier. We’ve done this by creating an online platform that allows
              you to create and contribute to an online CollectaGift Pot in 3
              simple steps:
            </p>
            <p class="mb-1">
              <!-- 1. Create online CollectaGift Pot in just a few clicks -->
              1.
              {{
                aboutUsContent?.welcomeToCollectAGift[2]?.description || "--"
              }}
            </p>
            <p class="mb-1">
              <!-- 2. Share link and invite people to contribute -->
              2.
              {{
                aboutUsContent?.welcomeToCollectAGift[3]?.description || "--"
              }}
            </p>
            <p>
              <!-- 3. Purchase Digital Card (optional) and Redeem Funds to purchase
              gift. -->
              3.
              {{
                aboutUsContent?.welcomeToCollectAGift[4]?.description || "--"
              }}
            </p>
            <!-- <p>
              Whether you’re organising a group gift collection or contributing
              to one, we’ve got you covered! <br />
              <b
                >In fact, we think CollectaGift solves a whole host of little
                (or big..) group gifting niggles:</b
              >
            </p> -->
            <p>
              {{ aboutUsContent?.welcomeToCollectAGift[5]?.description || "" }}
              <br />
              {{ aboutUsContent?.welcomeToCollectAGift[6]?.description || "" }}
            </p>
            <p>
              <b> Don't Carry Cash Anymore? Sorted! </b> Secure online cashless
              payments powered by Stripe.
            </p>

            <p>
              <b> Tired of Chasing People for Money? Sorted!</b> No more IOUs!
            </p>

            <p>
              <b> Based in a Different Location or WFH? Sorted! </b> Contribute
              from anywhere and at any time.
            </p>

            <p>
              <b>
                Trying to Organise a Group Greeting Card remotely? Sorted!
              </b>
              Online digital group card
            </p>

            <p>
              <b> Stuck for gift ideas? Sorted! </b> Choose from our catalogue
              of 100+ brands. You can request/submit gift ideas and create polls
              too.
            </p>

            <p class="mb-3">
              <b>
                Not sure what to do with leftover cash in the collection pot?
                Sorted!
              </b>
              Optional charity donations.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Welcome Collect gift-->

<!-- <section class="p-relative welcm-sec mb-0">
  <img class="abt_grphcs1" src="assets/images/about_grphcs1.png" />
  <img class="abt_grphcs2" src="assets/images/about_grphcs1.png" />
  <img class="abt_grphcs3" src="assets/images/about_grphcs2.svg" />
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="pl-5">
          <h4>Welcome to CollectAGift</h4>
          <div class="hd2">where group gifting is reimagined.</div>
          <div class="mt-5">
            <p>
              At CollectaGift, we love clubbing together with friends, family and colleagues to buy a group gift for a
              special someone. Whether it’s a Leaving Gift for a colleague, a Thank You Gift for a teacher, or any group
              gift, we love coming together, celebrating and giving. </p>
            <p class="mb-1">What we aren’t great fans of, is the hassle that can
              come with organising a group gift, so at CollectaGift, we’re passionate about making this process easier.
              We’ve done this by creating an nline platform that allows you to create and contribute to an online
              CollectaGift Pot in 3 simple steps:</p>
            <p class="mb-1">1. Create online CollectaGift Pot in just a few clicks</p>
            <p class="mb-1">2. Share link and invite people to contribute</p>
            <p>3. Purchase Digital Card (optional) and Redeem Funds to purchase gift.</p>
            <p>Whether you’re organising a group gift collection or contributing to one, we’ve got you covered! <br>
              <b>In fact, we think CollectaGift solves a whole host of little (or big..) group gifting niggles:</b>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section> -->

<!-- why us -->
<!-- <section class="p-relative why-us mt-0">
  <img class="whyImgG" src="assets/images/whyGrph.png" />
  <div class="container">
    <div class="row">
      <div class="col-lg-6 col-sm-12 mx-auto heading-theme text-center text-white">
        <h3 class="m-0">Why CollectaGift?</h3>
      </div>
    </div>

    <div class="row box-row">

      <div class="col-lg-6 col-sm-6">
        <div class="box">
          <div class="left-img">
            <img src="assets/images/cash.png" alt="">
          </div>
          <div class="content">
            <h5 class="font-Caviar">Don’t carry cash anymore?</h5>
            <p>Cashless payments powered by Stripe, our secure online payment provider.</p>
          </div>
        </div>
      </div>

      <div class="col-lg-6 col-sm-6">
        <div class="box">
          <div class="left-img">
            <img src="../../../../assets/images/home/whyCollect/ic_chasing.png" alt="">
          </div>
          <div class="content">
            <h5 class="font-Caviar">Tired of chasing people for money?</h5>
            <p>No more IOUs. Automated Reminders.</p>
          </div>
        </div>
      </div>

      <div class="col-lg-6 col-sm-6">
        <div class="box">
          <div class="left-img">
            <img src="assets/images/home/whyCollect/ic_greeting_card.png" alt="">
          </div>
          <div class="content">
            <h5 class="font-Caviar">Trying to organise a group greeting card remotely or has the greeting card gone
              missing in the office?</h5>
            <p>Digital Group Greeting Card</p>
          </div>
        </div>
      </div>

      <div class="col-lg-6 col-sm-6">
        <div class="box">
          <div class="left-img">
            <img src="assets/images/home/whyCollect/ic_wfh.png" alt="">
          </div>
          <div class="content">
            <h5 class="font-Caviar">Working from home/Based in a different location? </h5>
            <p>Contribute from anywhere into an online CollectaGift Pot</p>
          </div>
        </div>
      </div>

      <div class="col-lg-6 col-sm-6">
        <div class="box">
          <div class="left-img">
            <img src="assets/images/home/whyCollect/ic_collection_pot.png" alt="">
          </div>
          <div class="content">
            <h5 class="font-Caviar">Leftover cash in the Collection Pot?</h5>
            <p>
              Optional Charity Donations
            </p>
          </div>
        </div>
      </div>

      <div class="col-lg-6 col-sm-6">
        <div class="box">
          <div class="left-img">
            <img width="50px" height="50px" src="assets/images/ic_stuck_gift@2x.png" alt="">
          </div>
          <div class="content">
            <h5 class="font-Caviar">Stuck for Gift ideas?</h5>
            <p>Request/Submit Gift ideas and create polls to votes on ideas</p>
          </div>
        </div>
      </div>

    </div>
  </div>
</section> -->

<!-- <section class="p-relative feesSec mt-0 pt-2">
  <div class="container">
    <div class="heading-theme text-center">
      <h3 class="black">Fees</h3>
      <p>
        At CollectaGift, we want as much as possible of the contributions to go towards the Gift, so we don’t charge any
        service fees for creating the CollectaGift Pot.
      </p>
      <p style="color: #717171;">
        <b> Although as with all online payments there are payment processing fees for<br> each transaction which we do
          need to cover:</b>
      </p>
      <div class="text-left mt-4">
        <h6 class="font-Caviar black mb-2">Cost to make a contribution to a CollectaGift Pot</h6>
        <p class="black mb-4">
          There are payment processing fees incurred on contributions into a CollectaGift Pot. These vary according to
          the currency of the contribution and will be clearly shown when you make your contribution. For GBP payments
          it is 20p + 1.8% of contribution amount. For example, if you contribute £5, you will incur an additional 29p
          in payment processing fees. These rates are determined by our payment provider and CollectaGift does not make
          a profit on this. There will be the option to add the payment processing fee onto your contribution so 100% of
          your contribution amount goes into the CollectaGift Pot. If you do not opt for this, the payment processing
          fees will be deducted from your individual contribution amount.

        </p>
        <h6 class="font-Caviar black mb-2">Cost to redeem the CollectaGift Pot amount</h6>
        <p class="black mb-4">
          At CollectaGift, we don’t charge to redeem the total proceeds as again, we want as much as possible to go to
          the deserving CollectaGift Pot Recipient. However, Stripe, our third-party payment provider does charge
          payment processing fees to pay-out the total CollectaGift Pot amount into the Organiser’s bank account to
          allow the Organiser to purchase a gift. Currently, for GBP denominated pots and GBP pay-outs, this is £2.10 +
          0.25% of total CollectaGift Pot value. For example, if the total CollectaGift pot value is £100, the
          transaction fees paid to Stripe to redeem this amount is £2.35 (£2.10 + 25p). This is deducted from the total
          CollectaGift Pot value. However, CollectaGift will cover this if a Group Greeting Card is purchased (see
          below) and the total CollectaGift Pot value is £100 or less.
        </p>
        <h6 class="font-Caviar black mb-2"> Cost to purchase a Group Greeting Card</h6>
        <p class="black">
          The cost to purchase a digital group card is £3 and if purchased, this cost is deducted from the total
          CollectaGift Pot value. This is the cost for a print-at-home digital card. However, as our main aim at
          CollectaGift is to simplify the group gift collection process rather than to make a profit, if the Group
          Greeting Card is purchased and the total CollectaGift Pot value is £100 or less, we’ll use the sale proceeds
          from the Group Greeting Card to cover the Stripe pay-out fees so as much as possible can go to the deserving
          gift recipient. Think of this as our gift to you.
        </p>
      </div>
    </div>
  </div>
</section> -->

<!-- contct -->
<section class="p-relative contact-section p-0 mb-0" id="contact">
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-8 col-md-8 col-sm-12 contact-blue text-white">
        <div class="heading-theme">
          <h3 class="m-0">Contact Us</h3>
          <p>
            Contact us using the form below or email:
            <a class="text-white" href="mailTo:hello@collectagift.com"
              >hello&#64;collectagift.com</a
            >
          </p>
          <img
            src="assets/images/round-object.png"
            class="img-fluid round"
            alt=""
          />
        </div>

        <form
          class="row pt-4 m-0"
          [formGroup]="contactUsForm"
          autocomplete="off"
        >
          <div class="col-lg-6 col-12 pl-0">
            <div class="form-group">
              <label for="name"></label>
              <input
                id="name"
                type="text"
                class="form-control"
                formControlName="name"
                placeholder="Name"
              />
            </div>
          </div>

          <div class="col-lg-6 col-12 pl-0">
            <div class="form-group">
              <label for="name"></label>
              <input
                id="name"
                type="email"
                class="form-control"
                formControlName="email"
                placeholder="Email"
              />
            </div>
          </div>

          <div class="col-lg-12 col-12 pt-4 pl-0">
            <div class="form-group">
              <label for="name"></label>
              <input
                id="name"
                type="text"
                class="form-control"
                formControlName="message"
                placeholder="Message"
              />
            </div>
          </div>

          <div class="col-12 button-row">
            <button
              class="theme-btn submit-btn"
              (click)="contactUs()"
              [disabled]="contactUsForm.invalid"
            >
              Submit
            </button>
            <h1 class="contact-stemp">Contact</h1>
          </div>
        </form>
      </div>

      <!-- right  -->
      <div class="col-lg-4 col-md-4 col-sm-12 footer-right">
        <div class="footer-link">
          <ul class="footer-nav">
            <a class="nav-link" routerLink="/">Home</a>
            <a class="nav-link" routerLink="/">Campaigns</a>
            <a class="nav-link" routerLink="/">Contribution</a>
            <a class="nav-link" routerLink="/">Contact us</a>
          </ul>

          <!-- address -->
          <div class="address-container">
            <div class="address">
              <!-- <h2 class="h2 m-0" >Contact Information</h2> -->
              <!-- <address> -->
              <!-- <p>#523 rosemary Street <br>
                  Strasbourg, 67000 USA</p>
                <hr> -->
              <!-- <p>Call us: +123 456 5463</p> -->
              <!-- <a href="mailto:shareagift@gmail.com">
                  <p>shareagift@gmail.com</p>
                </a> -->
              <!-- </address> -->

              <div class="social-icon">
                <h2 class="text-black">Follow us</h2>
                <a routerLink="/">
                  <img src="assets/images/facebook.png" alt="" />
                </a>
                <a routerLink="/">
                  <img src="assets/images/instagram.png" alt="" />
                </a>
                <a routerLink="/">
                  <img src="assets/images/twitter.png" alt="" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
