import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: 'img[appImage]',
  exportAs: 'appImage'
})
export class ImageDirective {

  isLoading: boolean = true;
  isError: boolean = false;
  height: number;
  width: number;

  constructor(
    private _el: ElementRef<HTMLImageElement>
  ) {
    this.height = _el.nativeElement.offsetHeight;
    this.width = _el.nativeElement.offsetWidth;

  }

  get element(): HTMLImageElement {
    return this._el.nativeElement;
  }

  @HostListener('loadedmetadata', ['$event']) onLoadedMetaData(event) {
    this.isLoading = false;
    this.isError = false;
  }

  @HostListener('error', ['$event']) onError(event) {
    this.isLoading = false;
    this.isError = true;
  }

}
