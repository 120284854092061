import { Injectable } from "@angular/core";
import { IApi } from '../http-handler/common/contracts/api';
import { Template } from '../models/template.model';
import { HttpClient } from '@angular/common/http';
import { GenericApi } from '../http-handler/common/generic-api';

@Injectable({
    providedIn:'root'
})

export class TemplateService{
    templates:IApi<Template>;

    constructor(http:HttpClient){
        this.templates = new GenericApi<Template>('templates',http);
    }
}