<mat-drawer-container>
  <mat-drawer opened mode="side">
    <app-side-nav
      [tabs]="tabs"
      [campaign]="campaign"
      [form]="form"
      [disableNavigation]="isLoading"
      (tabChange)="changeTab($event)"
    ></app-side-nav>
  </mat-drawer>
  <mat-drawer-content class="d-flex flex-column">
    <app-header-nav></app-header-nav>

    <div class="content">
      <app-create-collect-pot
        [(isLoading)]="isLoading"
        [(id)]="campaignId"
        [(campaign)]="campaign"
        [form]="form"
        (campaignChange)="fetch()"
        *ngIf="tabs[0].isActive"
        (next)="changeTab($event)"
      ></app-create-collect-pot>
      <app-invite-people
        [campaign]="campaign"
        [form]="form"
        (next)="changeTab($event)"
        (campaignChange)="fetch()"
        *ngIf="tabs[1].isActive"
        [campaignId]="campaignId"
      ></app-invite-people>
      <app-buy-gift-card
        [(campaign)]="campaign"
        [(loading)]="isLoading"
        [form]="form"
        *ngIf="tabs[2].isActive"
      >
      </app-buy-gift-card>
    </div>
    <footer class="d-flex mb-1 bottom-links">
      <div class="mx-auto d-flex w-25 footers">
        <a target="_blank" routerLink="/privacy-policy">Privacy policy</a>
        <a target="_blank" routerLink="/terms-conditions">Terms of use</a>
        <a target="_blank" routerLink="/about-us">About us</a>
      </div>
    </footer>
  </mat-drawer-content>
</mat-drawer-container>
