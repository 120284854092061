import { Directive, ElementRef, Input, Renderer2, OnChanges, SimpleChanges, ChangeDetectorRef } from '@angular/core';
import { Observable } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';

@Directive({
    selector: '[media]'
})
export class MediaFileDirective {

    @Input() file: any;

    constructor(private element: ElementRef, private renderer: Renderer2, private ref: ChangeDetectorRef, private domSanitization: DomSanitizer
    ) { }

    getImgfromUrl(url: string) {
        return Observable.create(observer => {
            let req = new XMLHttpRequest();
            req.open('get', url);
            req.responseType = "arraybuffer";
            req.onreadystatechange = function () {
                if (req.readyState == 4 && req.status == 200) {
                    observer.next(req.response);
                    observer.complete();
                }
            };
            req.send();
        });
    }

    ngOnChanges(changes: SimpleChanges, ) {
        let element = this.element;
        if (this.file) {
            if (typeof this.file === 'string') {
                this.getImgfromUrl(this.file).subscribe(fileData => {
                    let src = URL.createObjectURL(new Blob([fileData]));
                    element.nativeElement.src = src;
                    return this.ref.detectChanges();
                });
            } else {
                let reader = new FileReader();
                reader.onloadend = (e) => {
                    element.nativeElement.src = reader.result;
                    return this.ref.detectChanges();
                };
                reader.readAsDataURL(this.file);
            }
        }

    }



}