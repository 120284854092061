import { Injectable } from '@angular/core';
import { IApi } from '../http-handler/common/contracts/api';
import { HttpClient } from '@angular/common/http';
import { GenericApi } from '../http-handler/common/generic-api';
import { Category } from '../models/category.model';

@Injectable({
  providedIn: 'root'
})
export class CategoriesService {

  categories:IApi<Category>;

constructor(
  http:HttpClient
) {

  this.categories = new GenericApi<Category>('categories',http);

 }

}
