<div>
  <h3 class="text-center"> {{ data.type == 'encode' ? 'Anecdotes' : '' || data.type == 'wishes' ? 'Wishes' : '' || data.type == 'downloadWishes' ? 'Download Message Wall' : ''  }}</h3>
  <div (click)="downloadMessageWall()" *ngIf="data.type == 'downloadWishes' && textMessage" class="d-flex justify-content-end cursor my-2">
    Download message wall
  </div>
  <div class="scrollDiv">
    <div class="nameText" *ngIf="!data?.contributions?.length">
      <!-- {{ data.type == 'encode' ? 'No enecdotes found!' : 'No wishes found!' }} -->
      {{ data.type == 'encode' ? 'No enecdotes found!' : '' || data.type == 'wishes' ? 'No wishes found!' : '' || data.type == 'downloadWishes' ? 'No Message Wall' : ''  }}
      
    </div>
    <div class="card" *ngFor="let item of data?.contributions">
      <div class="d-flex align-items-center">
        <img *ngIf="data.type !== 'downloadWishes'"
          class="userImgUrl"
          [src]="item?.user?.imgUrl || 'assets/images/team-man-placeholder.jpg'"
          alt=""
        />
        <div class="nameText pl-2">{{ (item?.user?.name | titlecase) || "--" }}</div>
      </div>
      <div *ngIf="data.type == 'encode'" class="mt-1">
        {{item?.funnyStoryText || '--'}}
      </div>
      <div *ngIf="data.type == 'wishes'" class="mt-1">
        {{item.message || '--'}}
      </div>
      <div *ngIf="data.type == 'downloadWishes'" class="mt-1 pl-2">
        {{item.message || '--'}}
      </div>
    </div>
  </div>
</div>
