import {
  Component,
  OnInit,
  Inject,
  ViewChild,
  HostListener,
  Input,
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Campaign } from 'src/app/models/campaign.model';
import { AppService } from 'src/app/services/app.service';
import { Page } from 'src/app/http-handler/common/contracts/page';
import { Contribution, FireContribution } from 'src/app/models/contribution';
import { ContributionService } from 'src/app/services/contributions.service';
import { FileUploaderComponent } from 'src/app/modules/shareModule/components/file-uploader/file-uploader.component';
import { CampaignService } from 'src/app/services/campaign.service';
// import { ImageCropperComponent } from 'ngx-image-cropper';
import { ProdigiProduct } from 'src/app/models/prodigiProduct.model';
import { MargeImagesService } from 'src/app/services/margeImages.service';
import { environment } from 'src/environments/environment';
import { CardFirebaseService } from 'src/app/services/card-firebase.service';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-create-card',
  templateUrl: './create-card.component.html',
  styleUrls: ['./create-card.component.scss'],
})
export class CreateCardComponent implements OnInit {
  // @ViewChild(ImageCropperComponent) imageCropper: ImageCropperComponent;
  @Input() form: FormGroup = new FormGroup({});

  contributions: Page<Contribution> = new Page({
    api: this._contributionService.contributions,
    serverPaging: false,
    filters: [
      {
        field: 'campaignId',
        value: this.data.campaign.id,
      },
    ],
  });

  outerOptions = {
    textArry: [],
    selectedTextIndex: null,
    selectedText: {
      txt: '',
      styles: {
        zIndex: 1,
        color: 'black',
        fontSize: 15,
        fontFamily: 'Source Sans Pro, sans-serif',
      },
    },
    textZindex: 0,
    fontFamilies: [
      'Source Sans Pro, sans-serif',
      'Caviar Dreams',
      'GeosansLight',
      'gotham',
      'GeosansLight',
      'cursive',
      'fantasy',
      'inherit',
      'initial',
      'monospace',
      'sans-serif',
      'serif',
      'unset',
      'Helvetica Neue',
      'Roboto',
    ],
  };

  isLoading: boolean;
  isImageLoading = true;
  base64 = '';
  firebaseWishes: FireContribution[] = [];
  get campaign() {
    return this.data.campaign;
  }
  get selectedProduct(): ProdigiProduct {
    return this.data.productDetail;
  }

  get cardDimensions() {
    const height = this.selectedProduct?.height || 450;
    const width = height / this.campaign.cardDimensions;
    return { height: `${height}px`, width: `${width * 2}px` };
  }
  cardPages = [{ page: 1 }];
  selectedPageIndex = 0;
  wishesPerPage: FireContribution[] = [];

  constructor(
    public dialogRef: MatDialogRef<CreateCardComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      campaign: Campaign;
      productDetail: ProdigiProduct;
      isPreviewFirstTime: boolean;
      id: string;
    },
    private _appService: AppService,
    private _contributionService: ContributionService,
    private _campaignService: CampaignService,
    private _margeImagesService: MargeImagesService,
    private _cardFirebase: CardFirebaseService
  ) {
    console.log(data, 'data');

    this.fetch();
    this.addNewTxt();
    if (!this.campaign.finalTemplate) {
      if (!this.campaign?.cardDimensions) {
        this.campaign.cardDimensions = 1.4142;
      }
      this.contributions.fetch().then((d) => {
        // console.log(d, 'contribution');
        this.contributions.select(d);
      });
    }
  }

  ngOnInit() { }

  async fetch() {
    this.loadImage();
    this.subscribeWishes();
  }

  subscribeWishes() {
    const subscribe = this._cardFirebase
      .getContributions(this.data.id)
      .subscribe((d) => {
        this.firebaseWishes = d;
        if (d.length) {
          const arr = [...new Set(d.map((w) => w.page || 1))];
          this.cardPages = arr.map((page, idx) => ({ page }));
        }
        this.selectPage(this.selectedPageIndex);
        subscribe.unsubscribe();
      });
  }

  nextPage() {
    if (this.selectedPageIndex >= this.cardPages.length - 1) {
      return;
    }
    this.selectPage(this.selectedPageIndex + 1);
  }

  previousPage() {
    if (this.selectedPageIndex <= 0) {
      return;
    }
    this.selectPage(this.selectedPageIndex - 1);
  }

  selectPage(index: number) {
    this.selectedPageIndex = index;
    const page = this.cardPages[index].page;
    this.wishesPerPage = this.firebaseWishes.filter((w) => w.page == page);
  }

  async loadImage() {
    this.isImageLoading = true;
    try {
      this.base64 = await this._margeImagesService.getImage(
        `${environment.apiUrls.api}/api/campaigns/change/base?imgUrl=${this.campaign.selectedTemplateUrl}`
      );
    } catch (error) {
      // console.log(error);
      this._appService.snackBarOpen('Error loading image');
    } finally {
      this.isImageLoading = false;
    }
  }
  async create() {
    // if (this.campaign.finalTemplate) return this.dialogRef.close();
    this.isLoading = true;
    this.outerOptions.selectedTextIndex = null;
    this.contributions.selected = null;
    let div = document.getElementById('editer');
    // div.style.display = 'none';
    let coverImg = document.getElementById('coverImg');
    if (!div) return this.dialogRef.close();
    // return;
    try {
      await new Promise((res) => setTimeout(res, 500));
      const wishiesImgFile = await this._appService.generateImage(div);
      const wishiesImgBase64 = await this._margeImagesService.toBase46(
        wishiesImgFile
      );
      const coverImgFile = await this._appService.generateImage(coverImg);
      const coverImgBase64 = await this._margeImagesService.toBase46(
        coverImgFile
      );
      // window.open().document.write(`
      //   <img src="${coverImgBase64}" />
      // `)

      // window.open().document.write(`
      //   <img src="${wishiesImgBase64}" />
      // `)

      const uploadedFiles = [wishiesImgFile, coverImgFile];
      if (this.selectedProduct) {
        var prodigiImgFile = await this._margeImagesService.margeImage(
          [coverImgBase64, wishiesImgBase64],
          this.selectedProduct.height,
          this.selectedProduct.width
        );
        uploadedFiles.push(prodigiImgFile);
      }
      this.uploadFile(uploadedFiles);
      this.data.isPreviewFirstTime = true;
    } catch (error) {
      this.isLoading = false;
      // console.log(error);
      this._appService.snackBarOpen('Something went wrong. please try again');
    } finally {
      // div.style.display = 'initial';
    }
  }

  zIndex = 0;

  select(c: FireContribution) {
    const item = this.contributions.items.find(
      (i) => i.id === c.contributionId
    );
    if (
      this.contributions.selected &&
      c.contributionId === this.contributions.selected.id
    )
      return;
    this.zIndex++;
    c.zIndex = this.zIndex;
    this.contributions.select(item);
  }

  onClick(e: Event) {
    e.stopPropagation();
    e.preventDefault();
  }

  addNewTxt() {
    if (!this.outerOptions.selectedText?.txt?.trim()) return;
    if (this.outerOptions.selectedTextIndex === null) {
      this.outerOptions.textArry.push({
        ...this.outerOptions.selectedText,
      });
    }
    this.outerOptions.selectedText = {
      txt: '',
      styles: {
        zIndex: 1,
        color: 'black',
        fontSize: 15,
        fontFamily: this.outerOptions.fontFamilies[0],
      },
    };
    this.outerOptions.selectedTextIndex = null;
  }

  selectText(index) {
    if (this.outerOptions.selectedTextIndex === index) return;
    this.outerOptions.selectedTextIndex = index;
    this.outerOptions.selectedText = this.outerOptions.textArry[index];
    this.outerOptions.selectedText.styles.zIndex = this.outerOptions.textZindex;
    this.outerOptions.textZindex++;
  }

  @HostListener('document:keypress', ['$event']) onKeyPress(
    event: KeyboardEvent
  ) {
    if (event.key.toLowerCase() === 'delete') {
      this.deleteSelectText();
    }
  }

  deleteSelectText() {
    if (this.outerOptions.textArry.length) {
      this.outerOptions.textArry.splice(this.outerOptions.selectedTextIndex, 1);
      this.outerOptions.selectedTextIndex = null;
    }
  }

  onDropped(position, item: FireContribution) {
    item.postion = position;
    this._cardFirebase.updateContribution(
      this.data.id,
      item.contributionId,
      item
    );
  }

  // imageCropped(event: ImageCroppedEvent) {
  //   this.croppedFile = this._appService.convertBase64Tofile(event.base64);
  //   console.log(this.croppedFile);
  // }

  uploadFile(file?: File[]) {
    // console.log('res2');
    let dialog = this._appService.matDialog.open(FileUploaderComponent, {
      disableClose: true,
      data: file.filter((i) => !!i),
    });

    dialog.afterClosed().subscribe((d: string[]) => {
      if (d && d.length) {
        this.dialogRef.close({
          urls: d,
          cardDimensions: this.campaign.cardDimensions,
        });
      }
    });
  }

  update(body: Campaign) {
    this._campaignService.campaigns
      .update(this.campaign.id, body)
      .then((d) => {
        this._appService.snackBarOpen('Card successfully created');
      })
      .catch((err) => {
        this.isLoading = false;
        this._appService.snackBarOpen(err);
      });
  }

  createCard() {
    // console.log('click');
    // console.log(this.data);
    this.data.isPreviewFirstTime = false;
    this.campaign.finalTemplate
    // console.log(this.data.isPreviewFirstTime);
  }
}
