<div class="container-fluid px-4">
  <div class="header mt-1">
    <!-- <h3 class="m-0">COLLECTAGIFT POTS</h3> -->

    <div class="d-flex ml-auto row">
      <div class="pl-3 col-lg-6">
        <img class="mr-2" src="assets/icons/Sort.png" alt="" />
        <mat-form-field>
          <mat-label>Sort By Creation Date</mat-label>
          <mat-select
            [(ngModel)]="campaigns.filters.properties['sortBy'].value"
            (selectionChange)="fetch(true)"
          >
            <mat-option [value]="'sortByCreationDateInAsc'">
              Oldest First
            </mat-option>
            <mat-option [value]="'sortByCreationDateInDesc'">
              Newest First
            </mat-option>
          </mat-select>
          <mat-icon matSuffix>keyboard_arrow_down</mat-icon>
        </mat-form-field>
      </div>
      <div class="pl-3 col-lg-6">
        <img class="mr-2" src="assets/icons/Sort.png" alt="" />
        <mat-form-field>
          <mat-label>Sort By Deadline Date</mat-label>
          <mat-select
            [(ngModel)]="campaigns.filters.properties['sortBy'].value"
            (selectionChange)="fetch(true)"
          >
            <mat-option [value]="'sortByDeadLineDateInAsc'">
              Oldest First
            </mat-option>
            <mat-option [value]="'sortByDeadLineDateInDesc'">
              Newest First
            </mat-option>
          </mat-select>
          <mat-icon matSuffix>keyboard_arrow_down</mat-icon>
        </mat-form-field>
      </div>
    </div>
  </div>

  <div class="cards mobile-res">
    <div *ngFor="let item of campaigns.items">
      <camaign-card
        [campaign]="item"
        [showStatus]="true"
        [actionOptions]="true"
      ></camaign-card>
    </div>
  </div>

  <div
    class="col-12 mt-5 pt-5"
    *ngIf="!campaigns.isLoading && !campaigns.items.length"
  >
    <div class="m-auto text-center pt-5">
      <img src="https://img.icons8.com/bubbles/200/000000/image-file.png" />
      <h6>no campaigns available</h6>
    </div>
  </div>
</div>
