<div class="outer">
  <div class="header">
    <img src="assets/logos/CollectaGift-WhiteTextOnly.png" alt="">
  </div>
  <div class="circle-progress">
    <!-- <div class="spinners">
      <mat-progress-spinner [strokeWidth]="5" [diameter]="130" [value]="100"></mat-progress-spinner>
      <mat-progress-spinner class="white" [strokeWidth]="5" [diameter]="130" [value]="progress"></mat-progress-spinner>
      <h3>
        {{progress | number:'.1' }}%
      </h3>
    </div> -->
    <p>
      <span class="text-white">{{date | date:'dd MMMM yyyy, hh:mm a'}}</span><br>
      <span class="text-left" *ngIf="form.get('receipientName')?.value">
        Create CollectaGift Pot for <br>
        {{form.get('receipientName')?.value}}
      </span>
    </p>
  </div>
  <nav class="side-nav">
    <ul>
      <li *ngFor="let item of tabs;let i = index;" (click)="active(i)" class="custom-radios"
        [class.active]="item.isActive">

        <div class="align-items-center d-flex">
          <span class="mr-3 p-0" [class.active]="isCompleted(i)" >
              {{ isCompleted(i) ? "" :  i+1}}
              <mat-icon *ngIf="isCompleted(i)" style="color: #ef4f60;font-size: 15px;height: 15px;width: 15px;" >check</mat-icon>
          </span>
          <div class="col p-0 navtitle">
            {{item?.title}}
          </div>
        </div>
      </li>
    </ul>
    <h6 class="mt-auto" >
      <!-- Have a question? <a > CONTACT US </a> -->
    </h6>
  </nav>
  <footer>
    <h6>
      <!-- <p class="mb-0" >copyright © Collect A Gift 2020</p> -->
      <p>Copyright © CollectaGift 2022 All Rights Reserved</p>
    </h6>
  </footer>
</div>
