import { NgModule } from "@angular/core";

import { ButtonDirective } from "./directives/button-ui/button.directive";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HeaderNavComponent } from "./components/header-nav/header-nav.component";
import { CheckBoxDirective } from "./directives/check-box/check-box.directive";
import { RouterModule } from "@angular/router";
import { HttpClientModule } from "@angular/common/http";
import { DateInputComponent } from "./components/date-input/date-input.component";
import { CommonModule } from "@angular/common";
import { AngularFireModule } from "@angular/fire";
import { AngularFireAuthModule } from "@angular/fire/auth";
import { environment } from "src/environments/environment.prod";
import { MatCardModule } from "@angular/material/card";
import { MatTableModule } from "@angular/material/table";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatBottomSheetModule } from "@angular/material/bottom-sheet";
import { MatListModule } from "@angular/material/list";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatSelectModule } from "@angular/material/select";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatMenuModule } from "@angular/material/menu";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatDialogModule } from "@angular/material/dialog";
import { MatNativeDateModule, MAT_DATE_LOCALE } from "@angular/material/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatChipsModule } from "@angular/material/chips";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatSliderModule } from "@angular/material/slider";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatTabsModule } from "@angular/material/tabs";
import { MatRadioModule } from "@angular/material/radio";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatRippleModule } from "@angular/material/core";
import { MediaFileDirective } from "./directives/media-file/img-preview.directive";
import { Base64Pipe } from "./directives/pipes/base64/base64.pipe";
import { FileUploadModule } from "ng2-file-upload";
import { FileUploaderComponent } from "./components/file-uploader/file-uploader.component";
import { DateTimePipe } from "./directives/pipes/date-time/date-time.directive";
import { SingleCamaignComponent } from "./components/single-camaign/single-camaign.component";
import { NotFound404Component } from "./components/404-not-found/404-not-found.component";
import { CategoryColorPipe } from "./directives/pipes/category-color/category-color.pipe";
import { TooltipModule } from "ng2-tooltip-directive";
import { TooltipOptions } from "ng2-tooltip-directive";
import { LazyLoadImageModule } from "ng-lazyload-image";
import { ImageCropperModule } from "ngx-image-cropper";
import { ImageCropperComponent } from "./components/image-cropper/image-cropper.component";
import { MatStepperModule } from "@angular/material/stepper";
import { SafePipe } from "./directives/pipes/safe-url/safe-url.pipe";
import { AngularDraggableModule } from "angular2-draggable";
import { StartWithContributeComponent } from "./dialogs/start-with-contribute/start-with-contribute.component";
import { ChangePasswordDialogComponent } from "./dialogs/change-password-dialog/change-password-dialog.component";
import { CampaignInviteIdPipe } from "./directives/pipes/cmpaign-invite-id/campaign-invite-id.pipe";
import { ControlMessagesComponent } from "./controls-msg/control-messages.omponent";
import { AddBankAccountComponent } from "./dialogs/add-bank-account/add-bank-account.component";
import { PayoutComponent } from "./dialogs/payout/payout.component";
import { SquireStringPipe } from "./directives/pipes/squire-string/squire-string.pipe";
import { CharOnlyDirective } from "./directives/char-only/char-only.directive";
import { SelectCountryComponent } from "./components/select-country/select-country.component";
import { NgxImageZoomModule } from "ngx-image-zoom";
import { AddCardDetialComponent } from "./components/add-card-detial/add-card-detial.component";
import { CardExDateDirective } from "./directives/card-ex-date/card-ex-date.directive";
import { CardNumDirective } from "./directives/card-num/card-num.directive";
import { InviteCharityComponent } from "./components/header-nav/invite-charity/invite-charity.component";
import { PassowordStrengthBarComponent } from "./components/passoword-strength-bar/passoword-strength-bar.component";
import { ImageDirective } from "./directives/image-dir/image.directive";
import { CampaignDigitalCardComponent } from "./components/campaign-digital-card/campaign-digital-card.component";
import { SuccessMessageDialogComponent } from "./dialogs/success-message-dialog/success-message-dialog.component";

const tooltipOptions: TooltipOptions = {
  theme: "light",
  "tooltip-class": "tooltip",
  "max-width": 700,
};

const modules = [
  MatStepperModule,
  MatSidenavModule,
  MatCardModule,
  MatTableModule,
  MatCheckboxModule,
  MatExpansionModule,
  MatBottomSheetModule,
  MatListModule,
  MatButtonModule,
  MatIconModule,
  MatInputModule,
  MatSelectModule,
  MatFormFieldModule,
  MatToolbarModule,
  MatMenuModule,
  MatTooltipModule,
  MatDialogModule,
  MatNativeDateModule,
  MatDatepickerModule,
  MatChipsModule,
  MatPaginatorModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatSliderModule,
  MatSnackBarModule,
  MatAutocompleteModule,
  MatTabsModule,
  MatRadioModule,
  MatSlideToggleModule,
  FormsModule,
  ReactiveFormsModule,
  HttpClientModule,
  AngularFireAuthModule,
  RouterModule,
  FileUploadModule,
  MatRippleModule,
  LazyLoadImageModule,
  ImageCropperModule,
  AngularDraggableModule,
  NgxImageZoomModule,
  // NgXCreditCardsModule
];

const directive = [
  ButtonDirective,
  CheckBoxDirective,
  MediaFileDirective,
  Base64Pipe,
  DateTimePipe,
  CategoryColorPipe,
  CampaignInviteIdPipe,
  SquireStringPipe,
  CharOnlyDirective,
  CardExDateDirective,
  CardNumDirective,
  ImageDirective,
];

const components = [
  HeaderNavComponent,
  DateInputComponent,
  FileUploaderComponent,
  SingleCamaignComponent,
  NotFound404Component,
  ImageCropperComponent,
  SafePipe,
  StartWithContributeComponent,
  ChangePasswordDialogComponent,
  AddBankAccountComponent,
  ControlMessagesComponent,
  PayoutComponent,
  SelectCountryComponent,
  AddCardDetialComponent,
  InviteCharityComponent,
  PassowordStrengthBarComponent,
  CampaignDigitalCardComponent,
  SuccessMessageDialogComponent
];

const providers = [{ provide: MAT_DATE_LOCALE, useValue: "en-GB" }];

@NgModule({
  declarations: [...directive, ...components],
  imports: [
    CommonModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    TooltipModule.forRoot(tooltipOptions),
    ...modules,
  ],
  entryComponents: [
    StartWithContributeComponent,
    ChangePasswordDialogComponent,
    AddBankAccountComponent,
    SuccessMessageDialogComponent
  ],
  exports: [
    ...modules,
    ...directive,
    ...components,
    AngularFireModule,
    TooltipModule,
  ],
  providers: [...providers],
})
export class ShareModule {}
