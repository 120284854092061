<div class="d-inline-block input-container" >

  <div class="d-flex align-items-center pb-2" (click)="date.open()" >
    <img src="assets/images/calender.png" alt="">
    <input [min]="minDate" (dateChange)="dateChange($event.value)" [formControl]="control" type="text" readonly placeholder="DD/MM/YY" (focus)="date.open()" (click)="date.open()" [matDatepicker]="date" >
    <mat-datepicker #date ></mat-datepicker>
    <img src="assets/images/blue down.png" class="mr-1" alt="">
    <div class="surface" [class.border-danger]="control?.errors && control?.touched" ></div>
  </div>

</div>