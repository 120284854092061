<div class="dialog-container p-0">
  <header class="border-bottom p-3 d-flex flex-header-browser justify-content-between align-items-center position-relative">
    <h5 class="m-0">Browse available Gift Providers</h5>
    <div class="mr-5 align-center sub-flex-header-browser">
      <div class="pl-3 col-lg-6">
        <mat-form-field>
          <mat-select
            #status="matSelect"
            [(ngModel)]="category"
            (selectionChange)="fetchTremendousProducts()"
            placeholder="Filter By Category"
          >
            <mat-option value="All">Show All</mat-option>
            <mat-option value="Fashion, Jewellery and Other Retail"
              >Fashion, Jewellery and Other Retail</mat-option
            >
            <mat-option value="Travel">Travel</mat-option>
            <mat-option value="Groceries">Groceries</mat-option>
            <mat-option value="Restaurants">Restaurants</mat-option>
            <mat-option value="Department Stores">Department Stores</mat-option>
            <mat-option value="Technology">Technology</mat-option>
            <mat-option value="Football">Football</mat-option>
            <mat-option value="Homewear">Homewear</mat-option>
            <mat-option value="DIY">DIY</mat-option>
            <mat-option value="Food & Drink">Food & Drink</mat-option>
            <mat-option value="Experiences">Experiences</mat-option>
            <mat-option value="Spa & Wellness">Spa & Wellness</mat-option>
            <mat-option value="Babies & Children">Babies & Children</mat-option>
            <mat-option value="Entertainment">Entertainment</mat-option>
            <mat-option value="Gift Cards">Flex Vouchers</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <input
      class="form-control"
      type="search"
      [(ngModel)]="searchVal"
      (input)="onSearchInput()"
      />
    </div>
    <button class="flex-button-close" mat-icon-button (click)="dialogRef.close()">
      <mat-icon>clear</mat-icon>
    </button>
  </header>

  <div class="content">
    <div class="row w-100">
      <div class="col-md-3" *ngFor="let item of fullTremendousList">
        <mat-card class="mb-3">
          <img
            mat-card-image
            (click)="giftCardDetails(item)"
            *ngIf="item?.image_thumb_url"
            [src]="item?.image_thumb_url"
          />
          <mat-card-content>
            <p class="text-center h5">
              {{ item?.name }}
            </p>
          </mat-card-content>
        </mat-card>
      </div>
      <div
        class="align-center w-100 justify-center"
        *ngIf="tremendousProducts?.isLoading"
      >
        <!-- Print Card -->
        <mat-spinner
          class="d-inline-block ml-2"
          mode="indeterminate"
          [strokeWidth]="2"
          [diameter]="22"
        ></mat-spinner>
      </div>
      <div
        class="w-100 text-center"
        *ngIf="
          !tremendousProducts?.list?.length && !tremendousProducts?.isLoading
        "
      >
        No data is available
      </div>
    </div>
  </div>
</div>
