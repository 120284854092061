import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ValidationService } from 'src/app/modules/shareModule/controls-msg/validation.service';
import { AppService } from 'src/app/services/app.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-guest-user-detail',
  templateUrl: './guest-user-detail.component.html',
  styleUrls: ['./guest-user-detail.component.scss'],
})
export class GuestUserDetailComponent implements OnInit {
  formGroup: FormGroup = new FormGroup({
    email: new FormControl('', [
    ]),
    name: new FormControl('', [ValidationService.spaceValidator]),
  });
  get formValue(): { email: string; name: string } {
    return this.formGroup.value;
  }

  constructor(
    public matDialogRef: MatDialogRef<GuestUserDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { id: string },
    private _appService: AppService,
    private _userService: UserService
  ) {
    if (!data.id) {
      this.closeDialog();
    }
  }

  closeDialog(data?: any) {
    this.matDialogRef.close(data);
  }
  isLoading = false;
  async submit() {
    try {
      if (this.formGroup.invalid) {
        return this.formGroup.markAllAsTouched();
      }
      this.isLoading = true;
      const user = await this._userService.createGuestUser(this.formValue);
      this.closeDialog(user);
    } catch (error) {
      this.isLoading = false;
    }
  }

  ngOnInit() {}
}
