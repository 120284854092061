import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { rejects } from 'assert';

@Injectable({
  providedIn: 'root'
})
export class CurrenciesService {

  private _token:string;
  currencies: Currency[] = [];

  constructor(
    private _http: HttpClient
  ) {

  }

  fetchList(): Observable<Currency[]> {
    let observable = this._http.get<Currency[]>('assets/currencies/Common-Currency.json');
    observable.subscribe(d => {
      this.currencies = [...d.filter(i => i.code?.toLowerCase()?.includes('gbp'))];
    })
    return observable;
  }

  search(v: string): Currency[] {
    v = v.toLowerCase();
    let currencies = this.currencies.filter(i => {
      Object.keys(i).forEach((k) => typeof i[k] === 'string' ? i[k].toLowerCase() : "")
      return (i.name.toLowerCase().includes(v) || i.code.toLowerCase().includes(v) || i.symbol.toLowerCase().includes(v) || i.name_plural.toLowerCase().includes(v) || i.rounding.toString().includes(v) || i.symbol_native.toLowerCase().includes(v) || i.decimal_digits.toString().includes(v))
    });

    return currencies;
  }

  generateToken():Promise<any>{
    return new Promise((res, rej) => {
      this._http.get('https://www.universal-tutorial.com/api/getaccesstoken', {
        headers: {
          "Accept": "application/json",
          "api-token": "5rUQ76iJffoEZjoTLlBDO3m_GCqLh0UHYKnDlN5TOZ_Iy0FonogmQHdKLnk7QeMh_jY",
          "user-email": "camshareofficial@gmail.com"
        }
      }).toPromise().then(d => {
        this._token = d['auth_token'];
        res(d);
      }).catch(err => {
        rej(err)
      })
    })
  }

  getCountires(): Promise<any> {
    return new Promise((res, rej) => {
      this._http.get<any[]>('https://www.universal-tutorial.com/api/countries', {
        headers: { 'Authorization': `Bearer ${this._token}` }
      }).subscribe(d => {
        res(d);
      }, err => { rej(err) });
    });

  }

  getStates(countryName):Promise<any>{
    return new Promise((res, rej) => {
      this._http.get<any[]>(`https://www.universal-tutorial.com/api/states/${countryName}`, {
        headers: { 'Authorization': `Bearer ${this._token}` }
      }).subscribe(d => {
        res(d);
      }, err => { rej(err) });
    });
  }

  getCities(state:string):Promise<any>{
    return new Promise((res, rej) => {
      this._http.get<any[]>(`GET https://www.universal-tutorial.com/api/cities/${state}`, {
        headers: { 'Authorization': `Bearer ${this._token}` }
      }).subscribe(d => {
        res(d);
      }, err => { rej(err) });
    });
  }
  

}


export class Currency {
  symbol?: string;
  name?: string;
  symbol_native?: string;
  decimal_digits?: number;
  rounding?: number;
  code?: string;
  name_plural?: string;
}
