import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TremendousProduct } from 'src/app/models/tremendous.product.model';
import { CampaignService } from 'src/app/services/campaign.service';
import { CardsDetailsComponent } from '../cards-details/cards-details.component';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
@Component({
  selector: 'app-browse-available-gift-providers',
  templateUrl: './browse-available-gift-providers.component.html',
  styleUrls: ['./browse-available-gift-providers.component.scss'],
})
export class BrowseAvailableGiftProvidersComponent implements OnInit {
  tremendousProducts: { list: TremendousProduct[]; isLoading: boolean } = {
    list: [],
    isLoading: false,
  };
  isFullProductsListLoading: boolean = false;
  searchVal: '';
  category: '';
  fullTremendousList: TremendousProduct[];
  private searchSubject: Subject<string> = new Subject();
  constructor(
    public dialogRef: MatDialogRef<BrowseAvailableGiftProvidersComponent>,
    private _dialog: MatDialog,
    private _campaignService: CampaignService
  ) {
    dialogRef.addPanelClass('create-catalogue-product-and-proceed');
    this.fetchTremendousProducts();
    this.searchSubject.pipe(debounceTime(400)).subscribe(() => {
      this.search(this.searchVal);
    });
  }

  ngOnInit() {}

  onSearchInput() {
    this.searchSubject.next(this.searchVal);
    console.log(this.searchVal);
  }

  ngOnDestroy() {
    this.searchSubject.unsubscribe();
  }

  async fetchTremendousProducts() {
    this.tremendousProducts.isLoading = true;
    this.tremendousProducts.list =
      await this._campaignService.getTremendousProduct(this.category);
    this.fullTremendousList = this.tremendousProducts.list.filter(
      (item) =>
        item?.items?.filter((card) => card.denomination_type !== 'fixed')
          .length > 0 && item.product_id !== 101582
    );
    this.tremendousProducts.isLoading = false;
    console.log(this.tremendousProducts.list[0]);
  }

  async search(value: string) {
    this.tremendousProducts.isLoading = true;
    this.isFullProductsListLoading = true;
    const searchVal = value.toLowerCase();
    if (!searchVal) {
      this.tremendousProducts.isLoading = false;
      this.isFullProductsListLoading = false;
      return (this.fullTremendousList = this.tremendousProducts.list);
    }
    this.tremendousProducts.isLoading = true;
    this.isFullProductsListLoading = true;
    this.fullTremendousList = this.tremendousProducts?.list?.filter((list) => {
      const name = list?.name?.toLowerCase();
      return name?.includes(searchVal);
    });
    console.log(this.fullTremendousList);
    this.tremendousProducts.isLoading = false;
    this.isFullProductsListLoading = false;
  }

  giftCardDetails(item) {
    this._dialog.open(CardsDetailsComponent, {
      maxHeight: '80vh',
      maxWidth: '600px',
      width: '600px',
      height: 'auto',
      data: item,
    });
  }
}
