import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable(
    {
        providedIn: 'root'
    }
)
export class MargeImagesService {
    constructor(
        private http: HttpClient
    ) { }

    async margeImage(images: string[], height, width) {
        const canvas = document.createElement('canvas');
        canvas.height = height;
        canvas.width = width * 4;
        const context = canvas.getContext('2d');
        context.fillStyle = "white";
        context.fillRect(0, 0, canvas.width, height);
        const loadedImages = await this._loadImages(images);
        loadedImages.forEach((img, indx) => context.drawImage(img, (indx + 1) * width, 0, width, height))
        const base64 = canvas.toDataURL('image/png');
        return this.dataURLtoFile(base64)
    }

    private async _loadImages(imgs: string[]) {
        const loadedImgs = imgs.filter(i => !!i).map(src => {
            return new Promise<HTMLImageElement>(async (r, rej) => {
                const img = new Image();
                img.onload = () => r(img);
                img.onerror = () => rej(null);
                img.setAttribute('crossorigin', "anonymous")
                img.src = src;
            })
        })
        return Promise.all(loadedImgs);
    }

    async getImage(url: string): Promise<string> {
        const path = this.convertProxyUrl(url);
        return this.http.get(path, { responseType: 'blob', headers: {
            timeout:'2000000'
        } }).toPromise().then(async result => {
            return await this.toBase46(result);
        }).catch(err => {
            console.log(err);
            throw err;
        });
    }

    convertProxyUrl(url) {
        if (!url) return '';
        if(!location.hash.includes('localhost')){
            return url;
        }
        return `/getImage/${url.split('/')[url.split('/')?.length - 1]}`
    }

    dataURLtoFile(dataurl, filename?: string) {
        var arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename || 'New image file', { type: mime });
    }

    toBase46 = async (file) => new Promise<string>((res, rej) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => res(reader.result as string);
        reader.onerror = () => rej(reader.error);
    })

}