<div class="files">

  <div class="file" *ngFor="let item of uploader?.queue;let i = index" >

    <div class="view-image" [style.backgroundImage]="'url('+(item._file | base64 | async)+')'" *ngIf="item._file.type.includes('image')" >
        
    </div>
    <img width="100px" height="100px" src="https://img.icons8.com/color/96/000000/music-record--v1.png" *ngIf="item._file.type.includes('audio')" />
    <video width="100px" [src]="item._file | base64 | async" *ngIf="item._file.type.includes('video')" ></video>
    <div class="col p-3">
      <h6>{{item._file?.name || 'Unnamed file'}}</h6>
      <div class="mb-1" >
        <span>size:{{item._file.size/1024/1024 | number:'.2'}} MB</span> 
        <span class="float-right" > {{item.progress}} %</span>
      </div>
      <mat-progress-bar class="orange" [value]="item.progress" ></mat-progress-bar>
    </div>

  </div>

</div>