import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AppService } from 'src/app/services/app.service';
import { UserService } from 'src/app/services/user.service';
import { MatDialogRef } from '@angular/material/dialog';
import { ValidationService } from '../../controls-msg/validation.service';

@Component({
  selector: 'app-change-password-dialog',
  templateUrl: './change-password-dialog.component.html',
  styleUrls: ['./change-password-dialog.component.scss']
})
export class ChangePasswordDialogComponent implements OnInit {

  visibilityPassword = {
    oldPassword:false,
    newPassword:false,
    confirmPassword:false
  }

  form:FormGroup = new FormGroup({
    oldPassword:new FormControl('',[Validators.required,]),
    newPassword:new FormControl('',[Validators.required,ValidationService.passwordValidator]),
    confirmPassword:new FormControl('',[Validators.required,ValidationService.passwordValidator])
  },{ validators:[ValidationService.compareValidator('newPassword','confirmPassword')] });
  isLoading:boolean = false;

  constructor(
    private _appService:AppService,
    private _userService:UserService,
    public dialogRef:MatDialogRef<ChangePasswordDialogComponent>
  ) {

    // this.form.get('newPassword').valueChanges.subscribe(d => {
    //   this.form.get('confirmPassword').setErrors({"notMatch":"Confirm password does not match"});
    // })

    // this.form.get('confirmPassword').valueChanges.subscribe(d => {
    //   if(d && d !== this.form.get('newPassword').value ){
    //     this.form.get('confirmPassword').setErrors({"notMatch":"Confirm password does not match"});
    //   }
    // });

   }

  ngOnInit() {
  }

  visibilityPasswordToggle(type:string){
    this.visibilityPassword[type] = !this.visibilityPassword[type];
  }

  updatePassword(){
    this.isLoading = true;
    this._userService.users.update(this._appService.user.id,{...this.form.value},null,`updatePassword/${this._appService.user.id}`).then(d => {
      this._appService.snackBarOpen('Password successfully updated.');
      this.dialogRef.close();
    }).catch(err => {
      if(err === 'old password not correct'){
        this.form.get('oldPassword').setErrors({'invalid':'invalid old password'})
      }
      this.isLoading = false;
      this._appService.snackBarOpen(err);
    })
  }

}
