import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';


@Pipe({
    name:'safeUrl'
})

export class SafePipe implements PipeTransform{

    constructor(
        private sanitizer:DomSanitizer
    ){

    }

    transform(url){
        let s:any = this.sanitizer.bypassSecurityTrustUrl(url);
        console.log()
        return s.changingThisBreaksApplicationSecurity;
    }

}