<header>
  <h4 class="m-0">
    Add Bank Account
  </h4>
  <button type="button" class="ml-auto" mat-icon-button (click)="dialogRef.close()">
    <mat-icon>clear</mat-icon>
  </button>
</header>

<mat-progress-bar *ngIf="countries?.isLoading || isLoading" [mode]="'indeterminate'"></mat-progress-bar>

<br>

<mat-horizontal-stepper #matStepper [linear]="true">
  <mat-step [stepControl]="form.get('country')">
    <form [formGroup]="form.get('country')">
      <h5>
        First you need to select your country
      </h5>
      <mat-form-field [appearance]="'outline'">
        <mat-label>Select Country</mat-label>
        <mat-select [formControl]="form.get('selectedCountry')"
          (selectionChange)="form.get('country').setValue($event.value)">
          <mat-option [value]="item" *ngFor="let item of countries.items">{{item?.countryName | titlecase}}</mat-option>
        </mat-select>
      </mat-form-field>
      <footer class="text-right mt-4">
        <button type="submit" mat-button color="c1" ButtonUi matStepperNext>
          Next
        </button>
      </footer>
    </form>
  </mat-step>
  <mat-step [stepControl]="form.get('bankAccountDetail')">
    <form [formGroup]="form.get('bankAccountDetail')">
      <h5>
        Fill your bank detail
      </h5>
      <div class="row">

        <div class="col-6">
          <mat-form-field [appearance]="'outline'">
            <mat-label>Account Holder Name</mat-label>
            <input type="text" matInput formControlName="accountHolderName" maxlength="20" validatorController
              char="true">
          </mat-form-field>
        </div>

        <div class="col-6">
          <mat-form-field [appearance]="'outline'">
            <mat-label>Account Number</mat-label>
            <input type="text" matInput formControlName="accountNumber" maxlength="8" validatorController
              number="true">
          </mat-form-field>
        </div>

        <div class="col-6">
          <mat-form-field [appearance]="'outline'">
            <mat-label>{{routingNumberLabel ? (routingNumberLabel | titlecase) : 'Routing Number'}}</mat-label>
            <input type="text" matInput formControlName="sortCode" maxlength="9">
          </mat-form-field>
        </div>

        <!-- <div class="col-6">
          <country-select placeholder="Currency" [type]="'mat-form-field'"
            [currencyCodeControl]="form.get(['bankAccountDetail','currency'])"
            [touched]="form.get(['bankAccountDetail','currency']).touched"
            [optionValueDisplay]="cardService.displayCurrancy" [searchMethod]="cardService.searchCurrancy"
            [inputValueDisplay]="cardService.displayCurrancy">
          </country-select>
        </div>

        <div class="col-6">
          <mat-form-field [appearance]="'outline'">
            <mat-label>City</mat-label>
            <input type="text" maxlength="50" matInput placeholder="City" formControlName="city">
          </mat-form-field>
        </div>

        <div class="col-6">
          <mat-form-field [appearance]="'outline'">
            <mat-label>Postal Code</mat-label>

            <input type="text" maxlength="10" matInput placeholder="Postal Code" formControlName="postalCode">
          </mat-form-field>
        </div>

        <div class="col-12">
          <mat-form-field [appearance]="'outline'">
            <mat-label>Address</mat-label>
            <input type="text" matInput placeholder="Address" formControlName="address">
          </mat-form-field>
        </div> -->

      </div>
      <footer class="text-right mt-4">
        <button type="button" mat-button ButtonUi color="c2" matStepperPrevious [disabled]="isLoading"
          class="mr-3">Back</button>
        <button type="submit" mat-button ButtonUi color="c1" matStepperNext (click)="create()"
          [disabled]="isLoading || form.get('bankAccountDetail').invalid">
          Add
        </button>
      </footer>
    </form>
  </mat-step>
</mat-horizontal-stepper>