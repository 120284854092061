<div class="dialog-container p-0">
  <header class="header">
    <!-- !campaign?.finalTemplate &&   -->

    <div class="font24">
      {{ data.isPreviewFirstTime ? "Card Preview" : "Rearrange wishes" }}
    </div>

    <div class="ml-auto">
      <button mat-icon-button class="ml-auto" (click)="dialogRef.close()">
        <mat-icon>clear</mat-icon>
      </button>
    </div>
  </header>
  <div class="px-3" *ngIf="!data.isPreviewFirstTime">
    <!-- !campaign?.finalTemplate &&  -->
    <!-- <form class="row px-3">
      <div class="form-group col pl-0">
        <label>Add Cover Image Text.</label>
        <input
          type="text"
          name="text"
          class="form-control"
          [(ngModel)]="outerOptions.selectedText.txt"
        />
      </div>

      <div class="form-group col">
        <label>Enter text size</label>
        <input
          type="number"
          name="size"
          maxlength="2"
          [min]="2"
          [max]="50"
          class="form-control"
          [(ngModel)]="outerOptions.selectedText.styles.fontSize"
        />
      </div>

      <div class="form-group col">
        <label>Text color</label>
        <input
          type="color"
          name="color"
          class="form-control"
          [(ngModel)]="outerOptions.selectedText.styles.color"
        />
      </div>

      <div class="form-group col">
        <label for="font">Select font family:</label>
        <select
          name="font"
          [style.fontFamily]="outerOptions.selectedText.styles.fontFamily"
          id="font"
          class="form-control"
          [(ngModel)]="outerOptions.selectedText.styles.fontFamily"
        >
          <option
            [style.fontFamily]="i"
            [value]="i"
            *ngFor="let i of outerOptions.fontFamilies"
          >
            {{ i }}
          </option>
        </select>
      </div>
      <div class="col form-group d-flex align-items-end pr-0">
        <button
          mat-stroked-button
          class="w-100"
          color="primary"
          (click)="addNewTxt()"
        >
          {{ outerOptions.selectedTextIndex != null ? "Done" : "Add" }}
        </button>
        <button
          mat-icon-button
          class="ml-3"
          *ngIf="outerOptions.selectedTextIndex !== null"
          (click)="deleteSelectText()"
        >
          <mat-icon>delete</mat-icon>
        </button>
      </div>
    </form> -->
  </div>
  <div class="content" *ngIf="!data.isPreviewFirstTime">
    <!-- !campaign?.finalTemplate &&  -->
    <table>
      <tbody>
        <tr>
          <td class="td1">
            <div class="margin-bottom">
              <div class="row">
                <div class="col-12 text-center font-14">Card Preview Image</div>
              </div>
              <div class="cropper-outer">
                <div
                  class="card-page cardp1"
                  [style.width]="cardDimensions.width"
                  [style.height]="cardDimensions.height"
                  id="coverImg"
                  #container
                >
                  <div
                    #boundary
                    class="messages background-messages"
                    *ngIf="!isImageLoading"
                    [style.background-image]="'url(' + base64 + ')'"
                  >
                    <div
                      class="message"
                      (click)="onClick($event)"
                      #txtElement
                      [inBounds]="true"
                      (mousedown)="selectText(i)"
                      [class.selected]="i === outerOptions?.selectedTextIndex"
                      ngDraggable
                      ngResizable
                      [bounds]="boundary"
                      *ngFor="let item of outerOptions?.textArry; let i = index"
                    >
                      <span
                        [style.fontSize]="item.styles.fontSize + 'px'"
                        [ngStyle]="item.styles"
                      >
                        {{ item.txt }}
                      </span>
                    </div>
                  </div>
                  <div
                    *ngIf="isImageLoading"
                    class="h-100 d-flex align-items-center justify-content-center"
                  >
                    <mat-spinner
                      [strokeWidth]="2"
                      [mode]="'indeterminate'"
                    ></mat-spinner>
                  </div>
                </div>
              </div>
            </div>
          </td>
          <td class="td2">
            <div>
              <div class="justify-content-center d-flex">
                <div class="top-width row">
                  <div class="col font-14 text-center">
                    Page No. : {{ selectedPageIndex + 1 }}
                  </div>
                </div>
              </div>
              <div class="card-outer">
                <div class="d-flex align-items-center chevron">
                  <button
                    class="bg-white mr-3"
                    mat-icon-button
                    [disabled]="selectedPageIndex <= 0"
                    (click)="previousPage()"
                  >
                    <mat-icon>keyboard_arrow_left</mat-icon>
                  </button>
                </div>
                <div
                  class="card-page cardp1"
                  id="editer"
                  [style.width]="cardDimensions.width"
                  [style.height]="cardDimensions.height"
                >
                  <div class="p-2 h-100 bg-white">
                    <div
                      class="messages"
                      #boundary
                      *ngIf="wishesPerPage?.length"
                    >
                      <!-- contributions?.items?.length &&  -->
                      <div
                        class="message"
                        (click)="onClick($event)"
                        [inBounds]="true"
                        [position]="item.postion"
                        (endOffset)="onDropped($event, item)"
                        (mousedown)="select(item)"
                        [ngStyle]="item"
                        [class.selected]="
                          item.contributionId === contributions?.selected?.id
                        "
                        ngDraggable
                        ngResizable
                        [bounds]="boundary"
                        *ngFor="let item of wishesPerPage"
                      >
                        {{ item.message }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="d-flex align-items-center chevron">
                  <button
                    class="bg-white ml-3"
                    mat-icon-button
                    [disabled]="selectedPageIndex >= cardPages.length - 1"
                    (click)="nextPage()"
                  >
                    <mat-icon>keyboard_arrow_right</mat-icon>
                  </button>
                </div>
              </div>
              <div class="bottom-width">
                You are able to rearrange the wishes by dragging & dropping them
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <div
    class="font18 p-3 d-flex justify-content-center"
    *ngIf="data.isPreviewFirstTime && !data?.campaign?.isAmountProcessed"
  >
    <!-- campaign?.finalTemplate ||  -->
    <!-- <a class="skyblue a" (click)="createCard()">Click here to rearrange the wishes on the card, if you wish</a> -->
    <a class="skyblue a wishesBtn" (click)="createCard()">Rearrange wishes</a>
  </div>

  <app-campaign-digital-card
    *ngIf="data.isPreviewFirstTime"
    [campaign]="campaign"
  >
    <!-- campaign?.finalTemplate ||  -->
  </app-campaign-digital-card>

  <footer
    class="d-flex justify-content-end border-top p-3"
    *ngIf="!data.isPreviewFirstTime"
  >
    <!-- !campaign?.finalTemplate &&  -->
    <button
      matRipple
      size="lg"
      color="c1"
      [loading]="isLoading"
      [disabled]="isLoading || isImageLoading"
      (click)="create()"
      ButtonUi
    >
      DONE
    </button>
  </footer>
</div>
