export class BankAccount {
    id?: number;
    accountHolderName?: string;
    accountNumber?: string;
    country?: string;
    currency?: string;
    // bankName?: string;
    isLoading?:boolean;
    sortCode?:string;
    // address?:string;
    // city: string;
    // postalCode?:string;
}
