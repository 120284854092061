import { Directive, ElementRef, Input, OnInit, HostListener, OnChanges, SimpleChanges } from '@angular/core';

@Directive({
  selector: '[ButtonUi]'
})
export class ButtonDirective implements OnInit, OnChanges {

  button: HTMLButtonElement;
  spinner: HTMLSpanElement;
  @Input() color: string;
  @Input() loading: boolean = false;
  @Input() disabled: boolean;
  @Input() outline: 'true' | 'false';
  @Input() size: 'sm' | 'lg' = 'sm';


  constructor(
    private el: ElementRef<HTMLButtonElement>
  ) {

    this.button = el.nativeElement;
    let style = this.button.style;
    style.lineHeight = '1rem';
    style.borderRadius = '3px';
    style.padding = '0.6rem 1rem';
    style.border = '1px solid rgba(0,0,0,.12)';
    style.outline = 'none';
    style.whiteSpace = "nowrap";
    style.transition = 'all .20s ease';
    style.backgroundColor = '#ffffff00';
    style.position = 'relative';
    style.fontFamily = "gotham";
    style.letterSpacing = '1px';
    style.fontWeight = "500";
    style.display = 'inline-flex';
    style.justifyContent = 'center';
    style.alignItems = 'center';
    this.createSpinner();

  }

  ngOnInit() {
    this.button.style.lineHeight = this.size === 'lg' ? '2rem' : '1rem';
    this.button.style.minWidth = this.size === 'lg' ? '100px' : 'auto';
    this.button.style.fontWeight = this.size === 'lg' ? '600' : 'normal';

    if (this.color && !this.outline) {
      this.button.style.backgroundColor = this.getColor(this.color);
      this.button.style.color = 'white';
      this.spinner.style.borderColor = '#ffffff73';
      this.spinner.style.borderLeftColor = 'white';
      this.button.style.borderColor = '#ffffff00';
    }

    if (this.color && this.outline == 'false') {
      this.button.style.color = this.getColor(this.color);
      this.spinner.style.borderColor = 'rgba(174, 173, 173, 0.45)';
      this.spinner.style.borderLeftColor = this.getColor(this.color);
      this.button.style.borderColor = 'white';
    }

    if (this.color && this.outline == 'true') {
      this.spinner.style.borderColor = 'rgba(174, 173, 173, 0.45)';
      this.button.style.backgroundColor = 'white';
      this.button.style.color = this.getColor(this.color);
      this.spinner.style.borderLeftColor = this.getColor(this.color);
      this.button.style.borderColor = this.getColor(this.color);
    }

  }

  ngOnChanges(change: SimpleChanges) {

    // console.log(change);
    if (change['loading'] && change['loading'].currentValue) {

      this.spinner.style.visibility = 'visible';
      this.button.disabled = true;
      this.button.style.opacity = '.6';
    } else if (change['disabled'] && change['disabled'].currentValue) {
      this.button.disabled = true;
      this.button.style.opacity = '.6';
    } else {
      this.spinner.style.visibility = 'hidden';
      this.button.disabled = false;
      this.button.style.opacity = '1';
    }

  }

  createSpinner() {
    this.spinner = document.createElement('span');
    this.spinner.style.height = '18px';
    this.spinner.style.width = '18px';
    this.spinner.style.border = '2px solid transparent';
    this.spinner.style.borderRadius = '50%';
    this.spinner.style.display = 'inline-block';
    this.spinner.style.position = 'absolute';
    this.spinner.style.right = '15px';
    this.spinner.classList.add('spin');
    this.spinner.style.visibility = 'hidden';
    this.button.appendChild(this.spinner);
  }

  getColor(type: string) {
    switch (type) {
      case "c1":
        return '#f0734c';
      case "c2":
        return '#0079BE';
      case "c3":
        return '#2D9CDB';
      case "c4":
        return '#FF9F52';
      case "c5":
        return '#797979';
      case "c6":
        return '#92D050';
      case "w":
        return 'white';
    }
  }

  @HostListener('mouseover', ['$event']) hover(event) {
    this.highLight(true);
  }

  @HostListener('mouseleave', ['$event']) leave(event) {
    this.highLight(false);
  }

  @HostListener('focus', ['$event']) focus(event) {
    this.highLight(true);
  }

  @HostListener('blur', ['$event']) blur(event) {
    this.highLight(false);
  }

  highLight(v: boolean) {
    if (v) {
      if (this.outline) {
        this.button.style.background = this.getColor(this.color);
        this.button.style.color = 'white';
      } else {
        this.button.style.filter = 'brightness(0.95)';
      }
    } else {
      if (this.outline) {
        this.button.style.background = 'white';
        this.button.style.color = this.getColor(this.color);
      } else {
        this.button.style.filter = 'brightness(1)';
      }
    }
  }

}
