import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Campaign } from 'src/app/models/campaign.model';

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss']
})
export class SideNavComponent implements OnInit {

  @Input() form:FormGroup = new FormGroup({});
  @Input() disableNavigation:boolean = false;
  @Input() campaign:Campaign = new Campaign();
  @Output() tabChange:EventEmitter<number> = new EventEmitter();

  @Input() tabs:Tab[] = [];

  constructor(
    private _router:Router,
    private _activatedRoute:ActivatedRoute
  ) { }

  ngOnInit() {
  }

  active(link?:number){
    if(!this.isCompleted(0) || this.disableNavigation) return;
    this.tabChange.emit(link);
    
  }

  isCompleted(index: number) {
    let isCompleted = true;
    switch (index) {
      case 0:
        if(!this.campaign.id) return false;
        Object.keys(this.form.controls).forEach(k => {
          if (this.giftDecisionTypeOption.includes(k)) {
            if (this.form.get(k).invalid){
              isCompleted = false;
            }
          }
        })
        return isCompleted;
      case 1:
        if(!this.campaign.id) return false;
        if(this.campaign.uniqueCode) return true;
        return  false;
      case 2:
        Object.keys(this.form.controls).forEach(k => {
          if (this.sectionThridOptions.includes(k)) {
            if (this.form.get(k).invalid) isCompleted = false;
          }
        })
        return isCompleted;
    }
  }

  get date():Date{
    return new Date();
  }

  get progress() {
    let progress = Object.keys(this.form.controls).filter(k => !this.form.get(k).invalid).length;
    return (progress / Object.keys(this.form.controls).length) * 100;
  }

  get giftDecisionTypeOption(){
    let common = ["receipientName","purposeOfGift","categoryId", "message", "collectionDeadline", "sendEmailReminders", "addStoryFromContributors", "giftDecisionType","campaignCurrency"];
    switch(this.form.get('giftDecisionType').value){
     case 'decided':
       return  ["giftLink", "maxContributionAmountInTotal",...common];
     case 'suggestionPoll':
       return ["giftSuggestions",...common];
     case 'suggestionFromContributors' :
       return common
      //  return [  "receipientGender", "receipientAge", 'particularInterests',...common];
       default:
        return common;
    }
  }

  get sectionThridOptions(){
    return ["giftLink","purchaseCard","selectedTemplateUrl","giftAmount","redeemOrDonate","receipientEmail","accountHolderName","accountNumber","ifscCode","branchName"]
  }

}

class Tab{
  img:string;
  title:string;
  isActive:boolean;
}
