<div class="flex-container">
  <h3 class="title">{{ data?.name }}</h3>
  <button class="close-button" (click)="dialogRef.close()">X</button>
</div>
<div class="info-container">
  <img width="250" height="150" src="{{ data?.image_thumb_url }}" alt="" />
  <div class="text-box">
    <details>
      <summary (click)="toggleSection('section1')">Description</summary>
      <blockquote *ngIf="isOpen.section1">{{ data?.description }}</blockquote>
    </details>
    <details>
      <summary (click)="toggleSection('section2')">Terms & Conditions</summary>
      <blockquote
        *ngIf="isOpen.section2"
        [innerHTML]="data?.terms_and_conditions"
      >
        Terms & Conditions
      </blockquote>
    </details>
    <details>
      <summary (click)="toggleSection('section3')">Redeem Instructions</summary>
      <blockquote
        *ngIf="isOpen.section3"
        [innerHTML]="data?.redeem_instructions"
      >
        Redeem Instructions
      </blockquote>
    </details>
    <details>
      <summary (click)="toggleSection('section4')">Disclaimer</summary>
      <blockquote *ngIf="isOpen.section4">{{ data?.disclaimer }}</blockquote>
    </details>
  </div>
</div>
<div class="flex-table">
  <table>
    <tr>
      <th>Product ID</th>
      <td align="end">{{ data?.product_id }}</td>
    </tr>
    <tr>
      <th>Currency</th>
      <td align="end">{{ data?.currency_code }}</td>
    </tr>
    <tr>
      <th>Countries</th>
      <td align="end">
        {{ data?.countries }}
      </td>
    </tr>
  </table>
  <table>
    <tr>
      <th>Type</th>
      <td align="end">{{ isTypeVariable() }}</td>
    </tr>

    <tr>
      <th>Product Code</th>
      <td align="end">{{ data?.items[0]?.code }}</td>
    </tr>

    <tr>
      <th>Value</th>
      <td align="end">
        {{ appService.getCurrencySymbol(data?.currency_code) }}
        {{ data?.min_value }}
        -
        {{ appService.getCurrencySymbol(data?.currency_code) }}
        {{ data?.max_value }}
      </td>
    </tr>
  </table>
</div>
