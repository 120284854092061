import { Component, ViewChild, AfterViewInit, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppService } from './services/app.service';
import * as AOS from 'aos';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})

export class AppComponent implements OnInit {

  isPasswordMatch: boolean = true;
  @ViewChild('password') passwordTemp: any;

  enteredPassword: string = '';
  readonly password: string = '0987654321';

  constructor(private _router: Router, private _appService: AppService) {
    if (!localStorage.getItem('token')) {
      const pat1 = /\/pages\/contribution\/[a-zA-Z0-9]+/g;
      const pat2 = /\/pages\/contribution\/send-message\/[a-zA-Z0-9]+\/\d+/g;

      let link1 = window.location.href.match(pat1);
      let link2 = window.location.href.match(pat2);
      if (link2) {
        const l = window.location.href.replace(location.origin + '/pages', '');
        const [link, qp] = l.split('?');
        const queryParams = {};
        qp.split('&').forEach((item) => {
          const [key, value] = item.split('=');
          queryParams[key] = value;
        })
        this._router.navigate([link], {
          queryParams,
        });
      } else if (link1) {
        this._router.navigate(['/home'], {
          queryParams: { redirectTo: link1[0] },
        });
      }
    }
  }

  ngOnInit() {
    AOS.init({
      duration: 1200,
    });
  }

  matchPassword() {
    if (this.enteredPassword !== this.password) {
      return this._appService.snackBarOpen('Invalid password.');
    }

    this.isPasswordMatch = true;
    this._appService.matDialog.closeAll();
  }
}
