<div class="outer">
  <div class="header">
    <img
      src="assets/logos/CollectaGift-WhiteTextOnly.png"
      routerLink="/home"
      style="outline: none"
      alt=""
    />
  </div>
  <nav class="side-nav">
    <ul>
      <li
        *ngFor="let item of links; let i = index"
        routerLink="{{ item?.link }}"
        routerLinkActive="active"
      >
        <div>
          <img
            class="icon normal"
            src="assets/icons/{{ item.img }}.png"
            alt=""
          />
          <img
            class="icon active"
            src="assets/icons/{{ item.activeImg }}.png"
            alt=""
          />
          <span class="navtitle">{{ item.title }}</span>
        </div>
      </li>
    </ul>
    <h6 class="mt-auto">
      Have a question?
      <a routerLink="/home" class="text-white" [fragment]="'contact'">
        CONTACT US
      </a>
    </h6>
  </nav>
  <footer>
    <h6>
      <!-- <p class="mb-0" >copyright © Collect A Gift 2020</p> -->
      <p>Copyright © CollectaGift 2022 All Rights Reserved</p>
    </h6>
  </footer>
</div>
