<div>

  <header>
    <h4 class="m-0" >
      Change Password
    </h4>
    <button class="ml-auto" type="button" mat-icon-button (click)="dialogRef.close()" >
      <mat-icon>clear</mat-icon>
    </button>
  </header>

  <form [formGroup]="form">

    <mat-form-field class="w-100" >
      <input matInput placeholder="Old Password" [type]="visibilityPassword.oldPassword ? 'text' : 'password'" formControlName="oldPassword" >
      <button mat-icon-button matSuffix type="button" (click)="visibilityPasswordToggle('oldPassword')" >
        <mat-icon *ngIf="visibilityPassword?.oldPassword" >visibility_off</mat-icon>
        <mat-icon *ngIf="!visibilityPassword?.oldPassword" >visibility</mat-icon>
      </button>
      <mat-error *ngIf="form.get('oldPassword').hasError('required')">
        Old password is required.
      </mat-error>
      <mat-error *ngIf="form.get('oldPassword').hasError('invalid')">
        Old password is invalid.
      </mat-error>
   
    </mat-form-field>

    <mat-form-field class="w-100" >
      <input matInput placeholder="New Password" [type]="visibilityPassword.newPassword ? 'text' : 'password'" formControlName="newPassword" >
      <button mat-icon-button matSuffix type="button" (click)="visibilityPasswordToggle('newPassword')" >
        <mat-icon *ngIf="visibilityPassword?.newPassword" >visibility_off</mat-icon>
        <mat-icon *ngIf="!visibilityPassword?.newPassword" >visibility</mat-icon>
      </button>
      <!-- <mat-error>
        
      </mat-error> -->
      <!-- <mat-error *ngIf="form.get('newPassword')?.hasError('minlength')">
        New password must be at least 6 characters long.
      </mat-error> -->
    <control-messages hidden #newPasswordError [control]="form.get('newPassword')" ></control-messages>

      <mat-error *ngIf="newPasswordError.errorMessage">
        {{newPasswordError.errorMessage}}
      </mat-error>
    </mat-form-field>


    <mat-form-field class="w-100" >
      <input matInput placeholder="Confirm Password" [type]="visibilityPassword.confirmPassword ? 'text' : 'password'" formControlName="confirmPassword" >
      <button mat-icon-button matSuffix type="button" (click)="visibilityPasswordToggle('confirmPassword')" >
        <mat-icon *ngIf="visibilityPassword?.confirmPassword" >visibility_off</mat-icon>
        <mat-icon *ngIf="!visibilityPassword?.confirmPassword" >visibility</mat-icon>
      </button>
      <control-messages hidden #confirmPasswordError [control]="form.get('confirmPassword')" ></control-messages>

      
      <mat-error *ngIf="confirmPasswordError.errorMessage">
        {{confirmPasswordError.errorMessage}}
      </mat-error>
    </mat-form-field>

    <button type="submit" (click)="updatePassword()" [disabled]="isLoading || form.invalid" [loading]="isLoading" matRipple color="c1" ButtonUi size="lg" class="w-100" >
      Done
    </button>

  </form>

</div>