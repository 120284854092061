import { Component, OnInit, ViewChild, AfterViewInit } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { AppService } from "src/app/services/app.service";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { CampaignService } from "src/app/services/campaign.service";
import { Campaign } from "src/app/models/campaign.model";
import { MatHorizontalStepper } from "@angular/material/stepper";
import { ValidationService } from "../../controls-msg/validation.service";
const LINK_PATTERN = new RegExp(
  "^(https?:\\/\\/)?" + // protocol
    "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
    "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
    "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
    "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
    "(\\#[-a-z\\d_]*)?$",
  "i"
);

const EMAIL_PATTERN = new RegExp(/[a-zA-Z0-9_-]+\@[a-zA-Z]+\.[a-zA-Z]+/i);

@Component({
  selector: "app-start-with-contribute",
  templateUrl: "./start-with-contribute.component.html",
  styleUrls: ["./start-with-contribute.component.scss"],
})
export class StartWithContributeComponent implements OnInit, AfterViewInit {
  form: FormGroup = new FormGroup({
    email: new FormControl("", [
      Validators.required,
      ValidationService.emailValidator
    ]),
    campaign: new FormGroup({
      uniqueCode: new FormControl(""),
      link: new FormControl("", Validators.pattern(LINK_PATTERN)),
    }),
  });

  isLoading: boolean;
  link: string;

  constructor(
    public dialogRef: MatDialogRef<StartWithContributeComponent>,
    public appService: AppService,
    private _router: Router,
    private _campaignService: CampaignService,
    private _activatedRoute: ActivatedRoute
  ) {
    this.link = new URLSearchParams(window.location.search).get("redirectTo");
    if (this.link) {
      this.form
        .get(["campaign", "link"])
        .setValue(window.location.origin + this.link);
    }

    // if (appService.user) {
    this.form.get("email").disable();
    // }

    this.form.get(["campaign", "uniqueCode"]).valueChanges.subscribe((d) => {
      if (d) this.form.get(["campaign", "link"]).setValue("");
    });

    this.form.get(["campaign", "link"]).valueChanges.subscribe((d) => {
      if (d) this.form.get(["campaign", "uniqueCode"]).setValue("");
    });
  }

  ngOnInit() {}

  ngAfterViewInit() {}

  async submit() {
    this.isLoading = true;

    if (
      this.link ||
      (this.form.get(["campaign", "link"]).valid &&
        this.form.get(["campaign", "link"]).value)
    ) {
      let linkAry = this.form.get(["campaign", "link"]).value.split("/");
      let campaignId = linkAry[linkAry.length - 1];
      let campaign = await this._campaignService
        .getByInviteLink(campaignId)
        .catch((e) => {
          this.isLoading = false;
          this.form.get(["campaign", "link"]).setErrors({ invalid: true });
          this.appService.snackBarOpen("Invalid link address");
        });
      if (campaign) {
        return this.navigate(campaign);
      }
    } else if (this.form.get(["campaign", "uniqueCode"]).value) {
      this._campaignService.campaigns
        .create({ ...this.form.get("campaign").value }, "enter/code")
        .then((d) => {
          this.navigate(d);
        })
        .catch((err) => {
          this.isLoading = false;
          this.form
            .get(["campaign", "uniqueCode"])
            .setErrors({ invalid: true });
          this.appService.snackBarOpen("Invalid unique code");
        });
    }
  }

  navigate(c?: Campaign) {
    this.dialogRef.close();
    if (!this.appService.user) {
      // this.appService.snackBarOpen(
      //   "To contribute, please login or register as a new user."
      // );
      this._router.navigate([
        "contribution",
        c.inviteLink.split("/")[c.inviteLink.split("/").length - 1],
      ]);
      return
      // return this._router.navigate(["auth/sign-up"], {
      //   queryParams: {
      //     redirectTo: `${
      //       c.inviteLink.match(/\/pages\/contribution\/[a-zA-Z0-9]+/g)[0]
      //     }`,
      //   },
      // });
    }
    this._router.navigate([
      "pages",
      "contribution",
      c.inviteLink.split("/")[c.inviteLink.split("/").length - 1],
    ]);
  }
}
