<input type="search" [placeholder]="label" autocomplete="new-password" *ngIf="type === 'normal'" class="form-control" [matAutocomplete]="countriesAutoComplete" [formControl]="fullDetail">
  <mat-form-field [appearance]="'outline'" *ngIf="type !== 'normal'"  >
    <mat-label>{{label}}</mat-label>
    <input type="text" matInput [placeholder]="label" name="dfgdgd" [matAutocomplete]="countriesAutoComplete" [formControl]="fullDetail" >
  </mat-form-field>
<mat-autocomplete #countriesAutoComplete [displayWith]="inputValueDisplay">
  <mat-option [value]="item" *ngFor="let item of filterCountries;let i = index;">
    <img [src]="item?.flag" width="25px" class="mr-3" alt="">
    {{optionValueDisplay(item)}}
  </mat-option>
</mat-autocomplete>