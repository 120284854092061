import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppService } from 'src/app/services/app.service';

@Component({
  selector: 'app-success-message-dialog',
  templateUrl: './success-message-dialog.component.html',
  styleUrls: ['./success-message-dialog.component.scss'],
})
export class SuccessMessageDialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<SuccessMessageDialogComponent>,
    public appService: AppService,
    @Inject(MAT_DIALOG_DATA) public data: { title: string }
  ) {
    dialogRef.disableClose = true;
    dialogRef.addPanelClass('success-message-dialog');
  }

  ngOnInit() {}
}
