import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Campaign } from 'src/app/models/campaign.model';
import { ProdigiProduct } from 'src/app/models/prodigiProduct.model';
import { ProdigiService } from 'src/app/services/prodigi.service';

@Component({
  selector: 'app-select-prodigi-product',
  templateUrl: './select-prodigi-product.component.html',
  styleUrls: ['./select-prodigi-product.component.scss']
})
export class SelectProdigiProductComponent implements OnChanges {

  @Input() campaign: Campaign

  isLoading: boolean
  prodigiProducts: ProdigiProduct[] = [];
  @Input() productSku: string
  @Output() productSkuChange: EventEmitter<string> = new EventEmitter<string>();
  @Input() disabled: boolean = false

  detail: ProdigiProduct = null;

  constructor(
    private _prodigiService: ProdigiService,
  ) {
    this.fetch()
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['productSku']) {
      this.getDetail();
    }
  }

  async fetch() {
    this.isLoading = true;
    this.prodigiProducts = await this._prodigiService.allProducts();
    this.isLoading = false;
  }

  get selected(): ProdigiProduct {
    return this.prodigiProducts.find(i => i.sku === this.productSku)
  }


  async getDetail() {
    this.detail = null;
    this.detail = await this._prodigiService.productDetail(this.productSku);
  }

}
