<div class="container-fluid">
    <div class="row">
        <div class="col">
            <h2>
                PAY OUT
            </h2>

            <mat-card class="mb-4">
                <table class="table">
                    <thead>
                        <tr>
                            <th>Date</th>
                            <th>Amount</th>
                            <th>Status</th>
                            <th>Remark</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of campaigns?.items;let i = index;">
                            <td>{{(item?.collectionDeadline | date:'dd, MMM yyyy') || "N/a"}}</td>
                            <td>{{item.tremendousOrderAmount ||(item?.userAmount - item?.stripeCharges || 0) |
                                currency:item.campaignCurrency}}</td>
                            <td><span class="status"
                                    [ngClass]="item?.isAmountProcessed ? 'paid' : 'pending'">{{item?.isAmountProcessed ?
                                    'Processed' : 'Pending'}}</span></td>
                            <td>{{(item?.purposeOfGift || item?.category?.name || "N/a") | titlecase}}</td>
                            <td class="py-2">
                                <!-- [ngClass]="item?.isAmountProcessed ? 'p-0' : 'py-2'" -->
                                <button ButtonUi color="c1" *ngIf="!item?.isAmountProcessed"  (click)="payout(i)">
                                    Payout
                                </button>
                                <div *ngIf="item?.isAmountProcessed">
                                    <span class="status paid py-2">Paid out</span>
                                </div>
                                <!-- <button ButtonUi color="c5" *ngIf="item?.isAmountProcessed">
                                    Paid out
                                </button> -->
                                <!-- <button mat-icon-button *ngIf="item?.isAmountProcessed">
                                    <img src="assets/icons/Download.png" alt="">
                                </button> -->
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div class="d-flex my-3" *ngIf="campaigns?.isLoading">
                    <mat-progress-spinner class="m-auto" [strokeWidth]="2" [diameter]="40" [mode]="'indeterminate'">
                    </mat-progress-spinner>
                </div>
                <div *ngIf="!campaigns.isLoading && !campaigns.items.length">
                    <div class="m-auto text-center">
                        <img src="https://img.icons8.com/bubbles/200/000000/image-file.png" />
                        <h6>no campaigns available</h6>
                    </div>
                </div>
                <hr>
                <mat-paginator [pageSize]="campaigns.pageSize" [pageSizeOptions]="[5,10,25,50,100]"
                    [length]="campaigns.totalRecords" [pageIndex]="campaigns.pageNo - 1"
                    (page)="campaigns.pageNo = $event.pageIndex+1;campaigns.pageSize = $event.pageSize;fetch(true);">
                </mat-paginator>
            </mat-card>

        </div>
        <!-- <div class="col-4">   
            <app-bank-acount #bankAccounts></app-bank-acount>
        </div> -->
    </div>
</div>

 <!-- <div class="currency">
                <header class="d-flex" >
                    <h2>
                        CURRENCY
                    </h2>
                    <button mat-icon-button class="ml-auto">
                        <img src="assets/icons/Blue edit.png" alt="">
                    </button>
                </header>
                <mat-card >
                    <div >
                        <span>Country :</span>&nbsp;<strong>N/a</strong>
                    </div>
                    <div>
                        <span>Currency :</span>&nbsp;<strong>N/a</strong>
                    </div>
                </mat-card>

            </div> -->