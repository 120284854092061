<div class="container-fluid">
  <h3 class="headtitle">BUY GIFT AND CARD</h3>
  <form [formGroup]="form" autocapitalize="off">
    <mat-card class="mat-card p-4" *ngIf="campaign.status === 'active'">
      <div class="text-center">
        <img
          class="py-3"
          height="200px"
          src="assets/images/Congratulations.jpg"
          alt=""
        />
        <h4 class="text-mute">
          You're almost there! You've collected
          {{
            campaign?.collectedAmount
              | currency : campaign?.campaignCurrency || "GBP"
          }}
          so far for
          {{ this.form.get("receipientName")?.value || "N/a" }}'s gift.
          {{ campaignContent?.buyGiftCard?.description1 || "--" }}
          In case you missed it,
          <a href="/pages/contribution/{{ campaignId }}" target="_blank"
            >here is the link</a
          >
          again to the CollectaGift Pot. Please share this email and ask your
          friends, family, colleagues to contribute. Don't worry, you'll also
          receive this link to your registered email address, so you can access
          it at any time.
          <!-- Come back here once the CollectaGift Pot has closed to redeem your gift amount. -->
        </h4>
      </div>

      <!-- <label class="info" *ngIf="!_appService?.user?.address">Please complete your KYC <a class="a"
          routerLink="/pages/detail/personal-information" [queryParams]="{kyc:'add'}">Here</a>
          <mat-icon
            tooltip="This is a one-time requirement for users the first time they create a CollectaGift Pot. It is a requirement of Stripe, our payment provider and is required to help eliminate fraud. ">
            info</mat-icon>
        </label> -->
    </mat-card>

    <mat-card
      class="mat-card p-4 faq"
      *ngIf="campaign.status === 'closed' || campaign.status == 'dateAchieved'"
    >
      <!-- isExprie -->
      <div class="text-center">
        <img
          class="py-3"
          height="200px"
          src="assets/images/Congratulations.jpg"
          alt=""
        />
        <h4 class="text-mute">
          <!-- Congratulations, you have collected -->
          {{ campaignContent?.buyGiftCardPaid?.description1 || "" }}
          {{
            campaign?.collectedAmount
              | currency : campaign?.campaignCurrency || "GBP"
          }}
          for
          {{ this.form.get("receipientName")?.value }}
          <!-- Way to go, you’ve collected {{(campaign?.collectedAmount | currency:(campaign?.campaignCurrency|| "GBP"))}}
          for
          {{this.form.get('receipientName')?.value}}. -->
        </h4>
      </div>
      <!--
      <label class="info" *ngIf="!_appService?.user?.address">Please complete your KYC <a class="a"
          routerLink="/pages/detail/personal-information" [queryParams]="{kyc:'add'}">Here</a>
          <mat-icon
          tooltip="This is a one-time requirement for users the first time they create a CollectaGift Pot. It is a requirement of Stripe, our payment provider and is required to help eliminate fraud. ">
          info</mat-icon>
        </label> -->

      <div class="form-group">
        <label class="info">
          <!-- 1. {{ campaignContent?.buyGiftCardPaid?.question1 || "" }} -->
          1. Which card option would you like?
          <mat-icon
            tooltip="The message wall is free, otherwise you can
          choose to purchase an e-card.  If you have enjoyed using this
          service, we would ask you to consider purchasing an e-card option
          as we do not generate any other income as part of this service."
          >
            info
          </mat-icon>
          <!-- Would you like to purchase a card, which will collate all messages
          from the contributors? -->
          <!-- Would you like to purchase an e-card, which will collate all messages from the contributors at a cost of
          {{cardPrice | currency:(campaign?.campaignCurrency|| "GBP")}}? -->
        </label>

        <!-- <div class="float-right">
          <img src="assets/images/messages.png" alt="">
          <a class="skyblue a" >"Contributor's Message"</a>
        </div> -->
      </div>

      <!-- new changes -->

      <!-- <div class="form-group">
        <mat-radio-group class="orange" formControlName="purchaseCard" (ngModelChange)="purchaseCardChnage()">
          <mat-radio-button [value]="true">Yes</mat-radio-button>
          <mat-radio-button [value]="false" class="mx-5">No</mat-radio-button>
        </mat-radio-group>
      </div> -->

      <!-- new changes -->

      <div class="my-3" *ngIf="form.get('purchaseCard')?.value">
        <!-- <div class="form-group">
          <label> Please select your Card Type. </label> <br />
          <mat-radio-group class="orange" formControlName="cardType">
            <mat-radio-button value="prodigi">
              <label class="info h-italic mb-0">
                Physical Greeting Card
                <mat-icon
                  tooltip="Select this option if you would like to post a hard copy greeting card directly to the recipient."
                >
                  info
                </mat-icon>
              </label>
            </mat-radio-button>
            <mat-radio-button value="digital" class="mx-5">
              <label class="info h-italic mb-0">
                E-Card
                <mat-icon
                  tooltip="Select this option if you would like to send the recipient a digital card directly into their inbox or you would like the option to print at home."
                >
                  info
                </mat-icon>
              </label>
            </mat-radio-button>
          </mat-radio-group>
        </div> -->

        <div class="form-group d-flex">
          <mat-radio-group
            class="orange"
            (ngModelChange)="onTemplateTypeChange($event)"
            formControlName="templateType"
          >
            <mat-radio-button value="uploaded" class="faq-div">
              <label class="info h-italic mb-0">
                {{ campaignContent?.buyGiftCardPaid?.question1IfYesA || "" }}
                (£4)
              </label>
            </mat-radio-button>
            <mat-radio-button value="selected" class="ml-4 mr-2 faq-div">
              <label class="info h-italic mb-0 faq-div1">
                {{ campaignContent?.buyGiftCardPaid?.question1IfYesB || "" }}
                (From £3)
              </label>
            </mat-radio-button>
            <mat-radio-button value="freeCard" class="ml-4 mr-0 faq-div">
              <label class="info h-italic mb-0 faq-div1">
                <!-- Download message wall (Free) -->
                Free Greeting Card (£0)
                <mat-icon
                  class="question"
                  tooltip="The front cover design of the Free Greeting Card will be the CollectaGift logo.  All messages will be visible inside."
                  >info</mat-icon
                >
              </label>
            </mat-radio-button>
          </mat-radio-group>
        </div>

        <div class="row">
          <div
            class="col-12 col-lg-4"
            *ngIf="form?.get('templateType').value === 'selected'"
          >
            <div>
              <label class="h-italic"
                >Please select a card cover for the card</label
              >
            </div>
            <div class="mb-4 p-0">
              <select
                class="form-control"
                [ngModelOptions]="{ standalone: true }"
                (change)="fetchTemplate()"
                [(ngModel)]="templates.filters.properties['categoryId'].value"
              >
                <option
                  *ngFor="let item of categories?.items"
                  [value]="item?.id"
                >
                  {{ item?.name }}
                </option>
              </select>
            </div>
          </div>
        </div>

        <div class="mb-3">
          <div
            class="d-flex digitalCardimg"
            *ngIf="form?.get('templateType').value === 'uploaded'"
          >
            <div class="temp-outer pr-4 mb-4">
              <div
                (click)="
                  this.campaign?.isAmountProcessed ? null : createCollage()
                "
              >
                <img
                  height="155px"
                  src="assets/images/makeCoverBtn.svg"
                  alt=""
                />
              </div>
            </div>
            <div class="temp-outer pr-4 mb-4">
              <div
                class="template border-0"
                style="width: auto; cursor: pointer"
                *ngIf="form.get('selectedTemplateUrl')?.value"
              >
                <img
                  [src]="form.get('selectedTemplateUrl')?.value"
                  height="155px"
                  alt=""
                />
              </div>
            </div>
          </div>

          <div
            class="d-flex digitalCardimg"
            *ngIf="form?.get('templateType').value === 'selected'"
          >
            <div class="temp-outer p-3 m-auto" *ngIf="templates?.isLoading">
              <mat-spinner [strokeWidth]="1" [diameter]="50"></mat-spinner>
            </div>
            <ng-container *ngFor="let item of templates.items">
              <div
                class="temp-outer pr-4 mb-4"
                (click)="
                  this.campaign?.isAmountProcessed ||
                  item?.price > campaign?.collectedAmount
                    ? null
                    : onSelectTemplate(false, item.url, item?.price)
                "
              >
                <div
                  [ngClass]="
                    item?.price > campaign?.collectedAmount ? 'not-allowed' : ''
                  "
                  class="template hover"
                  [class.active]="
                    item?.url === form.get('selectedTemplateUrl')?.value
                  "
                  [style.background-image]="'url(' + item?.url + ')'"
                >
                  <div class="price">
                    {{
                      item?.price
                        | currency : campaign?.campaignCurrency || "GBP"
                    }}
                  </div>
                  <div class="triangle"></div>
                  <mat-icon>check</mat-icon>
                </div>
              </div>
            </ng-container>
          </div>

          <!-- <div class="alert alert-danger text-center m-auto" role="alert"
            *ngIf="!templates.isLoading && !templates?.items?.length">
            templates not found
          </div> -->
        </div>
        <!-- view all wishes-->

        <div class="mb-3">
          <a
            *ngIf="form?.get('templateType').value == 'freeCard'"
            class="skyblue a"
            (click)="openWishes(campaign.contributions)"
          >
            View all wishes
          </a>
        </div>
        <div class="mb-3" *ngIf="isAlignWishes">
          <!-- <a class="skyblue a" (click)="createCard()">{{
            !form.get("finalTemplate")?.value && !isPreviewFirstTime
            ? "Click here to rearrange the wishes on the card, if you wish"
            : "Preview Card here"
            }}</a> -->

          <a
            *ngIf="form?.get('templateType').value != 'freeCard'"
            class="skyblue a"
            (click)="createCard()"
            >Preview card here (with option to rearrange the wishes)
          </a>
        </div>

        <!-- *ngIf="form.get('finalTemplate')?.value" -->
        <div>
          <div class="form-group mb-4 faq-div">
            <label class="mb-3 info"
              >2. Would you like the digital card to be auto-emailed to the
              recipient, or would you like to receive the digital card link to
              manually send it to the recipient?
              <mat-icon
                class="question"
                tooltip="Regardless of which option you choose, the digital card can always be downloaded at any time from your dashboard and as the organiser, a copy of the digital card will also be sent to your registered email address."
                >info</mat-icon
              >
            </label>
            <br />
            <mat-radio-group
              class="orange text-mute faq-div"
              formControlName="automaticCardEmail"
            >
              <mat-radio-button [value]="'autoEmail'"
                >Auto-Email to Recipient</mat-radio-button
              >
              <mat-radio-button class="ml-4 faq-div" [value]="'manually'">
                <label for="" class="info maunally">
                  Manually email to Recipient
                  <mat-icon
                    class="question"
                    tooltip="If you have chosen the Free Message Wall option please click on “View all wishes” which gives you the option to download the wishes too."
                    >info</mat-icon
                  >
                </label>
              </mat-radio-button>
            </mat-radio-group>
          </div>

          <div *ngIf="form.get('automaticCardEmail')?.value === 'autoEmail'">
            <div class="form-group faq-div">
              <label class="info h-italic">
                Please enter the email address of the recipient of where the
                digital card should be sent?
                <mat-icon
                  tooltip="Card will be sent as an image which can be shared with anyone"
                >
                  info</mat-icon
                >
              </label>
              <input
                type="email"
                class="form-control w-50"
                placeholder="Recipients Email"
                placeholder="Email"
                formControlName="cardRecipientEmail"
              />
            </div>

            <div class="form-group mb-4">
              <label class="h-italic"
                >Please select a time and date for when the digital card should
                be delivered</label
              >
              <div class="d-flex">
                <div class="datepick mr-3">
                  <input
                    matInput
                    class="form-control"
                    [min]="currentDate"
                    formControlName="cardSendEmailDate"
                    [matDatepicker]="datePicker"
                    (click)="datePicker.open()"
                    (dateChange)="onDateChange($event)"
                  />
                  <!-- <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle> -->
                  <!-- <mat-datepicker #picker1></mat-datepicker> -->
                  <img
                    class="dateicon"
                    (click)="datePicker.open()"
                    src="assets/icons/ic_calender.svg"
                  />
                  <mat-datepicker #datePicker></mat-datepicker>
                </div>
                <div class="timepick">
                  <input
                    class="form-control"
                    formControlName="cardSendEmailTime"
                    [ngxTimepicker]="toggleIcon"
                    [min]="currentTime"
                  />
                  <ngx-material-timepicker-toggle [for]="toggleIcon">
                    <svg
                      class="timeicon"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 30 30"
                      width="20px"
                      height="20px"
                      ngxMaterialTimepickerToggleIcon
                    >
                      <path
                        d="M15,3C8.373,3,3,8.373,3,15c0,6.627,5.373,12,12,12s12-5.373,12-12C27,8.373,21.627,3,15,3z M16,16H7.995 C7.445,16,7,15.555,7,15.005v-0.011C7,14.445,7.445,14,7.995,14H14V5.995C14,5.445,14.445,5,14.995,5h0.011 C15.555,5,16,5.445,16,5.995V16z"
                      />
                    </svg>
                  </ngx-material-timepicker-toggle>
                  <ngx-material-timepicker
                    #toggleIcon
                  ></ngx-material-timepicker>
                </div>
                <label class="info d-flex mb-0">
                  <mat-icon
                    tooltip="Please note, that the earliest you can schedule a card to be sent is 30 minutes from now.  This will allow you to complete the payout."
                  >
                    info
                  </mat-icon>
                </label>
              </div>
            </div>
          </div>

          <div
            *ngIf="
              form.get('automaticCardEmail')?.value === 'manually' &&
              !form.get('finalTemplate')?.value &&
              form.get('templateType')?.value != 'freeCard' &&
              campaignId
            "
          >
            <a class="skyblue a" (click)="copyCardLink()">Copy card link</a>
          </div>
        </div>
      </div>

      <div class="form-group mb-4">
        <label class="mb-3">
          <!-- How would you like to redeem the funds? -->
          3. {{ campaignContent?.buyGiftCardPaid?.question2 || "" }}
        </label>
        <br />
        <mat-radio-group
          class="orange flex-how-to text-mute d-flex"
          formControlName="payoutType"
          #payoutType="matRadioGroup"
          value="catalogue"
        >
          <mat-radio-button
            class="faq-div"
            [value]="'catalogue'"
            (click)="form.get('giftAmount')?.setValue('')"
          >
            <label class="info h-italic mb-0">
              {{ campaignContent?.buyGiftCardPaid?.question2B || "" }}
              <!-- *ngIf="form.get('payoutType').value === 'catalogue'" -->

              <mat-icon
                tooltip="This option is recommended, as you can choose a gift voucher from hundreds of providers in our catalogue and you can either gift the voucher itself to the Recipient or use the voucher to buy a gift for the Recipient from the chosen provider."
              >
                info
              </mat-icon>
            </label>
          </mat-radio-button>

          <mat-radio-button [value]="'account'" class="ml-4 option-2">
            <label class="info h-italic mb-0">
              <!-- Payout funds to visa debit card -->
              {{ campaignContent?.buyGiftCardPaid?.question2A || "" }}
              <mat-icon
                tooltip="This option is only advised if you cannot find a Gift Card option in the Gift Catalogue or you would prefer to gift a monetary amount on a Prepaid Debit Card.  The Prepaid Debit Card can be spent anywhere the Prepaid Debit card is accepted.  However please note the Prepaid Debit Card will be denominated in USD.  Please refer to our FAQs for further information."
              >
                info
              </mat-icon>
            </label>
          </mat-radio-button>

          <a
            class="skyblue ml-3 link"
            (click)="
              $event.stopPropagation();
              $event.preventDefault();
              saveAndContinue(true)
            "
          >
            Browse Gift Catalogue here
          </a>
        </mat-radio-group>
      </div>

      <!-- <div class=" form-group w-50 mb-4">
            <label for="gift">What gift have you bought/will you buy for
              {{this.form.get('receipientName')?.value | titlecase}}?</label>
            <input type="text" class="form-control" placeholder="Gift link" id="gift" formControlName="giftLink">
          </div> -->

      <!-- <div class="form-group mb-4" *ngIf="form.get('giftDecisionType').value !== 'decided'">
        <label class="info">Do you know what gift you will buy?</label>
        <mat-radio-group class="orange" [value]="form.get('decidedGift').value || !!form.get('giftName').value"
          (change)="onDecidedGiftChange($event.value)" formControlName="decidedGift">
          <mat-radio-button [value]="true">Yes</mat-radio-button>
          <mat-radio-button [value]="false" class="mx-5">No</mat-radio-button>
        </mat-radio-group>
      </div> -->

      <!-- what gift did you will you pay commented -->

      <!-- <div class="form-group mb-4" *ngIf="form.get('giftDecisionType').value === 'decided'">
        <label class="info">
          What gift did you/will you buy? -->
      <!-- Did you/will you buy {{form.get('giftName').value}}? -->
      <!-- <mat-icon tooltip="When the payout has closed, there will be an option for you to send an email to all contributors
            advising them of the total raised, what gift was purchased and whether any money was donated to
            Smile Train, our nominated charity partner.">
            info</mat-icon>
        </label>
        <mat-radio-group class="orange" (change)="onDecidedGiftChange($event.value)" formControlName="isGiftBought">
          <mat-radio-button [value]="true">Yes</mat-radio-button>
          <mat-radio-button [value]="false" class="mx-5">No</mat-radio-button>
        </mat-radio-group>
      </div> -->

      <!-- what gift did you will you pay commented -->

      <!-- <div *ngIf="form.get('decidedGift').value"> -->
      <!-- <div *ngIf="form.get('giftDecisionType').value !== 'decided'">
        <div class="form-group">
          <label class="info h-italic">
            {{ campaignContent?.buyGiftCardPaid?.question3 || "" }}

            <mat-icon
              tooltip="Please enter the name or a brief description of the gift. Upon redeeming the CollectaGift proceeds, we will auto-email all recipients notifying them of the gift selected.">
              info
            </mat-icon>
          </label>
          <input type="text" class="form-control w-50" placeholder="Please provide a brief description of the gift"
            formControlName="giftName" />
        </div>
      </div> -->

      <!-- <div class="w-50 mb-4" *ngIf="form.get('payoutType').value === 'account'">
        <label for="amount" class="info h-italic">
          {{ campaignContent?.buyGiftCardPaid?.question2IfChooseA || "" }}
        </label>
        <div id="amount" class="form-group icon left">
          <input (input)="valueChanges($event.target.value)" type="text" maxlength="10" class="form-control"
            placeholder="Amount" formControlName="giftAmount" />
          <span class="text">{{
            _appService.getCurrencySymbol(campaign?.campaignCurrency)
            }}</span>
        </div>
      </div> -->

      <div
        *ngIf="
          !form.get('decidedGift').value &&
          form.get('payoutType').value === 'account'
        "
      >
        <!-- <div>
          <label>
            You will have {{remainingAmount | currency:(campaign?.campaignCurrency|| "GBP")}} to redeem. Would you
            like
            to redeem this full amount, <br> or would you like to become a CollectaGift Charity Champion and donate
            some
            of this to our partner charity
            <span *ngFor="let item of charities.items | slice:0:1;">{{item.name | uppercase}}?</span>
          </label>
          <div class="form-group pt-2">
            <mat-radio-group class="orange" formControlName="wantToDonate" (change)="onWantToDonate($event.value)">
              <mat-radio-button [value]="false">I would like to redeem the full amount</mat-radio-button>
              <mat-radio-button [value]="true" class="mx-5">I would like to become a CollectaGift Charity Champion
              </mat-radio-button>
            </mat-radio-group>
          </div>
        </div> -->

        <div *ngIf="remainingAmount > 0 && form.get('wantToDonate')?.value">
          <!-- <div>
            <label class="h-italic text-dark">
              Please select the option from below to Become a Charity Champion
            </label>
            <div class="form-group">
              <mat-radio-group class="orange" formControlName="charityAmountType"
                (change)='onCharityAmountTypeChange($event.value)'>
                <mat-radio-button value="autoFilled">I would like to donate
                  {{remainingAmount | currency:(campaign?.campaignCurrency|| "GBP")}} amount</mat-radio-button>
                <mat-radio-button value="other" class="mx-5">Other
                  <div class="form-group icon left d-inline-block ml-3 mb-0"
                    *ngIf="form.get('charityAmountType').value === 'other'">
                    <input type="text" maxlength="10" class="form-control" placeholder="Please enter amount here"
                      formControlName="charityAmount">
                    <span class="text">{{_appService.getCurrencySymbol(campaign?.campaignCurrency)}}</span>
                  </div>
                </mat-radio-button>
              </mat-radio-group>
            </div>
          </div> -->

          <!-- <div>
            <label for="email" class="mb-4">If you chose to generously donate the rest nominated, please enter the gift
              recipients email
              address below, so they receive an email notification notifying them of your mutual good deed.</label>
            <div class="form-group mb-4">
              <input id="email" type="email" class="form-control w-50" formControlName="receipientEmail"
                placeholder="Recipients Email">
            </div>
          </div> -->
        </div>
      </div>

      <!-- <div>
        <label class="h-italic text-dark">
          Add message for the Contributors.
        </label>


        <div class="form-group">
          <textarea class="form-control" formControlName="comment"
            placeholder="This is a free text field for you to send a message to all contributors.  You may want to thank them for their contribution, let them know how much was collected, what gift was bought and also whether any leftover cash was donated to our charity partner CLEFT"></textarea>
        </div>
      </div> -->

      <!-- <div *ngIf="form.get('payoutType').value === 'account'">
        <p *ngIf="payoutAmount >= 100">
          Total collectaGift amount in your pot is
          {{ payoutAmount | currency: campaign?.campaignCurrency || "GBP" }}.
          Our payment Stripe will charge
          {{ stripeCharges | currency: campaign?.campaignCurrency || "GBP" }}
          (£2.10 + 0.25%) for transferring this amount to your bank account.
          This transaction fees will be deducted from the collectaGift amount.
          Therefore, you will receive
          {{
            payoutAmount - stripeCharges
              | currency: campaign?.campaignCurrency || "GBP"
          }}
          in your bank account.
        </p>

        <p *ngIf="payoutAmount < 100">
          Total collectaGift amount in your pot is
          {{ payoutAmount | currency: campaign?.campaignCurrency || "GBP" }}.
          Our payment partner Stripe will not charge any transaction fees on
          this amount. Therefore, you will receive
          {{ payoutAmount | currency: campaign?.campaignCurrency || "GBP" }} in
          your bank account.
        </p>
      </div> -->

      <!-- <div>
        <label class="mb-4">Please enter the account details where you would like to pay the amount info:</label>
        <div class="row w-75 text-mute">
          <div class="col-6">
            <div class="form-group">
              <label for="name">Account Holder's Name</label>
              <input id="name" type="text" maxlength="100" class="form-control" formControlName="accountHolderName"
                placeholder="Name">
            </div>
          </div>

          <div class="col-6">
            <div class="form-group">
              <label for="accountNp">Account No.</label>
              <input id="accountNp" type="number" class="form-control" formControlName="accountNumber"
                placeholder="Account No.">
            </div>
          </div>

          <div class="col-6">
            <div class="form-group">
              <label for="code">IFSC Code</label>
              <input id="code" type="text" maxlength="100" class="form-control" formControlName="routingNumber"
                placeholder="IFSC Code">
            </div>
          </div>

          <div class="col-6">
            <div class="form-group">
              <label for="Branch">Branch Name</label>
              <input id="Branch" type="text" maxlength="100" class="form-control" placeholder="Branch Name"
                formControlName="bankName">
            </div>
          </div>
        </div>
      </div> -->

      <div
        class="alert alert-success d-flex col-lg-6"
        role="alert"
        *ngIf="campaign?.isAmountProcessed"
      >
        <mat-icon class="mr-3">check</mat-icon> Payment successfully processed
      </div>

      <!-- <div class="text-danger">
      *Fees are £4 for CollectaGift Pot up to £500 <br>
      or £6 for CollectaGift Pot up to £1000
     </div> -->
    </mat-card>
    <div
      class="actions"
      *ngIf="
        (isExpried || campaign.status !== 'active') &&
        !campaign?.isAmountProcessed
      "
      [ngSwitch]="form.get('payoutType').value"
    >
      <button
        mat-ripple
        *ngSwitchCase="'account'"
        class="mr-3 orange"
        [disabled]="!campaign?.collectedAmount"
        size="lg"
        type="button"
        ButtonUi
        color="c1"
        (click)="openCharityDialog('payout')"
      >
        <!-- {{buttonDisable}} -->
        <!-- {{campaign?.collectedAmount}} -->
        Get CollectaGift Payout
      </button>

      <button
        mat-ripple
        *ngSwitchCase="'catalogue'"
        class="mr-3 orange"
        [disabled]="
          (!campaign?.collectedAmount || buttonDisable) &&
          form.get('templateType')?.value != 'freeCard'
        "
        size="lg"
        type="button"
        ButtonUi
        color="c1"
        (click)="openCharityDialog()"
      >
        <!-- {{ buttonDisable }} -->
        Proceed to choose Gift
      </button>

      <!-- <button ButtonUi class="orange" color="c1" size="lg" [loading]="loading" [disabled]="loading || buttonDisable"
        (click)="update()">
        Save and go to dashboard
      </button> -->
    </div>
    <div
      class="actions"
      *ngIf="
        (!isExpried && campaign.status === 'active') ||
        campaign?.isAmountProcessed
      "
    >
      <button
        class="orange"
        ButtonUi
        color="c1"
        size="lg"
        routerLink="/pages/detail/campaigns"
      >
        go to dashboard
      </button>
    </div>
  </form>
</div>
<!-- <div class="p-3">
  purchaseCard : {{ form.get("purchaseCard").value }} <br />
  decidedGift : {{ form.get("decidedGift").value }} <br />
  isGiftBought : {{ form.get("isGiftBought").value }} <br />
  giftAmount: {{ form.get("giftAmount").value }} <br />
  remainingAmount: {{ remainingAmount }} <br />
  wantToDonate : {{ form.get("wantToDonate").value }} <br />
  charityAmount: {{ form.get("charityAmount").value }} <br />
  userAmount: {{ form.get("userAmount").value }} <br />
  cardAmount: {{ form.get("cardAmount").value }} <br />
</div> -->
