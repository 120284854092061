import { Directive, ElementRef, HostListener, Input, OnInit } from '@angular/core';
import { NgControl } from '@angular/forms';

const ONLY_CHAR = /[A-Za-z ]+/g;
const ONLY_NUMBER = /\d+/g;
const ONLY_CHAR_AND_NUMBER = /[0-9A-Za-z ]+/g;

@Directive({
  selector: '[validatorController]'
})
export class CharOnlyDirective implements OnInit {

 @Input('number') isNumber:boolean = false;
 @Input('char') isChar:boolean = false;
 @Input('charAndNumber') isCharAndNumber:boolean = false;
  

  constructor(
   private el: ElementRef<HTMLInputElement>,
   private ngControl: NgControl
  ) { }

  ngOnInit(){
    if(!this.isNumber && !this.isCharAndNumber && !this.isChar){
      this.isCharAndNumber = true;
    }
  }

  @HostListener('input', ['$event']) input(e) {
    let match:string[] =  e.target.value.match(this.pattren) || [""];
    this.el.nativeElement.value = match.join("");
    this.ngControl.control.setValue(match.join(""));
  }

  get pattren():RegExp {
    if(this.isChar){
      return ONLY_CHAR;
    }else if(this.isCharAndNumber){
      return ONLY_CHAR_AND_NUMBER;
    }else if(this.isNumber){
      return ONLY_NUMBER
    }else{
      return ONLY_CHAR_AND_NUMBER;
    }
  }

}
