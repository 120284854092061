import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DetailComponent } from './detail.component';
import { ShareModule } from '../shareModule/share.module';
import { PersonalInformationComponent } from './personal-information/personal-information.component';
import { CampaignsComponent } from './campaigns/campaigns.component';
import { ContributionsComponent } from './contributions/contributions.component';
import { PayOutComponent } from './pay-out/pay-out.component';
import { DetailRoutingModule } from './detail-routing.module';
import { SideNavComponent } from './side-nav/side-nav.component';
import { BankAcountComponent } from './pay-out/bank-acount/bank-acount.component';
import { AddKycProofsComponent } from './personal-information/add-kyc-proofs/add-kyc-proofs.component';
import { CWishesComponent } from './c-wishes/c-wishes.component';
import { ViewGiftPollComponent } from './view-gift-poll/view-gift-poll.component';
import { ViewGiftSuggestionComponent } from './view-gift-suggestion/view-gift-suggestion.component';

@NgModule({
  imports: [CommonModule, ShareModule, DetailRoutingModule],
  declarations: [
    SideNavComponent,
    DetailComponent,
    PersonalInformationComponent,
    CampaignsComponent,
    ContributionsComponent,
    PayOutComponent,
    BankAcountComponent,
    AddKycProofsComponent,
    CWishesComponent,
    ViewGiftPollComponent,
    ViewGiftSuggestionComponent,
  ],
  entryComponents: [
    AddKycProofsComponent,
    CWishesComponent,
    ViewGiftPollComponent,
    ViewGiftSuggestionComponent,
  ],
})
export class DetailModule {}
