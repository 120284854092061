import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IApi } from '../http-handler/common/contracts/api';
import { GenericApi } from '../http-handler/common/generic-api';
import { Configuration } from '../models/configuration.model';
import { AppService } from './app.service';
import { Page } from '../http-handler/common/contracts/page';

@Injectable({
  providedIn: 'root',
})
export class ConfigurationService {
  configurations: IApi<Configuration> = new GenericApi<Configuration>(
    'configurations',
    this._http
  );
  list: Page<Configuration> = new Page({
    api: this.configurations,
    properties: new Configuration(),
  });
  constructor(private _http: HttpClient, private appService: AppService) {
    this.fetch();
  }

  fetch() {
    return this.list.fetch().then((d) => {
      d.select(d.items[0]);
    });
  }

  public get homeContent() {
    return this.list?.selected?.homepageContent || {};
  }

  public get aboutUsContent() {
    return this.list?.selected?.aboutUsContent || {};
  }

  public get faqContent() {
    return this.list?.selected?.faqContent || {};
  }

  public get campaignContent() {
    return this.list?.selected?.campaignContent || {};
  }

  public get homeHeaderImage() {
    return this.list?.selected?.homeHeaderImage || "";
  }

  public get invitePeopleToContribute() {
    return this.list?.selected.invitePeopleToContribute?.images;
  }

  async create(data: Configuration) {
    try {
      const res = await this.configurations.create(data);
      return res;
    } catch (error: any) {
      this.appService.snackBarOpen(error);
      return null;
    }
  }
}
