import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'squire'
})
export class SquireStringPipe implements PipeTransform {

  transform(value: string, args?: any): any {

    for (let i = 0; i <= value.length - 4; i++) {
      value = replaceChar(value, '*', i);
    }

    return value;

  }

}

function replaceChar(origString, replaceChar, index) {
  let firstPart = origString.substr(0, index);

  let lastPart = origString.substr(index + 1);

  let newString =
    firstPart + replaceChar + lastPart;

  return newString;
} 
