import {
  Component,
  ElementRef,
  HostBinding,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { LayoutItem } from '../../interfaces/layout.type';

@Component({
  selector: 'layout-img-item',
  templateUrl: './layout-img-item.component.html',
  styleUrls: ['./layout-img-item.component.scss'],
})
export class LayoutImgItemComponent implements OnInit {
  @Input() item: LayoutItem;
  @ViewChild('itemContainer') containerRef?: ElementRef<HTMLDivElement>;

  base64: string;
  backgroundPosition = { x: 0, y: 0 };
  scale = 1;
  isDragging = false;
  startPosition = { x: 0, y: 0 };

  constructor() {}

  ngOnInit() {}

  onDelete() {
    this.scale = 1;
    this.backgroundPosition = { x: 0, y: 0 };
    this.startPosition = { x: 0, y: 0 };
    this.base64 = null;
  }

  private clampBackgroundPosition() {
    const container = this.containerRef.nativeElement;
    const containerWidth = container.clientWidth;
    const containerHeight = container.clientHeight;
    const imageWidth = containerWidth * this.scale;
    const imageHeight = containerHeight * this.scale;

    if (imageWidth <= containerWidth) {
      // Center horizontally if image is smaller than container
      this.backgroundPosition.x = (containerWidth - imageWidth) / 2;
    } else {
      // Clamp horizontally if image is larger than container
      const minX = containerWidth - imageWidth;
      this.backgroundPosition.x = Math.min(0, Math.max(minX, this.backgroundPosition.x));
    }

    if (imageHeight <= containerHeight) {
      // Center vertically if image is smaller than container
      this.backgroundPosition.y = (containerHeight - imageHeight) / 2;
    } else {
      // Clamp vertically if image is larger than container
      const minY = containerHeight - imageHeight;
      this.backgroundPosition.y = Math.min(0, Math.max(minY, this.backgroundPosition.y));
    }
  }

  private updateBackgroundPosition() {
    const container = this.containerRef.nativeElement;
    container.style.backgroundPosition = `${this.backgroundPosition.x}px ${this.backgroundPosition.y}px`;
    container.style.backgroundSize = `${this.scale * 100}%`;
  }

  onContextMenu(event: Event) {
    event.preventDefault();
  }

  onMouseDown(event: MouseEvent) {
    if (event.buttons === 1) {
      // Left mouse button
      event.preventDefault();
      this.isDragging = true;
      this.startPosition = { x: event.clientX, y: event.clientY };
    }
  }

  onMouseMove(event: MouseEvent) {
    if (this.isDragging) {
      const deltaX = event.clientX - this.startPosition.x;
      const deltaY = event.clientY - this.startPosition.y;
      this.backgroundPosition.x += deltaX / this.scale;
      this.backgroundPosition.y += deltaY / this.scale;
      this.startPosition = { x: event.clientX, y: event.clientY };
      this.clampBackgroundPosition();
      this.updateBackgroundPosition();
    }
  }

  onMouseUpOrLeave() {
    this.isDragging = false;
  }

  onMouseWheel(event: WheelEvent) {
    event.preventDefault();
    const delta = Math.sign(event.deltaY) * -0.1;
    const newScale = Math.min(Math.max(1, this.scale + delta), 4);

    // Adjust position to zoom towards cursor
    const rect = this.containerRef.nativeElement.getBoundingClientRect();
    const x = event.clientX - rect.left;
    const y = event.clientY - rect.top;

    this.backgroundPosition.x -= (x / this.scale) * (newScale - this.scale);
    this.backgroundPosition.y -= (y / this.scale) * (newScale - this.scale);

    this.scale = newScale;
    this.clampBackgroundPosition();
    this.updateBackgroundPosition();
  }

  async onFileInputChange(event: any) {
    if (event?.target.files?.length) {
      this.onSelectFile(event?.target.files[0]);
    }
  }

  async onSelectFile(file: File) {
    if (file?.type?.includes('image')) {
      this.base64 = await this.toBase64(file);
    }
  }

  toBase64 = (file: File) =>
    new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        resolve(reader.result as string);
      };
      reader.onerror = (error) => reject(error);
    });

  @HostBinding('style') get style() {
    return {
      width: '100%',
      height: '100%',
    };
  }
}
