<div class="flex-container">
  <h3 class="title">{{ data?.data?.name }}</h3>
  <button class="close-button" (click)="dialogRef.close()">X</button>
</div>
<div class="info-container">
  <img
    width="250"
    height="150"
    src="{{ data?.data?.image_thumb_url }}"
    alt=""
  />
  <div class="text-box">
    <details>
      <summary (click)="toggleSection('section1')">Description</summary>
      <div *ngIf="isOpen.section1">
        {{ data?.data?.description }}
      </div>
    </details>
    <details>
      <summary (click)="toggleSection('section2')">Terms & Conditions</summary>
      <div
        *ngIf="isOpen.section2"
        [innerHTML]="data?.data?.terms_and_conditions"
      >
        Terms & Conditions
      </div>
    </details>
    <details>
      <summary (click)="toggleSection('section3')">Redeem Instructions</summary>
      <div
        *ngIf="isOpen.section3"
        [innerHTML]="data?.data?.redeem_instructions"
      >
        Redeem Instructions
      </div>
    </details>
    <details>
      <summary (click)="toggleSection('section4')">Disclaimer</summary>
      <div *ngIf="isOpen.section4">
        {{ data?.data?.disclaimer }}
      </div>
    </details>
  </div>
</div>
<div class="flex-table">
  <table>
    <tr>
      <th>Product ID</th>
      <td align="end">{{ data?.data?.product_id }}</td>
    </tr>
    <tr>
      <th>Currency</th>
      <td align="end">{{ data?.data?.currency_code }}</td>
    </tr>
    <tr>
      <th>Countries</th>
      <td align="end">
        {{ data?.data?.countries }}
      </td>
    </tr>
  </table>
  <table>
    <tr class="type-table">
      <th>Type</th>
      <th>Product Code</th>
      <th>Value</th>
    </tr>

    <tr class="type-table">
      <td align="end">{{ isType() }}</td>
      <td align="end">{{ data?.data?.items[0]?.code }}</td>

      <td align="end">
        {{ isValueFor() }}
      </td>
    </tr>
    <tr>
      <th>Category</th>
      <td colspan="2">{{ data?.data?.Category ? data?.data?.Category : "--" }}</td>
    </tr>
  </table>
</div>
<div *ngIf="!data?.view" class="card-button">
  <button
    class="button-add"
    matRipple
    size="lg"
    color="c1"
    ButtonUi
    (click)="callFunction(data?.data)"
  >
    Add Card
  </button>
</div>
