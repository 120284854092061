import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { UserService } from "src/app/services/user.service";
import { User } from "src/app/models/user.model";
import { AppService } from "src/app/services/app.service";
import { MatDialog } from "@angular/material/dialog";
import { InviteCharityComponent } from "./invite-charity/invite-charity.component";
import { StartWithContributeComponent } from "../../dialogs/start-with-contribute/start-with-contribute.component";

@Component({
  selector: "app-header-nav",
  templateUrl: "./header-nav.component.html",
  styleUrls: ["./header-nav.component.scss"],
})
export class HeaderNavComponent implements OnInit {
  constructor(
    public userService: UserService,
    public _router: Router,
    public appService: AppService,
    public dialog: MatDialog
  ) { }

  ngOnInit() { }

  signOut(): void {
    this.userService.logout();
  }

  logIn(): void {
    // /contribution/52
    this._router.navigate(["/auth/sign-in"], {
      queryParams: { redirectTo: "/pages/" + this._router.url },
    });
  }

  get user(): User {
    return JSON.parse(localStorage.getItem("user"));
  }

  inviteCharity() {
    const dialogRef = this.dialog.open(InviteCharityComponent, {
      panelClass: "charity-popup",
      maxWidth: "460px",
    });
    dialogRef.afterClosed().subscribe((result) => {
      // console.log(result);
    });
  }

  navigate(link, defualt?: string) {
    if (localStorage.getItem('token')) {
      this._router.navigate([link]);
    } else {
      this._router.navigate([defualt ? defualt : '/auth/sign-in'], {
        queryParams: { redirectTo: link },
      });
    }
  }

  startWithContribute(link?: string) {
    this.dialog.open(StartWithContributeComponent, {
      disableClose: true,
      minHeight: '200px',
      maxHeight: '100%',
      minWidth: '600px',
      maxWidth: '100%',
    });
  }

}
