<header>
  <div class="header-text">Design Your Own Cover</div>

  <div class="ml-auto">
    <button
      mat-icon-button
      id="closeBtn"
      class="ml-auto"
      (click)="dialogRef.close(file)"
    >
      <mat-icon>clear</mat-icon>
    </button>
  </div>
</header>

<app-design-own-card
  [cardRatio]="container.ratio"
  [cardHeight]="container.height"
  [cardWidth]="container.width"
  (onGenerateImage)="onGenerateImage($event)"
></app-design-own-card>
