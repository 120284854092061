import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgxSpinnerService } from 'ngx-spinner';
import { User } from '../models/user.model';
import { MatDialog } from '@angular/material/dialog';
import { getCurrencySymbol } from '@angular/common';
import html2canvas from 'html2canvas';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import * as htmlToImage from 'html-to-image';

@Injectable({
  providedIn: 'root',
})
export class AppService {
  constructor(
    private tostor: MatSnackBar,
    public spinner: NgxSpinnerService,
    public matDialog: MatDialog
  ) {}

  loadingShow() {
    this.spinner.show();
  }

  loadingHide() {
    this.spinner.hide();
  }

  snackBarOpen(value) {
    if (typeof value === 'object') {
      for (let v in value) {
        value = value[v];
        break;
      }
    }
    if (typeof value === 'string') {
      let v = value.substr(0, 1);
      value = v.toUpperCase() + value.substr(1, value.length);
    }
    this.tostor.open(value, 'Close', {
      duration: 5000,
      panelClass: 'snackBarOpen-tostor',
    });
  }

  snackBarOpenTemplate(template, duration?: number) {
    return this.tostor.openFromTemplate(template, {
      duration: duration || 5000,
    });
  }

  getCurrencySymbol(code) {
    return getCurrencySymbol(code, 'wide');
  }

  get user(): User {
    return JSON.parse(localStorage.getItem('user')) as User;
  }

  // get guestEmail(): string {
  //   return localStorage.getItem('email');
  // }

  get OTPVerified(): boolean {
    if (localStorage.getItem('OTPVerified')) {
      return false;
    }
  }

  set OTPVerified(v) {
    if (!v) {
      localStorage.removeItem('OTPVerified');
    } else {
      localStorage.setItem('OTPVerified', `${v}`);
    }
  }

  // clearGuestUser(): void {
  //   localStorage.clear();
  //   window.location.href = '/home';
  // }

  copyLink(link) {
    let input = document.createElement('input');
    input.value = `${link}`;
    document.body.appendChild(input);
    input.select();
    document.execCommand('copy');
    document.body.removeChild(input);
    this.snackBarOpen('Link Copied');
  }

  // generateImage = (e = new HTMLElement) => new Promise<File>((res, rej) => {
  //   html2canvas(e).then(async (c) => {
  //     let img = c.toDataURL("image/png");
  //     var bolbBin = atob(img.split(',')[1]);
  //     var ary = [];
  //     for (let i = 0; i < bolbBin.length; i++) {
  //       ary.push(bolbBin.charCodeAt(i));
  //     }
  //     const file = new File([new Uint8Array(ary)], 'New image file', { type: "image/png" })
  //     res(file)
  //   }).catch(err => {
  //     rej(err);
  //   })
  // });

  generateImage = (e = new HTMLElement()) =>
    new Promise<File>((res, rej) => {
      htmlToImage
        .toPng(e)
        .then(function (dataUrl) {
          var bolbBin = atob(dataUrl.split(',')[1]);
          var ary = [];
          for (let i = 0; i < bolbBin.length; i++) {
            ary.push(bolbBin.charCodeAt(i));
          }
          const file = new File([new Uint8Array(ary)], 'New image file', {
            type: 'image/png',
          });
          res(file);
        })
        .catch(function (error) {
          rej(error);
        });
    });

  convertBase64Tofile(base64: string, fileName?: string) {
    if (!base64) return null;
    var bolbBin = atob(base64.split(',')[1]);
    var ary = [];
    for (let i = 0; i < bolbBin.length; i++) {
      ary.push(bolbBin.charCodeAt(i));
    }
    let bb = new Blob([new Uint8Array(ary)], { type: 'image/png' });
    let file = new File([bb], fileName || 'New file', { type: 'image/png' });
    return file;
  }

  formGroupSetValue(form: FormGroup, value: { [key: string]: any }) {
    Object.keys(form.controls).forEach((key) => {
      const control = form.get(key);
      if (value[key] !== undefined) {
        if (control instanceof FormControl) {
          control.setValue(value[key]);
        } else if (control instanceof FormGroup) {
          this.formGroupSetValue(control, value[key]);
        } else if (control instanceof FormArray) {
          control.controls.forEach((arrayControl, index) => {
            const arrayItem: Array<any> = value[key][index];
            if (arrayControl instanceof FormControl) {
              arrayControl.setValue(arrayItem);
            } else if (arrayControl instanceof FormGroup) {
              this.formGroupSetValue(arrayControl, arrayItem);
            }
          });
        }
      }
    });
  }
}
